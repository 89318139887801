import qs from 'qs';
import { DICE_SLOTS, SLOTS } from 'types';
import { LANGUAGES } from 'i18n';
import { BaseService } from './BaseService';

export type ConfirmBetRequestDataType = { [key in SLOTS]: number };

export type ConfirmBetResponseDataType = {
  total: string;
};

export interface ICreatePendingBetRequestData {
  [SLOTS.player]: number;
  [SLOTS.tie]: number;
  [SLOTS.banker]: number;
  timeStamp: number;
}

export interface ICreatePendingDiceBetRequestData {
  // @TODO HHH
  [DICE_SLOTS.red]: number;
  [DICE_SLOTS.orange]: number;
  [DICE_SLOTS.yellow]: number;
  [DICE_SLOTS.green]: number;
  [DICE_SLOTS.blue]: number;
  [DICE_SLOTS.purple]: number;
  [DICE_SLOTS.triple]: number;
  timeStamp: number;
}

export interface ICreatePendingBetResponseData {
  [SLOTS.player]: number;
  [SLOTS.tie]: number;
  [SLOTS.banker]: number;
  timeStamp: number;
  total: number;
}

class RestAPIService extends BaseService {
  public async confirmBet(data: ConfirmBetRequestDataType) {
    const result = await this.post<ConfirmBetResponseDataType | null>(
      'bets/create/',
      qs.stringify(data),
      {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    );

    if (result.status === 201) {
      return result.data;
    }

    return null;
  }

  public async createPendingBet(data: ICreatePendingBetRequestData) {
    const result = await this.post<ICreatePendingBetResponseData | null>(
      'bets/pending-bets/create',
      data,
      {
        'Content-Type': 'application/json; charset=utf-8',
      },
    );

    if (result.status === 201) {
      return result.data;
    }

    return null;
  }

  public async createPendingDiceBet(data: ICreatePendingDiceBetRequestData) {
    const result = await this.post<ICreatePendingBetResponseData | null>(
      'bets/dices/pending-bets/create',
      data,
      {
        'Content-Type': 'application/json; charset=utf-8',
      },
    );

    if (result.status === 201) {
      return result.data;
    }

    return null;
  }

  public async getGameRules(locale: LANGUAGES) {
    const route = `game-rules/${locale}`;

    return this.getAPI(route);
  }

  public async getHistory() {
    const route = `bets/history`;

    return this.getAPI(route);
  }
}

export const RestService = new RestAPIService();
