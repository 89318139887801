import { createAction } from 'typesafe-actions';
import { ACTIONS } from './types';
import { IDiceLimits } from 'constants/common';

export const saveTableSettingsToStoreAction = createAction(ACTIONS.SAVE_TABLE_SETTINGS_TO_STORE)<{
  tableId: string;
  gameId: string | null;
  dealerNickname: string;
  minBetLimit: number;
  maxBetLimit: number;
  displayingWinBall: number;
  dicesLimits: IDiceLimits;
}>();

export const updateGameIdAction = createAction(ACTIONS.UDATE_GAME_ID)<{ gameId: string }>();

export const updateDealerAction = createAction(ACTIONS.UPDATE_DEALER)<{ dealerNickname: string }>();
