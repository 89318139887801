import { ReactNode } from 'react';
import { Device } from 'types';

export enum SettingPanels {
  Main = 'Main',
  Sound = 'Sound',
  Video = 'Video',
  General = 'General',
  Rules = 'Rules',
  Winners = 'Winners',
  History = 'History',
  Chat = 'Chat',
  BetLimits = 'BetLimits',
}

export interface ISettingsProps {
  children: ReactNode;
  openedPanel: SettingPanels | null;
  deviceType?: Device;
  onSettingsButtonClick: (value: SettingPanels) => void;
  onFullScreenClick: () => void;
  isFullScreen: boolean;
}
