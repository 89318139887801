import { ClientSideNotifications, Notifications, NotificationsChat } from 'types';

export const defaultListeners = Object.keys(Notifications).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);

export const clienSideConnectionListeners = Object.values(ClientSideNotifications).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);

export const defaultChatListeners = Object.keys(NotificationsChat).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);

export const chatClientSideConnectionListeners = Object.values(ClientSideNotifications).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);
