import { IWinnerDice, WinnerBall } from 'constants/common';
import { INotificationResponse } from 'types';

export enum ACTIONS {
  SAVE_WINNER_BALL_TO_STORE = 'SAVE_WINNER_BALL_TO_STORE',
}

export interface IWinnerBallState {
  winnerBall: WinnerBall | null;
}

export type ResultBall = WinnerBall | 'UNDEFINED';

type OnSaveWinnerBallToStoreNotificationData = {
  winner: ResultBall;
  winners: IWinnerDice; // @TODO HHH
};

type OnSaveConfirmedWinnerBallToStoreNotificationData = {
  winner: WinnerBall;
  winners: IWinnerDice; // @TODO HHH
};

export type OnDrawFinishedData = INotificationResponse<OnSaveWinnerBallToStoreNotificationData>;

export type OnResultConfirmedData =
  INotificationResponse<OnSaveConfirmedWinnerBallToStoreNotificationData>;
