import styled, { css } from 'styled-components';
import { Device } from 'types';
import { WinAmount, WinTitle } from '../WinStatus/styles';

export const Wrapper = styled.div<{ $deviceType?: Device }>`
  position: absolute;
  left: 50%;
  display: flex;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 126rem;
        height: 262rem;
        width: 464rem;
        z-index: 3;

        ${WinTitle} {
          font-size: 8px;
          line-height: 14px;
        }

        ${WinAmount} {
          font-size: 26px;
          line-height: 60px;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 58rem;
        height: 142rem;
        width: 344rem;
        z-index: 3;

        @media only screen and (max-width: 430px) {
          width: 270rem;
        }

        ${WinTitle} {
          font-size: 8px;
          line-height: 12px;
        }

        ${WinAmount} {
          font-size: 24px;
          line-height: 40px;
          letter-spacing: 3px;
        }
      `;
    }

    return css`
      bottom: 428rem;
      height: 264rem;
      width: 464rem;
      border-radius: 50%;

      ${WinTitle} {
        font-size: 9px;
        line-height: 14px;
      }

      ${WinAmount} {
        font-size: 24px;
        line-height: 60px;
      }
    `;
  }}
`;
