import { ActionType, createReducer } from 'typesafe-actions';
import { saveDrawnDicesAction } from './actions';
import { IDrawnDicesState } from './types';

export const drawnDicesState: IDrawnDicesState = {
  winners: null,
};

const actions = {
  saveDrawnDicesAction,
};

export const drawnDicesReducer = createReducer<IDrawnDicesState, ActionType<typeof actions>>(
  drawnDicesState,
).handleAction(saveDrawnDicesAction, (state, { payload: { winners } }) => ({
  ...state,
  winners,
}));
