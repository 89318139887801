import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { balanceSelector, totalBetSelector } from 'core/widgets/User';
import { tableBetLimitSelector } from 'core/widgets/TableSettings';
import { useModal } from 'hooks';
import { transformToCurrency } from 'utils/transformToCurrency';
import { PopupConfirm } from 'components';
import { roundStateSelector } from 'core/widgets/RoundStates';
// import { RoundStates } from 'core/widgets/RoundStates/types';
import { preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { noticeSelector } from 'core/widgets/Notices';
import { Notices as NoticeType } from 'core/widgets/Notices/types';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { WalletBalanceResponsive } from './Responsive';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { placedBetSelector } from 'core/widgets/Bet';
import { getBetSum } from 'core/widgets/Bet/utils';
import { placedDiceBetSelector } from 'core/widgets/DiceBet';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType } from 'constants/common';

export const WalletBalance = () => {
  const { t } = useTranslation();
  const { isShown, openModal, closeModal } = useModal();
  const betLimit = useSelector(tableBetLimitSelector);
  const balance = useSelector(balanceSelector);
  const roundState = useSelector(roundStateSelector);
  const totalBet = useSelector(totalBetSelector);
  const notification = useSelector(noticeSelector);
  const dispatch = useDispatch();
  const { currency } = useSelector(authInfoSelector);
  const placedBet = useSelector(placedBetSelector);
  const betSum = getBetSum([placedBet]);
  const gameType = useSelector(gameTypeSelector);

  const placedDiceBet = useSelector(placedDiceBetSelector); // @TODO HHH

  const betDiceSum = getDiceBetSum([placedDiceBet]);
  const showedBets = gameType === GameType.HHH ? betDiceSum : betSum;

  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (balance === null || betLimit === undefined) {
      return;
    }

    if (balance && balance > betLimit.min) {
      setFirstLoad(true);
    }

    if (!balance && totalBet) {
      setFirstLoad(true);
    }

    if (balance < betLimit.min && !firstLoad && !totalBet) {
      setFirstLoad(true);
      openModal();
    }

    if (notification === NoticeType.NoBetsMade || notification === NoticeType.SessionTimeout) {
      closeModal();
      dispatch(manageSettingsPanelAction({ settingsPanel: null }));
    }
  }, [
    balance,
    betLimit,
    openModal,
    roundState,
    firstLoad,
    totalBet,
    notification,
    closeModal,
    dispatch,
  ]);

  return (
    <>
      {isShown ? (
        <PopupConfirm onConfirm={closeModal}>{t('notices.lowBalance')}</PopupConfirm>
      ) : null}
      <WalletBalanceResponsive
        balance={transformToCurrency({
          value: balance !== null ? preciseAddSubtract(balance, showedBets, 'subtract') : 0,
          currency,
        }).replace(/-/g, '')}
      />
    </>
  );
};
