import { all, takeEvery } from 'redux-saga/effects';
import { ACTIONS } from '../types';
import { placeDiceBetSaga } from './placeDiceBetSaga';
import { clearDiceBetSaga } from './clearDiceBetSaga';
import { createPendingDiceBetSaga } from './createPendingDiceBetSaga';

export function* diceBetRootSaga() {
  yield all([
    takeEvery(ACTIONS.PLACE_DICE_BET, placeDiceBetSaga),
    takeEvery(ACTIONS.CLEAR_PLACED_DICE_BET, clearDiceBetSaga),
    takeEvery(
      [ACTIONS.PLACE_DICE_BET_SUCCESS, ACTIONS.CLEAR_PLACED_DICE_BET_SUCCESS],
      createPendingDiceBetSaga,
    ),
  ]);
}
