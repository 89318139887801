import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  hasUserInStageSelector,
  hasUserInTop100Selector,
  isUserInStageJPSelector,
  jackpotActivitySelector,
} from 'core/widgets/Jackpot/selectors';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { activeChipSelector } from 'core/widgets/ActiveChip';
import AudioService from 'services/AudioService';
import audioSourceMusic from 'assets/sounds/music_sounds/baccarat_Music_Option_1_v2_01_reduce.mp3';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { useEffectSound } from 'hooks/useEffectSound';

import { clearPlacedDiceBetAction, placeDiceBetAction } from 'core/widgets/DiceBet/actions';
import { summaryDiceBetAllSelector, summaryDiceBetSelector } from 'core/widgets/DiceBet';
import { DICE_SLOTS } from 'types';
import { drawnDicesSelector } from 'core/widgets/DrawnDices/selectors';

import { DiceSlotsResponsive } from './Responsive';
import { getWinnerSlots } from './utils/isTriple';

export const DiceSlotsContainer = () => {
  const dispatch = useDispatch();

  const slotsData = useSelector(summaryDiceBetSelector);
  const slotsAll = useSelector(summaryDiceBetAllSelector);

  const drawnDices = useSelector(drawnDicesSelector);
  const winnerDices = useMemo(() => (drawnDices ? getWinnerSlots(drawnDices) : []), [drawnDices]);

  const roundState = useSelector(roundStateSelector);
  const activeChip = useSelector(activeChipSelector);

  const hasUserInTop100 = useSelector(hasUserInTop100Selector);
  const isUserInStageJP = useSelector(isUserInStageJPSelector);
  const hasUserInStage = useSelector(hasUserInStageSelector);
  const {
    hasCollectionPeriod,
    countdownJackpot,
    minBet,
    sum,
    stage,
    currency: jackpotCurrency,
  } = useSelector(jackpotActivitySelector);

  const { gameSoundMute, music, soundEffects } = useSelector(soundSettingsSelector);
  const audioMusicRef = useRef(null) as any;

  useEffect(() => {
    const audio = audioMusicRef?.current as any;
    if (!gameSoundMute && !music.mute) {
      audio.play();
    }
    if (gameSoundMute || music.mute) {
      audio.pause();
    }
  }, [gameSoundMute, music]);

  useEffectSound(audioMusicRef, music);

  const onSlotClick = useCallback(
    (slot: DICE_SLOTS) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }

      if (activeChip === null) {
        return;
      }

      dispatch(placeDiceBetAction({ slot, value: activeChip.value }));
    },
    [activeChip, dispatch, gameSoundMute, soundEffects],
  );

  const onBetClear = useCallback(
    (slot: DICE_SLOTS) => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('cancel', soundEffects.volume);
      }

      dispatch(clearPlacedDiceBetAction(slot));
    },
    [dispatch, gameSoundMute, soundEffects],
  );

  const onBetConfirm = useCallback(() => {}, []);

  const onErrorClick = useCallback(() => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('error', soundEffects.volume);
    }
  }, [gameSoundMute, soundEffects]);

  return (
    <>
      <audio src={audioSourceMusic} ref={audioMusicRef} loop />
      <DiceSlotsResponsive
        slots={slotsData}
        winnerDices={roundState === RoundStates.ResultConfirmed ? winnerDices : null}
        activeChip={activeChip?.color}
        isBettingTime={roundState === RoundStates.BettingTimeStarted}
        // isBettingTime={true}
        onSlotClick={onSlotClick}
        onBetClear={onBetClear}
        onBetConfirm={onBetConfirm}
        onErrorClick={onErrorClick}
        isActiveJPSlot={Boolean(
          (Boolean(stage) && isUserInStageJP) || Boolean(hasUserInTop100 && !stage),
        )}
        minBetJP={minBet?.value}
        stage={stage}
        dateTimer={countdownJackpot}
        isActiveCollectionPeriod={hasCollectionPeriod}
        hasUserInStage={hasUserInStage}
        jackpotSum={sum ? sum : undefined}
        jackpotCurrency={jackpotCurrency}
      />
    </>
  );
};
