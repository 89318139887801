import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BaseWinHistory } from './WinHistory.base';
import { IDiceStatisticsProps } from '../types';

export const WinHistory = (props: IDiceStatisticsProps) => (
  <>
    <DesktopComponent>
      <BaseWinHistory {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseWinHistory {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseWinHistory {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseWinHistory {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
