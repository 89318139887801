import { setBalanceAction } from 'core/widgets/User/actions';
import { setWinStatusAction } from 'core/widgets/WinStatus/actions';
import { WinnerBallSlot } from 'core/widgets/WinStatus/types';
import { put, select } from 'redux-saga/effects';
import { Notifications } from 'types';
import { OnPlayerWonData } from '../types';
import { setDiceWinStatusAction } from 'core/widgets/DiceWinStatus/actions';
import { WinnerDices } from 'widgets/DiceWinStatus/types';
import { GameType } from 'constants/common';
import { gameTypeSelector } from 'core/gameTypeReducer';

export function* onPlayerWonSaga({ type: notificationType, data: result }: OnPlayerWonData) {
  if (notificationType === Notifications.PLAYER_WON) {
    const {
      winner,
      winners, // @TODO HHH
      winningAmount,
      user: { balance },
      bets,
    } = result;

    const gameType: GameType | null = yield select(gameTypeSelector);

    yield put(setBalanceAction(balance));

    if (gameType === GameType.BAC) {
      const betAmount = bets[WinnerBallSlot[winner]];
      yield put(setWinStatusAction({ winner, winningAmount, betAmount }));
    }

    // @TODO HHH
    if (gameType === GameType.HHH) {
      yield put(
        setDiceWinStatusAction({
          winners: [
            winners?.firstDice as unknown as WinnerDices,
            winners.secondDice as unknown as WinnerDices,
            winners.thirdDice as unknown as WinnerDices,
          ],
          winningAmount,
        }),
      );
    }
  }
}
