import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import { reverse } from 'ramda';

import { Device } from 'types';
import { SettingsPanel } from 'components';
import {
  Wrapper,
  ButtonWrapper,
  ListContainer,
  LimitText,
  ChatContainerScroll,
  ChatWrapper,
} from './styles';
import { IChatPanelComponentProps } from './types';
import { isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { Chat } from './components/Chat';
import { Input } from './components/Input';
import { useKeyboardStatus } from 'hooks/useKeyboardStatus';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';
import { chatWebSocketService } from 'services/ChatWebSocketService';

const getDeltaHeight = (deviceType: Device) => {
  if (deviceType === Device.Mobile) {
    return isInIframe() ? 154 : 186;
  }

  if (deviceType === Device.Tablet_Portrait) {
    return isOldBrowserIOS() ? 230 : 210;
  }

  if (deviceType === Device.Tablet_Landscape) {
    return isInIframe() ? 230 : 210;
  }
  return 150;
};

export const ChatPanelComponent = ({
  deviceType = Device.Desktop,
  isBlockedChat,
  messages,
  newMessage,
  messageError,
  isChatInputFocused,
  handleFocus,
  onCloseClick,
  onSendMessage,
  handleMessageChange,
}: IChatPanelComponentProps) => {
  const { t } = useTranslation();
  const ref = useRef(null) as any;
  const scrollableRef = useRef(null);
  const scrollableRef2 = useRef(null);

  const [h, setH] = useState(400);

  const inputRef = useRef(null);

  const [isReversed, setIsReversed] = useState(false);

  const onBlur = () => {
    if (deviceType === Device.Desktop) {
      handleFocus(false);
    }
    // @ts-ignore
    if (
      scrollableRef2?.current &&
      (deviceType === Device.Mobile ||
        deviceType === Device.Tablet_Portrait ||
        deviceType === Device.Tablet_Landscape)
    ) {
      setTimeout(() => {
        handleFocus(false);

        // @ts-ignore
        scrollableRef2.current.scrollTop = 1000;
      }, 100);
    }
  };

  const onFocus = () => {
    handleFocus(true);
    // @ts-ignore
    if (
      scrollableRef2?.current &&
      (deviceType === Device.Mobile ||
        deviceType === Device.Tablet_Portrait ||
        deviceType === Device.Tablet_Landscape)
    ) {
      setTimeout(() => {
        // @ts-ignore
        scrollableRef2.current.scrollTop = 0;
      }, 100);
    }
  };

  useEffect(() => {
    // @ts-ignore
    const height = ref?.current?.getBoundingClientRect().height;
    setH(height);
  }, [ref]);

  const scrollToBottom = useCallback(() => {
    if (scrollableRef.current && deviceType === Device.Desktop) {
      // @ts-ignore
      scrollableRef.current.scrollTop = 1000;
    }
    if (
      scrollableRef2.current &&
      (deviceType === Device.Mobile ||
        deviceType === Device.Tablet_Portrait ||
        deviceType === Device.Tablet_Landscape)
    ) {
      // @ts-ignore
      scrollableRef2.current.scrollTop = 1000;
    }
  }, [deviceType]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSendMessage();
      (event.target as HTMLInputElement).blur();
    }
  };

  const isKeyboardOpen = useDetectKeyboardOpen();

  useEffect(() => {
    if (isKeyboardOpen) {
      setIsReversed(true);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
      setTimeout(() => {
        setIsReversed(false);
      }, 300);
    }
  }, [isKeyboardOpen]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSendMessage();
      (event.target as HTMLInputElement).blur();
    }
  };

  const isReverseList = isKeyboardOpen && isChatInputFocused;

  const delta = getDeltaHeight(deviceType);

  return (
    <Wrapper ref={ref}>
      <SettingsPanel onCloseClick={onCloseClick} deviceType={deviceType} hasVersionInfo={false}>
        <SettingsPanel.Title>{t('chat.title')}</SettingsPanel.Title>
        <SettingsPanel.Item>
          <ChatWrapper
            $isColumnReverse={isReverseList}
            $hasShadow={isReversed}
            $deviceType={deviceType}>
            <ListContainer $hasShadow={messages?.length > 6} $isReversed={isReverseList}>
              <ChatContainerScroll $deviceType={deviceType} ref={scrollableRef}>
                {deviceType === Device.Mobile ||
                deviceType === Device.Tablet_Portrait ||
                deviceType === Device.Tablet_Landscape ? (
                  <SimpleBar
                    style={{
                      height: `${h - delta}px`,
                      // height: `${h - (deviceType === Device.Mobile ? 186 : isOldBrowserIOS() ? 230 : 210)}px`,
                    }}
                    autoHide={false}
                    scrollableNodeProps={{ ref: scrollableRef2 }}
                    className="bar_scroll">
                    <Chat
                      messages={isReverseList ? reverse(messages) : messages}
                      deviceType={deviceType}
                    />
                  </SimpleBar>
                ) : (
                  <Chat messages={messages} deviceType={deviceType} />
                )}
              </ChatContainerScroll>
            </ListContainer>
            <ButtonWrapper
              onKeyPress={handleKeyPress}
              $isReversed={isReverseList}
              $deviceType={deviceType}>
              <Input
                ref={inputRef}
                value={newMessage || ''}
                placeholder={t('chat.typeMessage')}
                onClick={onSendMessage}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                disabled={messageError || isBlockedChat}
                onBlur={onBlur}
                onFocus={onFocus}
              />
              {newMessage && newMessage?.length >= 150 && (
                <LimitText>{t('chat.messageLimit')}</LimitText>
              )}
            </ButtonWrapper>
          </ChatWrapper>
        </SettingsPanel.Item>
      </SettingsPanel>
    </Wrapper>
  );
};
