import styled, { css } from 'styled-components';
import { Device } from 'types';

export const CloseButton = styled.div`
  position: absolute;
  right: -12rem;
  top: -12rem;
  height: 32rem;
  width: 32rem;
  border: 1px solid #2b2a2b;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 101;
  ${({ theme: { background, border } }) => css`
    background: ${background.primary};
    &:hover {
      border: 1px solid ${border.accent};
    }
  `}
`;

const WrapperContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: min-content;
  border-style: none;
  ${({ theme: { border } }) => css`
    border-color: ${border.primaryDefault};
  `}

  &:after {
    content: '';
    position: absolute;
    border: 1rem solid;
    border-image-source: linear-gradient(
      270deg,
      rgba(241, 230, 210, 0) 0%,
      #f1e6d2 50%,
      rgba(241, 230, 210, 0) 100%
    );
    border-image-slice: 1;
    pointer-events: none;
  }
`;

export const LimitsWrapper = styled(WrapperContainer)<{ $deviceType?: Device }>`
  margin: 0 auto 258em;
  width: 488rem;
  border-width: 4rem;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.8) 40%, rgba(52, 52, 52, 0.16) 100%);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    background: rgba(18, 16, 17, 0.6);
  }

  &:after {
    top: 4rem;
    left: 4rem;
    right: 4rem;
    bottom: 4rem;
    z-index: 1002;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        width: 312rem;
        @media only screen and (max-width: 320px) {
          width: 278rem;
        }
        margin: 152rem auto 40rem;
        border-width: 2rem;
        background: #121011;

        &:after {
          top: 2rem;
          left: 2rem;
          right: 2rem;
          bottom: 2rem;
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        margin: 120rem auto 40rem;
        border-width: 4rem;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          pointer-events: none;
        }

        &:after {
          top: 4rem;
          left: 4rem;
          right: 4rem;
          bottom: 4rem;
        }
      `;
    }

    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        top: -62rem;
      `;
    }
  }}
`;

export const Head = styled.p<{ $deviceType?: Device }>`
  padding: 22rem 0;
  font-size: 14rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  z-index: 100;
  border-bottom: 1rem solid #434343;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        font-size: 10rem;
        padding: 10px 0;
      `;
    }
  }}
`;

export const ItemValue = styled.p`
  font-size: 10rem;
  position: relative;
  padding: 0 14rem 0 0;
  word-break: break-word;

  @media only screen and (max-width: 7680px) {
    padding: 0 12rem 0 0;
  }
`;

export const HeadTable = styled.div<{ $deviceType?: Device }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
  position: relative;
  z-index: 1;
  margin: 16rem 0 0;

  ${ItemValue} {
    color: #e7d5b9;
    font-size: 10rem;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        padding: 0 24rem;
        width: calc(100% - 50rem - 1px);
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;

        min-height: 32rem;
        position: relative;
        cursor: pointer;
        z-index: 104;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        ${ItemValue} {
          font-size: 5rem;
        }
      `;
    }
  }}
`;

export const HeadRow = styled.div<{ $deviceType?: Device }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--clash-gold-bb-9370, #bb9370);
  top: 0;
  left: 0;
  height: 100%;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        min-height: 32rem;
        top: 2rem;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        padding: 6rem 16rem;
      `;
    }
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        min-height: 25rem;
        padding: 6rem 16rem;
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        min-height: 25rem;
        padding: 6rem 8rem;
        height: 100%;
        font-size: 6rem;
      `;
    }
  }}
`;

export const Box = styled.div<{ $width: number }>`
  ${({ $width }) => css`
    width: ${`${$width}%`};
  `};
`;

export const TableContainer = styled.div<{ $deviceType?: Device }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 94%;

  & > div {
    width: 100%;
  }

  & p {
    font-size: 10rem;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        padding: 0 24rem;
        width: calc(100% - 50rem - 1px);
        height: calc(100% - 100rem);
        margin: 2rem 0 28rem;
        z-index: 103;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
      `;
    }
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        margin: 0 0 28rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        margin: 0 0 20rem;
        & p {
          font-size: 7rem;
        }
      `;
    }
  }}
`;

export const TableContent = styled.div<{ $deviceType?: Device }>`
  width: 100%;
  border-bottom: 1px solid var(--clash-gold-bb-9370, #bb9370);
`;

export const TableRow = styled.div`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  padding: 10rem 16rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-right: 1px solid var(--clash-gold-bb-9370, #bb9370);
  border-left: 1px solid var(--clash-gold-bb-9370, #bb9370);
`;
