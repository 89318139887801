import { DICE_SLOTS } from 'types';

export enum ACTIONS {
  PLACE_DICE_BET = 'PLACE_DICE_BET',
  PLACE_DICE_BET_SUCCESS = 'PLACE_DICE_BET_SUCCESS',
  CLEAR_PLACED_DICE_BET = 'CLEAR_PLACED_DICE_BET',
  CLEAR_PLACED_DICE_BET_SUCCESS = 'CLEAR_PLACED_DICE_BET_SUCCESS',
  CLEAR_ALL_DICE_BETS = 'CLEAR_ALL_DICE_BETS',
  CONFIRM_DICE_BET_SUCCESS = 'CONFIRM_DICE_BET_SUCCESS',
  CONFIRM_DICE_BET_ERROR = 'CONFIRM_DICE_BET_ERROR',
  ADD_CONFIRMED_DICE_BET = 'ADD_CONFIRMED_DICE_BET',
  SET_CONFIRMED_DICE_BET = 'SET_CONFIRMED_DICE_BET',
}

export type DiceBetSlotType = {
  slot: DICE_SLOTS;
  value: number;
};

export type BetTypeDice = {
  [key in DICE_SLOTS]: number;
};

export interface IDiceBet {
  id: number;
  body: BetTypeDice;
}

export interface IDiceBetState {
  bets: {
    placed: BetTypeDice;
    confirmed: IDiceBet[];
  };
}
