import React from 'react';
import { Device } from 'types';
import { Wrapper } from './styles';
import { IDeviceDiceSlotJackpotProps } from './types';

export const DiceSlotJackpotBase = ({
  deviceType = Device.Desktop,
  isActiveSlot,
  onClick,
  isMinimized,
}: IDeviceDiceSlotJackpotProps) => (
  <Wrapper
    $deviceType={deviceType}
    $hasJackpot={isActiveSlot}
    $isMinimized={isMinimized}
    onClick={onClick}
  />
);
