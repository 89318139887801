import { put } from 'redux-saga/effects';
import { ClientSideNotifications } from 'types';
import { socketDisconnectedAction } from '../actions';

export function* onSocketDisconnectedSaga(notification: string) {
  if (notification === ClientSideNotifications.SOCKET_DISCONNECTED) {
    yield put(socketDisconnectedAction());
  }
}

export function* onSocketChatDisconnectedSaga(notification: string) {
  if (notification === ClientSideNotifications.SOCKET_DISCONNECTED) {
    yield;
  }
}
