import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { ITableSettingsState } from './types';

export const tableSettingsSelector = (state: IState) => state.tableSettingsReducer;

export const tableInfoSelector = createSelector(
  tableSettingsSelector,
  (state: ITableSettingsState | null) => state,
);

export const tableIdSelector = createSelector(
  tableSettingsSelector,
  (state: ITableSettingsState | null) => state?.tableId,
);

export const tableBetLimitSelector = createSelector(
  tableSettingsSelector,
  (state: ITableSettingsState | null) => state?.betLimit,
);

export const displayingWinBallSelector = createSelector(
  tableSettingsSelector,
  (state: ITableSettingsState | null) => state?.displayingWinBall || 0,
);

export const tableDiceBetLimitSelector = createSelector(
  tableSettingsSelector,
  (state: ITableSettingsState | null) => state?.dicesLimits,
);
