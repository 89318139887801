import React from 'react';
import { useSelector } from 'react-redux';
import { Chips } from 'widgets/Chips';
import { TableInfo } from 'widgets/TableInfo';
import { WalletBalance } from 'widgets/WalletBalance';
import { CloseGameButton } from 'widgets/CloseGameButton';
import { Scoreboard } from 'widgets/Scoreboard';
import { ScoreboardButton } from 'widgets/ScoreboardToggleButton';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { InnerWrapper, Wrapper } from './styles';
import { GameType } from 'constants/common';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { DiceStatistics } from 'widgets/DiceStatistics';

export const GamePanelLandscape = () => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);
  const gameType = useSelector(gameTypeSelector);

  return (
    <Wrapper>
      <InnerWrapper>
        <WalletBalance />
        <Chips />
        <ScoreboardButton />
        <CloseGameButton />
      </InnerWrapper>
      {isScoreboardOpen ? gameType === GameType.HHH ? <DiceStatistics /> : <Scoreboard /> : null}
      <TableInfo />
    </Wrapper>
  );
};
