import React from 'react';
import { Device, DICE_SLOTS } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { GameType } from 'constants/common';
import { SettingPanels } from 'widgets/Settings/types';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { DiceSlotJackpot, DiceSlot } from './components';
import {
  BettingAreaWrapper,
  BlueArea,
  GreenArea,
  OrangeArea,
  PurpleArea,
  RedArea,
  SlotsWrapper,
  TripleArea,
  WinningAmountText,
  WinningAmountWrapper,
  Wrapper,
  YellowArea,
} from './styles';
import { IDiceSlotsComponentProps } from './types';
import { SlotsJackpotInfo } from 'widgets/Slots/SlotsJackpotInfo';

export const BaseDiceSlots = ({
  deviceType = Device.Desktop,
  slots,
  winnerDices,
  activeChip,
  isBettingTime,
  onSlotClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
  isActiveJPSlot,
  stage,
  dateTimer,
  isActiveCollectionPeriod,
  hasUserInStage,
  minBetJP,
  jackpotSum,
  jackpotCurrency,
}: IDiceSlotsComponentProps) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  const dispatch = useDispatch();

  const onSettingsButtonClick = () => {
    if (jackpotSum) {
      dispatch(
        manageSettingsPanelAction({
          settingsPanel: SettingPanels.Winners,
          hasClickJackpotSlot: true,
        }),
      );
    }
  };

  return (
    <Wrapper>
      <SlotsJackpotInfo
        gameType={GameType.HHH}
        isScoreboardOpen={isScoreboardOpen}
        stage={stage}
        dateTimer={dateTimer}
        deviceType={deviceType}
        jackpotCurrency={jackpotCurrency}
        jackpotSum={Number(jackpotSum)}
        onClick={onSettingsButtonClick}
      />

      <DiceSlotJackpot
        onClick={onSettingsButtonClick}
        isMinimized={isScoreboardOpen}
        isActiveSlot={isActiveJPSlot}
      />
      <SlotsWrapper>
        <BettingAreaWrapper $deviceType={deviceType}>
          <TripleArea
            $hasPlacedBet={slots[DICE_SLOTS.triple] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.triple) || false}>
            <DiceSlot
              winningAmount="33:1"
              slot={DICE_SLOTS.triple}
              bet={slots[DICE_SLOTS.triple]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.triple] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.triple)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.triple)}
              onBetClear={() => onBetClear(DICE_SLOTS.triple)}
              onErrorClick={onErrorClick}
            />
          </TripleArea>
          <RedArea
            $hasPlacedBet={slots[DICE_SLOTS.red] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.red) || false}>
            <DiceSlot
              slot={DICE_SLOTS.red}
              bet={slots[DICE_SLOTS.red]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.red] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.red)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.red)}
              onBetClear={() => onBetClear(DICE_SLOTS.red)}
              onErrorClick={onErrorClick}
            />
          </RedArea>
          <OrangeArea
            $hasPlacedBet={slots[DICE_SLOTS.orange] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.orange) || false}>
            <DiceSlot
              slot={DICE_SLOTS.orange}
              bet={slots[DICE_SLOTS.orange]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.orange] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.orange)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.orange)}
              onBetClear={() => onBetClear(DICE_SLOTS.orange)}
              onErrorClick={onErrorClick}
            />
          </OrangeArea>
          <YellowArea
            $hasPlacedBet={slots[DICE_SLOTS.yellow] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.yellow) || false}>
            <DiceSlot
              slot={DICE_SLOTS.yellow}
              bet={slots[DICE_SLOTS.yellow]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.yellow] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.yellow)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.yellow)}
              onBetClear={() => onBetClear(DICE_SLOTS.yellow)}
              onErrorClick={onErrorClick}
            />
          </YellowArea>
          <GreenArea
            $hasPlacedBet={slots[DICE_SLOTS.green] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.green) || false}>
            <DiceSlot
              slot={DICE_SLOTS.green}
              bet={slots[DICE_SLOTS.green]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.green] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.green)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.green)}
              onBetClear={() => onBetClear(DICE_SLOTS.green)}
              onErrorClick={onErrorClick}
            />
          </GreenArea>
          <BlueArea
            $hasPlacedBet={slots[DICE_SLOTS.blue] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.blue) || false}>
            <DiceSlot
              slot={DICE_SLOTS.blue}
              bet={slots[DICE_SLOTS.blue]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.blue] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.blue)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.blue)}
              onBetClear={() => onBetClear(DICE_SLOTS.blue)}
              onErrorClick={onErrorClick}
            />
          </BlueArea>
          <PurpleArea
            $hasPlacedBet={slots[DICE_SLOTS.purple] !== 0}
            $deviceType={deviceType}
            $isMinimized={isScoreboardOpen}
            $isWinner={winnerDices?.includes(DICE_SLOTS.purple) || false}>
            <DiceSlot
              slot={DICE_SLOTS.purple}
              bet={slots[DICE_SLOTS.purple]}
              isBettingTime={isBettingTime}
              activeChip={activeChip}
              isActiveJP={Boolean(
                minBetJP &&
                  slots[DICE_SLOTS.purple] >= minBetJP &&
                  (isActiveCollectionPeriod || (stage && hasUserInStage)),
              )}
              onClick={() => onSlotClick(DICE_SLOTS.purple)}
              onBetConfirm={() => onBetConfirm(DICE_SLOTS.purple)}
              onBetClear={() => onBetClear(DICE_SLOTS.purple)}
              onErrorClick={onErrorClick}
            />
          </PurpleArea>
        </BettingAreaWrapper>
        <WinningAmountWrapper $deviceType={deviceType}>
          <WinningAmountText>
            one colour <span>1:1</span>
          </WinningAmountText>
          <WinningAmountText>
            two colours <span>2:1</span>
          </WinningAmountText>
          <WinningAmountText>
            three colours <span>3:1</span>
          </WinningAmountText>
        </WinningAmountWrapper>
      </SlotsWrapper>
    </Wrapper>
  );
};
