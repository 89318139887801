import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';
import { isIOS } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import { ToastTypes } from 'types/toast';
import { GameType } from 'constants/common';

export const ToastMessage = styled.span<{ type: ToastTypes; $deviceType: Device }>`
  text-transform: uppercase;
  /* white-space: nowrap; */
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        min-width: 160rem;
      `;
    }
    return css`
      white-space: nowrap;
    `;
  }}

  ${({ type, theme: { text } }) => {
    if (type === ToastTypes.SUCCESS) {
      return css`
        color: ${text.acceptDefault};
      `;
    }

    if (type === ToastTypes.ERROR) {
      return css`
        color: ${text.signalDefault};
      `;
    }

    return css`
      color: ${text.primaryDefault};
    `;
  }}
`;

export const Wrapper = styled.div<{ $deviceType: Device; $gameType?: GameType | null }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 90;
  display: flex;

  ${({ $deviceType, $gameType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 340rem;
        height: 40rem;
        width: 360rem;

        ${ToastMessage} {
          font-size: 12rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        /* top: calc(56.25vw - 31rem);
        top: calc(56.25vw - 56rem); */
        top: ${getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9 && !isInIframe()
          ? '144rem'
          : '175rem'};

        @media screen and (max-width: 375px) {
          top: ${isOldBrowserIOS()
            ? 'calc(56.25vw - 60rem)'
            : isInIframe() || !isIOS
              ? '175rem'
              : 'calc(56.25vw - 57rem)'};
        }

        height: 28rem;
        width: 184rem;

        ${ToastMessage} {
          font-size: 8rem;
        }
      `;
    }

    return css`
      top: ${$gameType === GameType.HHH ? '272rem' : '356rem'};
      height: 40rem;
      width: 360rem;

      ${ToastMessage} {
        font-size: 12rem;
      }
    `;
  }}
`;

export const ToastItem = styled.svg<{ type: ToastTypes }>`
  ${({ type, theme: { text } }) => {
    if (type === ToastTypes.SUCCESS) {
      return css`
        & ${ToastMessage} {
          fill: ${text.acceptDefault};
        }
      `;
    }

    if (type === ToastTypes.ERROR) {
      return css`
        & ${ToastMessage} {
          fill: ${text.signalDefault};
        }
      `;
    }

    return css`
      & ${ToastMessage} {
        fill: ${text.primaryDefault};
      }
    `;
  }}
`;

export const SoundButton = styled.div`
  width: 203px;
  height: 39px;
  background: #121011;
`;

export const WrapperButton = styled.div<{ $deviceType?: Device }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  top: 10rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 72rem;

        ${WrapperButtonContent} {
          font-size: 10rem;
          padding: 6rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 40rem;

        ${WrapperButtonContent} {
          padding: 2rem 4rem;
          font-size: 8rem;
        }

        ${SoundSwitchToggle} {
          transform: scale(0.6);
        }
      `;
    }

    return css`
      top: 10rem;
    `;
  }}
`;

export const WrapperButtonContent = styled.div<{ $deviceType?: Device }>`
  padding: 10rem;
  background: #121011;

  color: #f1e6d2;
  font-size: 12rem;
  text-transform: uppercase;
  border: 1px solid #f1e6d2;
  background: linear-gradient(348deg, #121011 8.54%, #343434 116.17%);

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundSwitchToggle = styled.div<{ $deviceType?: Device }>`
  margin-left: 4rem;
`;
