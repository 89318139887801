import audioVoiceBets from 'assets/sounds/voices/english/please_place_you_bets.wav';
import audioVoiceBetsFr from 'assets/sounds/voices/french/please_place_you_bets.wav';
import audioVoiceBetsCh from 'assets/sounds/voices/chinese/please_place_you_bets.wav';
import audioVoiceBetsSp from 'assets/sounds/voices/spanish/please_place_you_bets.wav';
import audioVoiceBetsRu from 'assets/sounds/voices/russian/please_place_you_bets.wav';
import audioVoiceBetsHi from 'assets/sounds/voices/hindi/please_place_you_bets.wav';
import audioVoiceBetsPt from 'assets/sounds/voices/portuguese/please_place_you_bets.wav';
import audioVoiceBetsTl from 'assets/sounds/voices/tagalog/please_place_you_bets.wav';
import audioVoiceBetsId from 'assets/sounds/voices/bahasa/please_place_you_bets.wav';
import audioVoiceBetsTr from 'assets/sounds/voices/turkish/please_place_you_bets.wav';

import audioVoiceNoBets from 'assets/sounds/voices/english/no_more_bets_good_luck.wav';
import audioVoiceNoBetsFr from 'assets/sounds/voices/french/no_more_bets_good_luck.wav';
import audioVoiceNoBetsCh from 'assets/sounds/voices/chinese/no_more_bets_good_luck.wav';
import audioVoiceNoBetsSp from 'assets/sounds/voices/spanish/no_more_bets_good_luck.wav';
import audioVoiceNoBetsRu from 'assets/sounds/voices/russian/no_more_bets_good_luck.wav';
import audioVoiceNoBetsHi from 'assets/sounds/voices/hindi/no_more_bets_good_luck.wav';
import audioVoiceNoBetsPt from 'assets/sounds/voices/portuguese/no_more_bets_good_luck.wav';
import audioVoiceNoBetsTl from 'assets/sounds/voices/tagalog/no_more_bets_good_luck.wav';
import audioVoiceNoBetsId from 'assets/sounds/voices/bahasa/no_more_bets_good_luck.wav';
import audioVoiceNoBetsTr from 'assets/sounds/voices/turkish/no_more_bets_good_luck.wav';

import { LANGUAGES } from 'i18n';

export const placeBetsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceBets,
  [LANGUAGES.zh]: audioVoiceBetsCh,
  [LANGUAGES.fr]: audioVoiceBetsFr,
  [LANGUAGES.es]: audioVoiceBetsSp,
  [LANGUAGES.ru]: audioVoiceBetsRu,
  [LANGUAGES.hi]: audioVoiceBetsHi,
  [LANGUAGES.pt]: audioVoiceBetsPt,
  [LANGUAGES.tl]: audioVoiceBetsTl,
  [LANGUAGES.id]: audioVoiceBetsId,
  [LANGUAGES.tr]: audioVoiceBetsTr,
};

export const noMoreBetsSounds: { [key in LANGUAGES]: string } = {
  [LANGUAGES.en]: audioVoiceNoBets,
  [LANGUAGES.zh]: audioVoiceNoBetsCh,
  [LANGUAGES.fr]: audioVoiceNoBetsFr,
  [LANGUAGES.es]: audioVoiceNoBetsSp,
  [LANGUAGES.ru]: audioVoiceNoBetsRu,
  [LANGUAGES.hi]: audioVoiceNoBetsHi,
  [LANGUAGES.pt]: audioVoiceNoBetsPt,
  [LANGUAGES.tl]: audioVoiceNoBetsTl,
  [LANGUAGES.id]: audioVoiceNoBetsId,
  [LANGUAGES.tr]: audioVoiceNoBetsTr,
};
