import { IState } from 'core';
import { createSelector } from '@reduxjs/toolkit';
import { IDiceStatistics } from './types';

const diceDataSelector = (state: IState) => state.diceReducer.diceStatisticsReducer;

export const diceStatisticsSelector = createSelector(
  diceDataSelector,
  (state: IDiceStatistics) => state.roundResults,
);
