import styled, { css } from 'styled-components';
import { Device } from 'types';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const StatisticsWrapper = styled.div<{ $deviceType: Device }>`
  margin: 0 8rem;
  height: fit-content;
  width: 100%;
  position: absolute;
  bottom: 130rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        z-index: 100;
        margin: 0;
        bottom: ${isInIframe() ? '38rem' : '20rem'};
        gap: ${isInIframe() ? '22rem' : '64rem'};
        display: flex;
        justify-content: center;

        ${StatisticBoardWrap} {
          position: static;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        z-index: 100;
        margin: 0;
        bottom: ${isInIframe() ? '-4rem' : '0'};
        display: flex;
        justify-content: space-between;

        ${StatisticBoardWrap} {
          position: static;
        }
      `;
    }
  }}
`;

export const StatisticBoardWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
`;

export const PercentageWrap = styled(StatisticBoardWrap)`
  left: 0;
`;

export const HistoryWrap = styled(StatisticBoardWrap)`
  right: 0;
`;

export const Text = styled.p`
  font-size: 10rem;
  line-height: 14rem;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(231, 213, 185, 0.6);
  transform: rotate(-90deg);
  margin-right: -82rem;
`;
