import styled, { css, keyframes } from 'styled-components';
import { Device } from 'types';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const diceSlotWinnerAnimation = keyframes`
  0% {
    box-shadow: 0 0 2rem 2rem #fff7eb inset;
  }
  33% {
    box-shadow: inset 0 0 8rem 4rem #fff7eb, inset 0 0 2rem 2rem #fff7eb;
  }
  100% {
    box-shadow: 0 0 2rem 2rem #fff7eb inset;
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const SlotsWrapper = styled.div`
  position: relative;
  background: #434343;
  border: 2rem solid #f1e6d2;
  border-radius: 8rem 8rem 12rem 12rem;
`;

export const BettingAreaWrapper = styled.div<{ $deviceType: Device }>`
  position: relative;
  border-radius: 8rem;
  display: grid;
  grid-template-areas:
    'red triple triple purple'
    'orange yellow green blue';
  grid-auto-columns: minmax(0, 1fr);
  grid-gap: 2rem;
  background: #f1e6d2;
  border: 2rem solid #f1e6d2;
  margin-left: -2rem;
  margin-top: -2rem;
  margin-right: -2rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        grid-template-areas:
          'triple triple triple'
          'red orange yellow'
          'green blue purple';

        ${SlotArea} {
          border-radius: 6rem;
        }
      `;
    }
  }}
`;

export const SlotArea = styled.div<{
  $hasPlacedBet: boolean;
  $deviceType: Device;
  $isMinimized: boolean;
  $isWinner: boolean;
}>`
  position: relative;
  border-radius: 8rem;
  font-size: 16rem;
  height: 88rem;
  box-sizing: border-box;
  border-width: ${({ $hasPlacedBet }) => ($hasPlacedBet ? '3rem' : '0')};
  border-style: solid;

  ${({ $deviceType, $isMinimized, $hasPlacedBet }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        height: ${$isMinimized
          ? isInIframe()
            ? '72rem'
            : '80rem'
          : isInIframe()
            ? '126rem'
            : '136rem'};
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        height: ${$isMinimized ? '40rem' : isInIframe() ? '58rem' : '64rem'};
        border-width: ${$hasPlacedBet ? '2rem' : '0'};
      `;
    }
  }}
  ${({ $isWinner }) =>
    $isWinner &&
    css`
      border: 2rem solid #fff7eb;
      animation: ${diceSlotWinnerAnimation} 800ms ease-out 5;
    `}
`;

export const TripleArea = styled(SlotArea)`
  grid-area: triple;
  background: linear-gradient(180deg, #bb9370 0%, #554333 165.91%);

  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #bb9370;
    `}
`;

export const RedArea = styled(SlotArea)`
  grid-area: red;
  background: linear-gradient(180deg, #ff2455 39.38%, #991633 131.25%);
  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #ff2455;
    `}
`;
export const OrangeArea = styled(SlotArea)`
  grid-area: orange;
  background: linear-gradient(180deg, #ff5e24 39.58%, #993816 131.94%);
  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #ff5e24;
    `}
`;
export const YellowArea = styled(SlotArea)`
  grid-area: yellow;
  background: linear-gradient(180deg, #ffca41 39.17%, #a57703 130.56%);
  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #ffca41;
    `}
`;
export const GreenArea = styled(SlotArea)`
  grid-area: green;
  background: linear-gradient(180deg, #a8d32c 39.17%, #576d17 130.56%);
  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #a8d32c;
    `}
`;
export const BlueArea = styled(SlotArea)`
  grid-area: blue;
  background: linear-gradient(180deg, #2bc5ef 39.17%, #197189 130.56%);
  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #2bc5ef;
    `}
`;
export const PurpleArea = styled(SlotArea)`
  grid-area: purple;
  background: linear-gradient(180deg, #a361f4 39.17%, #5f388e 130.56%);
  ${({ $hasPlacedBet }) =>
    $hasPlacedBet &&
    css`
      border-color: #a361f4;
    `}
`;

export const WinningAmountText = styled.div`
  font-size: 10rem;
  line-height: 16rem;
  font-weight: 400;
  letter-spacing: 0.02rem;
  text-align: center;
  text-transform: uppercase;

  span {
    display: inline-block;
    margin-left: 12rem;
    letter-spacing: 2rem;
  }
`;

export const WinningAmountWrapper = styled.div<{ $deviceType: Device }>`
  position: relative;
  bottom: 0;
  display: flex;
  padding: 4rem 60rem;
  border-radius: 0 0 12rem 12rem;
  justify-content: space-between;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefaultLight};
  `}

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        padding: 2rem 16rem;

        ${WinningAmountText} {
          font-size: 6rem;
          line-height: 12rem;

          span {
            margin-left: 4rem;
          }
        }
      `;
    }
  }}
`;
