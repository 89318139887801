import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { CHIP_1M_PURPLE, chipsMIN2k } from './chipsValues';

export const CHIPS_MIN_2_5k: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 2.5e3,
  },
  ...chipsMIN2k,
  CHIP_1M_PURPLE,
];
