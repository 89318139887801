import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{ $deviceType: Device }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        ${SlotInnerWrapper} {
          font-size: 16rem;
          line-height: 24rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        ${SlotInnerWrapper} {
          font-size: 12rem;
          line-height: 24rem;
          letter-spacing: 1px;
        }
      `;
    }

    return css`
      ${SlotInnerWrapper} {
        font-size: 16rem;
        line-height: 24rem;
        letter-spacing: 2px;
      }
    `;
  }}
`;

export const SlotInnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  text-align: center;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefaultLight};
  `}
`;

export const SlotTitle = styled.p`
  text-transform: uppercase;
`;

export const SlotValue = styled.p`
  margin-left: 20rem;
`;

export const BetWrapper = styled.div<{ positionX: string; positionY: string; size: number }>`
  position: absolute;
  top: ${({ positionX }) => positionX};
  right: ${({ positionY }) => positionY};
  width: ${({ size }) => `${size}rem`};
  height: ${({ size }) => `${size}rem`};

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const ConfirmBetWrapper = styled.div<{
  $deviceType: Device;
  positionX: string;
  positionY: string;
}>`
  position: absolute;
  z-index: 1;
  top: ${({ positionX }) => positionX};
  right: ${({ positionY }) => positionY};

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        svg {
          width: 28rem;
          height: 28rem;
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        svg {
          width: 26rem;
          height: 26rem;
        }
      `;
    }

    return css`
      svg {
        width: 24rem;
        height: 24rem;
      }
    `;
  }}
`;
