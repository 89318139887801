import { clearAllBetsAction } from 'core/widgets/Bet/actions';
import { addNoticeAction } from 'core/widgets/Notices/actions';
import { Notices } from 'core/widgets/Notices/types';
import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { setTotalBetAction } from 'core/widgets/User/actions';
import { put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { RoundStates } from '../../RoundStates/types';
import { OnRoundCanceledData } from '../types';
import { clearAllDiceBetsAction } from 'core/widgets/DiceBet/actions';

export function* onRoundCanceledSaga({ type: notificationType }: OnRoundCanceledData) {
  if (notificationType === Notifications.ROUND_CANCELED) {
    yield put(clearAllBetsAction());
    // @TODO HHH
    yield put(clearAllDiceBetsAction());
    yield put(setTotalBetAction(0));
    yield put(changeRoundStateAction({ roundState: RoundStates.RoundCanceled }));
    yield put(addNoticeAction({ notification: Notices.RoundCanceled }));
  }
}
