import { IState } from 'core';
import { createSelector } from '@reduxjs/toolkit';
import { IDiceWinStatusState } from './types';

const diceWinStatusRootSelector = (state: IState) => state.diceReducer.diceWinStatusReducer;

export const diceWinStatusSelector = createSelector(
  diceWinStatusRootSelector,
  (state: IDiceWinStatusState | null) => state,
);
