import React from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { IconNames } from 'components';
import { ArrowStyled, ScoreboardPortraitWrapper, Text } from './styles';
import { IDeviceScoreboardButtonProps } from './types';
import { useSelector } from 'react-redux';
import { gameTypeSelector } from '../../core/gameTypeReducer';
import { GameType } from 'constants/common';

export const ScoreboardButtonPortrait = ({
  isActive,
  deviceType = Device.Mobile,
  onScoreboardButtonClick,
}: IDeviceScoreboardButtonProps) => {
  const { t } = useTranslation();
  const gameType = useSelector(gameTypeSelector);

  return (
    <ScoreboardPortraitWrapper onClick={onScoreboardButtonClick} $deviceType={deviceType}>
      <Text>
        {gameType === GameType.HHH ? t('scoreboard.titleStatistics') : t('scoreboard.title')}
      </Text>
      <ArrowStyled name={IconNames.ArrowDown} $isActive={isActive} />
    </ScoreboardPortraitWrapper>
  );
};
