import { ResultDice } from 'components/Dice/types';
import { IWinnerDice } from 'constants/common';

export enum ACTIONS {
  SET_DICE_STATISTICS = 'SET_DICE_STATISTICS',
  ADD_NEW_ROUND_RESULT = 'ADD_NEW_ROUND_RESULT',
}

export interface IDiceStatistics {
  roundResults: IWinnerDice[];
}
