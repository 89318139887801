import React from 'react';
import { Device, SLOTS } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { GameType, WinnerBall } from 'constants/common';
import { SettingPanels } from 'widgets/Settings/types';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SlotBlue, SlotGold, SlotJackpot, SlotRed } from './components';
import { SlotsWrapper, Wrapper } from './styles';
import { ISlotsComponentProps } from './types';
import { SlotsJackpotInfo } from './SlotsJackpotInfo';

export const BaseSlots = ({
  slots,
  currentSlot,
  winnerBall,
  activeChip,
  isBettingTime,
  onSlotClick,
  onBetConfirm,
  onBetClear,
  deviceType = Device.Desktop,
  onErrorClick,
  hasBets,
  hasConfirmedBets,
  jackpotSum,
  minBetJP,
  totalConfirmedBets,
  isActiveSlot,
  stage,
  dateTimer,
  modeOnlyOneSlotForBets,
  isActiveCollectionPeriod,
  hasUserInStage,
  currency,
  jackpotCurrency,
}: ISlotsComponentProps) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);
  const minimizedViewboxSizes: Record<Device, string> = {
    [Device.Desktop]: '0 0 1006 51',
    [Device.Tablet_Landscape]: '0 0 1006 51',
    [Device.Tablet_Portrait]: '0 0 722 107',
    [Device.Mobile]: '0 0 346 52',
  };

  const maximizedViewboxSizes: Record<Device, string> = {
    [Device.Desktop]: '0 0 1006 51',
    [Device.Tablet_Landscape]: '0 0 1006 51',
    [Device.Tablet_Portrait]: '0 0 722 107',
    [Device.Mobile]: '0 0 346 75',
  };

  const minimizedViewboxSizesSlots: Record<Device, string> = {
    [Device.Desktop]: '0 0 1006 128',
    [Device.Tablet_Landscape]: '0 0 1006 128',
    [Device.Tablet_Portrait]: '0 0 722 138',
    [Device.Mobile]: '0 0 346 68',
  };

  const maximizedViewboxSizesSlots: Record<Device, string> = {
    [Device.Desktop]: '0 0 1006 128',
    [Device.Tablet_Landscape]: '0 0 1006 128',
    [Device.Tablet_Portrait]: '0 0 722 268',
    [Device.Mobile]: '0 0 346 186',
  };

  const jackpot =
    jackpotSum &&
    `${jackpotCurrency} ${Number(jackpotSum).toLocaleString('en-US').replace(',', ' ')}`;

  const dispatch = useDispatch();

  const onSettingsButtonClick = () => {
    if (jackpotSum) {
      dispatch(
        manageSettingsPanelAction({
          settingsPanel: SettingPanels.Winners,
          hasClickJackpotSlot: true,
        }),
      );
    }
  };

  return (
    <Wrapper>
      <SlotsJackpotInfo
        gameType={GameType.BAC}
        isScoreboardOpen={isScoreboardOpen}
        stage={stage}
        dateTimer={dateTimer}
        deviceType={deviceType}
        jackpotCurrency={jackpotCurrency}
        jackpotSum={Number(jackpotSum)}
        onClick={onSettingsButtonClick}
      />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={
          isScoreboardOpen ? minimizedViewboxSizes[deviceType] : maximizedViewboxSizes[deviceType]
        }
        fill="none">
        <SlotJackpot
          jackpotSum={jackpotSum ? String(jackpotSum) : undefined}
          onClick={onSettingsButtonClick}
          isActiveSlot={isActiveSlot}
          stage={stage}
          dateTimer={dateTimer}
        />
      </svg>
      <SlotsWrapper
        xmlns="http://www.w3.org/2000/svg"
        viewBox={
          isScoreboardOpen
            ? minimizedViewboxSizesSlots[deviceType]
            : maximizedViewboxSizesSlots[deviceType]
        }
        fill="none">
        <SlotGold
          bet={slots[SLOTS.tie]}
          isCurrent={currentSlot === SLOTS.tie}
          isWinner={winnerBall === WinnerBall.GOLD}
          isBettingTime={
            modeOnlyOneSlotForBets
              ? isBettingTime && (!hasBets || hasBets === SLOTS.tie || !hasConfirmedBets)
              : isBettingTime
          }
          activeChip={activeChip}
          isActiveJP={Boolean(
            minBetJP &&
              slots[SLOTS.tie] >= minBetJP &&
              (isActiveCollectionPeriod || (stage && hasUserInStage)),
          )}
          onClick={() => onSlotClick(SLOTS.tie)}
          onBetConfirm={() => onBetConfirm(SLOTS.tie)}
          onBetClear={() => onBetClear(SLOTS.tie)}
          onErrorClick={onErrorClick}
        />
        <SlotBlue
          bet={slots[SLOTS.player]}
          isCurrent={currentSlot === SLOTS.player}
          isWinner={winnerBall === WinnerBall.BLUE}
          isBettingTime={
            modeOnlyOneSlotForBets
              ? isBettingTime && (!hasBets || hasBets === SLOTS.player || !hasConfirmedBets)
              : isBettingTime && !totalConfirmedBets[SLOTS.banker]
          }
          activeChip={activeChip}
          isActiveJP={Boolean(
            minBetJP &&
              slots[SLOTS.player] >= minBetJP &&
              (isActiveCollectionPeriod || (stage && hasUserInStage)),
          )}
          onClick={() => onSlotClick(SLOTS.player)}
          onBetConfirm={() => onBetConfirm(SLOTS.player)}
          onBetClear={() => onBetClear(SLOTS.player)}
          onErrorClick={onErrorClick}
        />
        <SlotRed
          bet={slots[SLOTS.banker]}
          isCurrent={currentSlot === SLOTS.banker}
          isWinner={winnerBall === WinnerBall.RED}
          isBettingTime={
            modeOnlyOneSlotForBets
              ? isBettingTime && (!hasBets || hasBets === SLOTS.banker || !hasConfirmedBets)
              : isBettingTime && !totalConfirmedBets[SLOTS.player]
          }
          activeChip={activeChip}
          isActiveJP={Boolean(
            minBetJP &&
              slots[SLOTS.banker] >= minBetJP &&
              (isActiveCollectionPeriod || (stage && hasUserInStage)),
          )}
          onClick={() => onSlotClick(SLOTS.banker)}
          onBetConfirm={() => onBetConfirm(SLOTS.banker)}
          onBetClear={() => onBetClear(SLOTS.banker)}
          onErrorClick={onErrorClick}
        />
      </SlotsWrapper>
    </Wrapper>
  );
};
