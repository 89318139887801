import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { drawnDicesSelector } from 'core/widgets/DrawnDices/selectors';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { tableInfoSelector } from 'core/widgets/TableSettings';
import { DrawnDicesResponsive } from './Responsive';

export const DrawnDices = () => {
  const winners = useSelector(drawnDicesSelector);
  const roundState = useSelector(roundStateSelector);
  const tableInfo = useSelector(tableInfoSelector);

  const displayUntilNewRoundStarted = useMemo(
    () =>
      roundState === RoundStates.DrawStarted ||
      roundState === RoundStates.DrawFinished ||
      roundState === RoundStates.ResultConfirmed ||
      roundState === RoundStates.RoundFinished,
    [roundState],
  );
  const displayUntilRoundFinished = useMemo(
    () =>
      roundState === RoundStates.DrawStarted ||
      roundState === RoundStates.DrawFinished ||
      roundState === RoundStates.ResultConfirmed,
    [roundState],
  );
  const resultVisible = useMemo(
    () => (tableInfo?.displayingWinBall ? displayUntilRoundFinished : displayUntilNewRoundStarted),
    [tableInfo?.displayingWinBall, displayUntilRoundFinished, displayUntilNewRoundStarted],
  );

  return <>{Boolean(resultVisible) && <DrawnDicesResponsive winners={winners} />}</>;
};
