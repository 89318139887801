import { DICE_SLOTS } from 'types';
import { BetTypeDice } from './types';

export const EMPTY_DICE_BET: BetTypeDice = {
  [DICE_SLOTS.red]: 0,
  [DICE_SLOTS.orange]: 0,
  [DICE_SLOTS.yellow]: 0,
  [DICE_SLOTS.green]: 0,
  [DICE_SLOTS.blue]: 0,
  [DICE_SLOTS.purple]: 0,
  [DICE_SLOTS.triple]: 0,
};
