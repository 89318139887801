import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { diceWinStatusSelector } from 'core/widgets/DiceWinStatus';
import { setDiceWinStatusAction } from 'core/widgets/DiceWinStatus/actions';

import { DiceWinStatusResponsive } from './Responsive';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import AudioService from 'services/AudioService';
import { Sounds } from 'core/widgets/SoundSettings/types';

const WIN_STATUS_DISPLAY_TIME = 3000;

export const DiceWinStatus = () => {
  const dispatch = useDispatch();
  const roundState = useSelector(roundStateSelector);
  const diceWinData = useSelector(diceWinStatusSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { currency } = useSelector(authInfoSelector);

  const soundTimer = useCallback(() => {
    AudioService.enqueue('win', Sounds.SOUND_EFFECTS, true);
  }, []);

  useEffect(() => {
    if (diceWinData !== null) {
      soundTimer();
      const timer = setTimeout(() => {
        dispatch(setDiceWinStatusAction(null));
      }, WIN_STATUS_DISPLAY_TIME);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, gameSoundMute, soundEffects, soundTimer, diceWinData]);

  return (
    <>
      {roundState !== RoundStates.ResultConfirmed || diceWinData === null ? null : (
        <DiceWinStatusResponsive
          winnerDices={diceWinData.winners}
          winnerAmount={diceWinData.winningAmount}
          currency={currency}
        />
      )}
    </>
  );
};
