import React from 'react';
import { useTranslation } from 'react-i18next';
import { Device } from 'types';
import { ToastTypes } from 'types/toast';
import { useSelector } from 'react-redux';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { Wrapper, ToastItem, ToastMessage } from './styles';
import { IDeviceToastProps } from './types';

export const BaseToast = ({
  type = ToastTypes.DEFAULT,
  message,
  deviceType = Device.Desktop,
}: IDeviceToastProps) => {
  const { t } = useTranslation();
  const gameType = useSelector(gameTypeSelector);

  return (
    <Wrapper $deviceType={deviceType} $gameType={gameType}>
      <ToastMessage type={type} $deviceType={deviceType}>
        {t(message)}
      </ToastMessage>
      <ToastItem
        type={type}
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 360 40"
        fill="none">
        <path
          d="M0 40L360 40V0L0 0L0 40Z"
          fill="url(#paint0_linear_658_43228)"
          fillOpacity="0.64"
        />
        <path
          d="M0.5 37.5L359.5 37.5V2.5L0.5 2.5L0.5 37.5Z"
          stroke="url(#paint1_linear_658_43228)"
        />
        {/* <ToastMessage x="50%" y="50%" dominantBaseline="middle" textAnchor="middle">
          {t(message)}
        </ToastMessage> */}
        <defs>
          <linearGradient
            id="paint0_linear_658_43228"
            x1="0"
            y1="20"
            x2="360"
            y2="20"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#121011" stopOpacity="0" />
            <stop offset="0.3125" stopColor="#121011" />
            <stop offset="0.65" stopColor="#121011" />
            <stop offset="1" stopColor="#121011" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_658_43228"
            x1="360"
            y1="20"
            x2="0"
            y2="20"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#F1E6D2" stopOpacity="0" />
            <stop offset="0.5" stopColor="#F1E6D2" />
            <stop offset="1" stopColor="#F1E6D2" stopOpacity="0" />
          </linearGradient>
        </defs>
      </ToastItem>
    </Wrapper>
  );
};
