import { saveWinnerBallToStoreAction } from 'core/widgets/WinnerBall/actions';
import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { put, select } from 'redux-saga/effects';
import { Notifications } from 'types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { resetRoundWinnersDataAction } from 'core/widgets/WinnersTicker/actions';
import { roundStatusMessages } from 'constants/toastMessages';
import { setJackpotWonSum, setJackpotWonWinners } from 'core/widgets/Jackpot/actions';
import { OnDrawFinishedData, ResultBall } from '../../WinnerBall/types';
import { RoundStates } from '../../RoundStates/types';
import { saveDrawnDicesAction } from 'core/widgets/DrawnDices/actions';
import { ResultDice } from 'components/Dice/types';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType, IWinnerDice, WinnerBall } from 'constants/common';

export function* onDrawFinishedSaga({ type: notificationType, data: result }: OnDrawFinishedData) {
  const { winner, winners } = result;
  const gameType: GameType | null = yield select(gameTypeSelector);

  if (notificationType === Notifications.DRAW_FINISHED) {
    yield put(changeRoundStateAction({ roundState: RoundStates.DrawFinished }));
    yield put(
      addToastAction({
        value:
          gameType === GameType.HHH
            ? roundStatusMessages.DRAW_FINISHED_DICE
            : roundStatusMessages.DRAW_FINISHED,
      }),
    );
    yield put(resetRoundWinnersDataAction());
    yield put(setJackpotWonSum({ sum: null }));
    yield put(setJackpotWonWinners({ players: null }));

    if (winner !== 'UNDEFINED') {
      yield put(saveWinnerBallToStoreAction({ winner }));
    }

    // @TODO HHH
    if (gameType === GameType.HHH) {
      // @TODO HHH
      if (winner !== 'UNDEFINED') {
        yield put(
          saveDrawnDicesAction({
            winners: [winners.firstDice, winners.secondDice, winners.thirdDice],
          }),
        );
      }
    }
  }
}
