import { put, select } from 'redux-saga/effects';
import { call } from 'ramda';
import * as Sentry from '@sentry/react';

import { roundStateSelector } from 'core/widgets/RoundStates';
import { IRoundState, RoundStates } from 'core/widgets/RoundStates/types';
import {
  ICreatePendingBetRequestData,
  ICreatePendingBetResponseData,
  ICreatePendingDiceBetRequestData,
  RestService,
} from 'services/RestAPIService';
// import { summaryBetSelector } from '../selectors';
// import { BetType } from '../types';
import { setTotalBetAction } from 'core/widgets/User/actions';
// import { getBetSum } from '../utils';
// import { clearAllBetsAction } from '../actions';
import { totalBetSelector } from 'core/widgets/User';
import { transformToCurrency, truncationNumber } from 'utils/transformToCurrency';
import { DiceBetType } from 'core/widgets/Bet/types';
import { clearAllBetsAction } from 'core/widgets/Bet/actions';
import { getBetSum } from 'core/widgets/Bet/utils';
import { placedDiceBetSelector } from '../selectors';
import { BetTypeDice } from '../types';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { clearAllDiceBetsAction } from '../actions';

export function* createPendingDiceBetSaga() {
  const roundState: IRoundState['roundState'] = yield select(roundStateSelector);

  if (roundState === RoundStates.BettingTimeStarted) {
    // const placedBet: DiceBetType = yield select(summaryBetSelector);
    const placedBetResult: BetTypeDice = yield select(placedDiceBetSelector);
    const data: ICreatePendingDiceBetRequestData = {
      red: truncationNumber(placedBetResult.red),
      orange: truncationNumber(placedBetResult.orange),
      yellow: truncationNumber(placedBetResult.yellow),
      green: truncationNumber(placedBetResult.green),
      blue: truncationNumber(placedBetResult.blue),
      purple: truncationNumber(placedBetResult.purple),
      triple: truncationNumber(placedBetResult.triple),
      timeStamp: new Date().getTime(),
    };

    try {
      const response: ICreatePendingBetResponseData = yield call(() =>
        RestService.createPendingDiceBet(data),
      );

      const totalBet = getDiceBetSum([placedBetResult]);
      yield put(setTotalBetAction(totalBet));
    } catch (error) {
      Sentry.captureException({ error, data });
      const totalBet: number | null = yield select(totalBetSelector);

      if (!totalBet) {
        yield put(clearAllDiceBetsAction());
      }

      console.log(error);
    }
  }
}
