import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { IDrawnDicesState } from './types';

const winnerDicesSelector = (state: IState) => state.diceReducer.drawnDicesReducer;

export const drawnDicesSelector = createSelector(
  winnerDicesSelector,
  (state: IDrawnDicesState) => state.winners,
);
