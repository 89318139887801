import React, { memo, useRef, useEffect, useState } from 'react';
import { Device } from 'types';
import { transformToCurrency } from 'utils/transformToCurrency';
import { RoundWinner } from 'core/widgets/WinnersTicker/types';
import { WinAmount, WinnerName, WinnerStyled, Wrapper, ContentWrapper } from './styles';
import { IDeviceRoundWinnersTickerProps } from './types';
import { IWinnerJP } from 'core/widgets/Jackpot/types';
import { useSelector } from 'react-redux';
import { gameTypeSelector } from '../../core/gameTypeReducer';

export const BaseWinnersTicker = memo(
  ({
    roundWinners,
    isHidingStarted,
    deviceType = Device.Desktop,
  }: IDeviceRoundWinnersTickerProps) => {
    const gameType = useSelector(gameTypeSelector);

    const [sizes, setSizes] = useState({
      content: 0,
      container: 0,
    });
    const refContent = useRef<HTMLDivElement>(null);
    const refContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (refContent.current && refContainer.current) {
        setSizes({
          content: refContent.current.offsetWidth,
          container: refContainer.current.offsetWidth,
        });
      }
    }, [refContent, refContainer]);

    return (
      <Wrapper
        ref={refContainer}
        $gameType={gameType}
        $deviceType={deviceType}
        $isHiding={isHidingStarted}
        $winnersQty={roundWinners.length}
        $containerSize={sizes.container}
        $contentSize={sizes.content}>
        <ContentWrapper ref={refContent}>
          {roundWinners.map((winner) => (
            <WinnerStyled
              key={
                (winner as IWinnerJP).name
                  ? `${(winner as IWinnerJP).name}_${(winner as IWinnerJP).amount}`
                  : (winner as RoundWinner).player
              }>
              {(winner as IWinnerJP).name && (
                <>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.99991 8.63486L8.58491 10.1949C8.77491 10.3099 9.00991 10.1399 8.95991 9.92486L8.27491 6.98486L10.5549 5.00986C10.7199 4.86486 10.6349 4.58986 10.4099 4.56986L7.40491 4.31486L6.22991 1.54486C6.14491 1.33986 5.85491 1.33986 5.76991 1.54486L4.59491 4.31486L1.58991 4.56986C1.36991 4.58986 1.27991 4.86486 1.44991 5.00986L3.72991 6.98486L3.04491 9.92486C2.99491 10.1399 3.22991 10.3099 3.41991 10.1949L5.99991 8.63486Z"
                      fill="#FFF7EB"
                    />
                  </svg>
                  {(winner as IWinnerJP).amount && (
                    <WinAmount>
                      {transformToCurrency({
                        value: (winner as IWinnerJP).amount,
                        currency: (winner as IWinnerJP).currency,
                      })}
                    </WinAmount>
                  )}
                  <WinnerName>{(winner as IWinnerJP).name}</WinnerName>
                </>
              )}
              {(winner as RoundWinner).player && (
                <>
                  <WinAmount>
                    {transformToCurrency({
                      value: (winner as RoundWinner).amount,
                      currency: (winner as RoundWinner).currency,
                    })}
                  </WinAmount>
                  <WinnerName>{(winner as RoundWinner).player}</WinnerName>
                </>
              )}
            </WinnerStyled>
          ))}
        </ContentWrapper>
      </Wrapper>
    );
  },
);
