import { Device } from 'types';

export enum WinnerDices {
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
}

export interface IDiceWinStatusProps {
  winnerDices: WinnerDices[];
  winnerAmount: number;
  currency: string | null;
}

export interface IDeviceDiceWinStatusProps extends IDiceWinStatusProps {
  deviceType?: Device;
}
