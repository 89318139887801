import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { DiceSlotResponsive } from './Responsive';
import { IBaseDiceSlotProps } from './types';

export const DiceSlot = ({ bet, ...rest }: IBaseDiceSlotProps) => {
  const hasPlacedBet = useMemo(() => bet !== 0, [bet]);
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return (
    <DiceSlotResponsive
      {...{ bet, ...rest }}
      hasPlacedBet={hasPlacedBet}
      isMinimized={isScoreboardOpen}
    />
  );
};
