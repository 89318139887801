import { Icon } from 'components/Icon';
import { colors } from 'constants/theme';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import { isInIframe } from 'hooks/getDefineDevEnv';

export const CloseIcon = styled(Icon)`
  position: absolute;
  height: 16rem;
  width: 16rem;
  cursor: pointer;
`;

export const Title = styled.p`
  text-transform: uppercase;
`;

export const Item = styled.div`
  border-top: 1rem solid;
  border-image-slice: 1 0 0 0;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 52.08%, #121011 100%);
  font-size: 12rem;
`;

export const ItemTitle = styled.p`
  font-size: 12rem;
  line-height: 14rem;
  text-transform: uppercase;
  width: calc(100% - 38rem);
  padding-right: 10rem;
`;

export const Wrapper = styled.div<{ $deviceType: Device; $isOldBrowser: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;

  ${({ $deviceType, $isOldBrowser }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        width: 100%;
        ${CloseIcon} {
          top: 5rem;
          right: 24rem;
        }

        ${Title} {
          margin: 8rem 24rem;
          font-size: 10rem;
          line-height: 14rem;
        }

        ${Item} {
          padding: ${$isOldBrowser && !isInIframe() ? '24rem 24rem' : '32rem 24rem'};
        }
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        ${CloseIcon} {
          top: 24rem;
          right: 24rem;
        }

        ${Title} {
          /* margin: 22rem 24rem; */
          margin: 22rem 44rem 22rem 24rem;
          font-size: 14rem;
          line-height: 20rem;
        }

        ${Item} {
          padding: 40rem 24rem;
        }
      `;
    }

    return css`
      ${CloseIcon} {
        top: 36rem;
        right: 40rem;
      }

      ${Title} {
        margin: 36rem 58rem 32rem 40rem;
        font-size: 14rem;
        line-height: 20rem;
      }

      ${Item} {
        padding: 40rem;
      }
    `;
  }}
`;

export const VersionInfo = styled.div`
  color: ${colors.Subtitle};

  text-align: center;
  font-size: 12rem;
  line-height: 14rem;
  text-transform: uppercase;

  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
`;
