import styled, { css } from 'styled-components';
import { CHIPS_COLORS } from 'widgets/Chips/data/currencies/colors';
import { CursorChips } from 'widgets/Slots/data';

export const Wrapper = styled.div<{ $isBettingTime: boolean; $activeChip?: CHIPS_COLORS }>`
  background-color: transparent;
  height: 100%;
  box-sizing: border-box;
  position: relative;

  ${({ $isBettingTime, $activeChip }) => {
    if (!$isBettingTime) {
      return css`
        cursor: not-allowed;
      `;
    }

    if ($activeChip) {
      return css`
        cursor:
          url(${CursorChips[$activeChip]}) 8 8,
          pointer;
      `;
    }
  }}
`;
