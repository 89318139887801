import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { CHIP_50K_LIGHT_GREEN } from './chipsValues';

const chipsMIN50 = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 50,
  },
  {
    color: CHIPS_COLORS.YELLOW,
    value: 100,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 200,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 500,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 1e3,
  },
];

export const CHIPS_MIN_50: ChipType[] = [
  ...chipsMIN50,
  {
    color: CHIPS_COLORS.ORANGE,
    value: 2e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 20e3,
  },
  CHIP_50K_LIGHT_GREEN,
];

export const CHIPS_MIN_50_v2: ChipType[] = [
  ...chipsMIN50,
  {
    color: CHIPS_COLORS.ORANGE,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 200e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_GREEN,
    value: 500e3,
  },
];

export const CHIPS_MIN_50_v3: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 50,
  },
  {
    color: CHIPS_COLORS.YELLOW,
    value: 100,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 500,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 1e3,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 100e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 500e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_GREEN,
    value: 1e6,
  },
];
