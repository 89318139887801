import React from 'react';

import DiceWinLeft from 'assets/icons/diceWIn_left.png';
import DiceWinCenter from 'assets/icons/diceWin_center.png';
import DiceWinRight from 'assets/icons/diceWin_right.png';

export const DiceWinGradientBase = () => (
  <defs>
    <filter
      id="filter0_d_688_63009"
      x="25"
      y="0"
      width="86.2793"
      height="88"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="16" />
      <feGaussianBlur stdDeviation="12" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.8 0"
      />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_688_63009" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_688_63009" result="shape" />
    </filter>
    <pattern id="pattern0_688_63009" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlinkHref="#image0_688_63009" transform="scale(0.0016129 0.00154352)" />
    </pattern>
    <pattern id="pattern1_688_63009" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlinkHref="#image0_688_63009" transform="scale(0.0016129 0.00154352)" />
    </pattern>
    <filter
      id="filter1_d_688_63009"
      x="69.2793"
      y="0"
      width="76.8872"
      height="88"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="16" />
      <feGaussianBlur stdDeviation="12" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.8 0"
      />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_688_63009" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_688_63009" result="shape" />
    </filter>
    <pattern id="pattern2_688_63009" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlinkHref="#image1_688_63009" transform="scale(0.00213689 0.00154321)" />
    </pattern>
    <pattern id="pattern3_688_63009" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlinkHref="#image1_688_63009" transform="scale(0.00213689 0.00154321)" />
    </pattern>
    <filter
      id="filter2_d_688_63009"
      x="104.167"
      y="0"
      width="86.2793"
      height="88"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="16" />
      <feGaussianBlur stdDeviation="12" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0.0705882 0 0 0 0 0.0627451 0 0 0 0 0.0666667 0 0 0 0.8 0"
      />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_688_63009" />
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_688_63009" result="shape" />
    </filter>
    <pattern id="pattern4_688_63009" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use
        xlinkHref="#image2_688_63009"
        transform="matrix(0.00161812 0 0 0.00154851 0 -0.00171784)"
      />
    </pattern>
    <pattern id="pattern5_688_63009" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use
        xlinkHref="#image2_688_63009"
        transform="matrix(0.00161812 0 0 0.00154851 0 -0.00171784)"
      />
    </pattern>
    <linearGradient
      id="paint0_linear_688_63009"
      x1="108"
      y1="132"
      x2="108"
      y2="20"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.4" stop-color="#121011" />
      <stop offset="1" stop-color="#343434" stop-opacity="0.2" />
    </linearGradient>
    <linearGradient
      id="paint1_linear_688_63009"
      x1="214"
      y1="76"
      x2="1.99951"
      y2="76"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#F1E6D2" stop-opacity="0" />
      <stop offset="0.5" stop-color="#F1E6D2" />
      <stop offset="1" stop-color="#F1E6D2" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint2_linear_688_63009"
      x1="216"
      y1="41.5"
      x2="-0.000488281"
      y2="41.5"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#121011" stop-opacity="0" />
      <stop offset="0.5" stop-color="#F1E6D2" />
      <stop offset="1" stop-color="#121011" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint3_linear_688_63009"
      x1="136.757"
      y1="54"
      x2="136.757"
      y2="69.4873"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#121011" />
      <stop offset="1" stop-color="#121011" />
    </linearGradient>
    <linearGradient
      id="paint4_linear_688_63009"
      x1="36.283"
      y1="62.9999"
      x2="172.787"
      y2="62.9999"
      gradientUnits="userSpaceOnUse">
      <stop stop-color="#121011" stop-opacity="0" />
      <stop offset="0.505822" stop-color="#BB9370" />
      <stop offset="1" stop-color="#121011" stop-opacity="0" />
    </linearGradient>
    <linearGradient
      id="paint5_linear_688_63009"
      x1="125.426"
      y1="72.4865"
      x2="125.426"
      y2="37.9459"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.25" stop-color="#F1E6D2" stop-opacity="0" />
      <stop offset="0.520833" stop-color="#F1E6D2" />
      <stop offset="0.802083" stop-color="#F1E6D2" stop-opacity="0" />
    </linearGradient>
    <image id="image0_688_63009" width="620" height="648" xlinkHref={DiceWinLeft} />
    <image id="image1_688_63009" width="468" height="648" xlinkHref={DiceWinCenter} />
    <image id="image2_688_63009" width="618" height="648" xlinkHref={DiceWinRight} />
  </defs>
);
