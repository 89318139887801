import { WinnerDices } from 'widgets/DiceWinStatus/types';

export enum ACTIONS {
  SET_DICE_WIN_STATUS = 'SET_DICE_WIN_STATUS',
}

export interface IDiceWinStatusState {
  winners: WinnerDices[];
  winningAmount: number;
}
