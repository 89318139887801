import { PlatformType } from 'hooks';
import { call, fork, put } from 'redux-saga/effects';
import { chatWebSocketService } from 'services/ChatWebSocketService';
import { webSocketService } from 'services/WebSocketService';
import { ActionType } from 'typesafe-actions';
import {
  listenUserChatBlocked,
  listenUserChatUnblocked,
  listenNewChatMessage,
  listenChatPlayerConnectedMessage,
  listenSocketChatConnectedMessage,
  listenSocketChatDisconnectedMessage,
} from '../listeners';

export function* connectToChatWebSocketSaga({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  payload: { token },
}: ActionType<any>) {
  // const isConnected: boolean = yield call(() => webSocketService.isConnected());
  const isConnectedChat: boolean = yield call(() => chatWebSocketService.isConnected());
  const isConnecting = chatWebSocketService.isChatConnecting();
  if (!isConnecting && !isConnectedChat && token) {
    if (!token) {
      // TODO: handle case if there is no auth data from pathname and localStorage
      return;
    }

    yield call(() => chatWebSocketService.connect(token as string));
    yield fork(listenChatPlayerConnectedMessage);
    yield fork(listenUserChatBlocked);
    yield fork(listenUserChatUnblocked);
    yield fork(listenNewChatMessage);
    yield fork(listenSocketChatConnectedMessage);
    yield fork(listenSocketChatDisconnectedMessage);
  }
}
