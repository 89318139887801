import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{ $deviceType: Device }>`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: 3;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        top: 16rem;
        left: 16rem;
        width: 192rem;
        height: 88rem;
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 88rem;
        left: 24rem;
        width: 152rem;
        height: 72rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 40rem;
        left: 8rem;
        width: 80rem;
        height: 34rem;
      `;
    }
  }}
`;

export const Background = styled.div<{ $deviceType: Device }>`
  position: absolute;
  top: 2rem;
  left: 3rem;
  background: linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #343434 100%), #121011;
  z-index: 1;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        width: 190rem;
        height: 86rem;
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 148rem;
        height: 70rem;
        top: 2rem;
        left: 3rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        width: 77rem;
        height: 38rem;
      `;
    }
  }}
`;

export const BorderGradient = styled.div<{ $deviceType: Device }>`
  position: absolute;
  top: 2rem;
  left: 2rem;
  border: 2rem solid;
  border-image: linear-gradient(
    180deg,
    rgba(241, 230, 210, 0.4) 0%,
    #f1e6d2 49.26%,
    rgba(241, 230, 210, 0.4) 100%
  );
  border-image-slice: 1;
  z-index: 3;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        width: 188rem;
        height: 84rem;
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 148rem;
        height: 68rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        width: 76rem;
        height: 36rem;
        border-width: 1rem;
      `;
    }
  }}
`;

export const InnerWrapper = styled.div<{ $deviceType: Device }>`
  top: 4rem;
  left: 4rem;
  position: absolute;
  border-image: linear-gradient(
    180deg,
    rgba(241, 230, 210, 0.4) 0%,
    #f1e6d2 49.26%,
    rgba(241, 230, 210, 0.4) 100%
  );
  border-image-slice: 1;
  border: 2rem solid #2b2a2b;
  z-index: 4;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        width: 182rem;
        height: 78rem;
        top: 5rem;
        left: 5rem;
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 144rem;
        height: 64rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        width: 70rem;
        height: 30rem;
      `;
    }
  }}
`;

export const Winners = styled.div<{ $deviceType: Device }>`
  position: absolute;
  display: flex;
  z-index: 6;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        top: 24rem;
        left: 24rem;
        gap: 12rem;
      `;
    }

    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        top: 20rem;
        left: 18rem;
        gap: 10rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        top: 12rem;
        left: 10rem;
        gap: 6rem;
      `;
    }
  }}
`;

export const DiceWrap = styled.div<{ $deviceType: Device }>`
  width: 40rem;
  height: 40rem;
  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 32rem;
        height: 32rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        width: 16rem;
        height: 16rem;
      `;
    }
  }}
`;
