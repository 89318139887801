import styled, { css } from 'styled-components';
import { Device } from 'types';
import { Icon } from 'components';

export const LandscapeWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30rem;
  padding: 8rem 24rem;
  border: 1rem solid;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 50%, #121011 100%);
  border-image-slice: 1;

  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}
`;

const Container = styled.div`
  display: flex;
  white-space: nowrap;
`;

export const ContainerLeft = styled(Container)``;

export const ContainerRight = styled(Container)``;

export const PortraitContainer = styled(Container)`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

export const ItemInfoLandscape = styled.div<{ $isBigContent: boolean }>`
  font-size: 12rem;
  line-height: 14rem;
  text-transform: uppercase;

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};

    &:not(:first-of-type) {
      &::before {
        content: 'I';
        display: inline-block;
        margin: 0 20rem;
        color: ${text.primaryDisabled};
      }
    }
  `}

  ${({ $isBigContent }) => {
    if ($isBigContent)
      return css`
        &:not(:first-of-type) {
          &::before {
            margin: 0 20rem;
          }
        }
      `;
    if (!$isBigContent)
      return css`
           &:not(:first-of-type) {
            &::before {
              margin: 0 40rem;
            }
      `;
  }}
`;

export const ItemInfoPortrait = styled.div`
  line-height: 14rem;
  text-transform: uppercase;

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const ItemInfoLabel = styled.span`
  opacity: 0.6;
`;

export const ItemInfoValue = styled.span``;

export const ItemInfoSeparator = styled.div`
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};

    &::before {
      content: 'I';
      font-size: 12rem;
      display: inline-block;
      color: ${text.primaryDisabled};
    }
  `}
`;

export const ItemInfoTopPortrait = styled(ItemInfoPortrait)``;

export const ItemInfoBottomPortrait = styled(ItemInfoPortrait)``;

export const PortraitWrapper = styled.div<{ $deviceType?: Device }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  height: 100%;
  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait)
      return css`
        padding: 8rem 24rem;

        ${ItemInfoTopPortrait} {
          font-size: 12rem;
        }

        ${ItemInfoBottomPortrait} {
          font-size: 12rem;
        }
      `;
    if ($deviceType === Device.Mobile)
      return css`
        padding: 4rem 8rem;

        ${ItemInfoTopPortrait} {
          font-size: 8rem;

          @media only screen and (max-width: 430px) {
            font-size: 6rem;
          }
        }

        ${ItemInfoBottomPortrait} {
          font-size: 6rem;
        }

        ${IconStyled} {
          width: 10rem;
          height: 10rem;
          margin-left: 6rem;
          margin-top: 2rem;
        }
      `;
  }}
`;

export const IconStyled = styled(Icon)`
  cursor: pointer;
  height: 14rem;
  width: 14rem;
  margin-left: 8rem;
`;

export const LimitsWrapper = styled.div`
  display: flex;
`;
