import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{
  $deviceType: Device;
  $hasJackpot?: boolean;
  $isMinimized?: boolean;
}>`
  height: 50rem;
  border-radius: 12rem 12rem 8rem 8rem;
  border-width: 2rem;
  border-style: solid;
  margin-bottom: 2rem;

  background: #121011;
  backdrop-filter: blur(5.657520771026611px);

  ${({ $hasJackpot }) =>
    $hasJackpot
      ? css`
          box-shadow:
            0px 4px 8px 0px #b900fa inset,
            0px -8px 12px 0px #5b10f2 inset;
        `
      : css`
          box-shadow: 0px 2.83px 16.97px 0px #fff7eb40 inset;
        `}

  ${({ $deviceType, $isMinimized }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        height: ${$isMinimized ? '42rem' : '46rem'};
      `;
    }
  }}
`;
