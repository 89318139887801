import { betMessages } from 'constants/toastMessages';
import { placeBetSuccessAction } from 'core/widgets/Bet/actions';
import { getBetSum } from 'core/widgets/Bet/utils';
import { previousConfirmationSlotSelector } from 'core/widgets/BetConfirmation';
import {
  setClosestConfirmationSlotAction,
  setConfirmationSlotAction,
} from 'core/widgets/BetConfirmation/actions';
import { addToastAction } from 'core/widgets/Toast/actions';
import { balanceSelector } from 'core/widgets/User';
import { put, select } from 'redux-saga/effects';
import { SLOTS } from 'types';
import { ToastTypes } from 'types/toast';
import { repeatBetSuccessAction } from '../actions';
import { betRepeatRootSelector } from '../selectors';
import { IBetRepeatState } from '../types';
import { BetType, DiceBetType } from 'core/widgets/Bet/types';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType } from 'constants/common';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { placeDiceBetSuccessAction } from 'core/widgets/DiceBet/actions';

export function* repeatBetSaga() {
  const balance: number | null = yield select(balanceSelector);
  const { isUsed, isConfirmed, value }: IBetRepeatState = yield select(betRepeatRootSelector);
  const gameType: GameType = yield select(gameTypeSelector);

  if (gameType === GameType.BAC) {
    // @TODO HHH
    const betValue = value as BetType;

    if (value === null || balance === null || isUsed || isConfirmed) {
      return;
    }

    const lastBetSum = getBetSum([betValue]);

    if (lastBetSum > balance) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    yield put(repeatBetSuccessAction());
    yield put(placeBetSuccessAction(betValue));

    const previousConfirmationSlot: SLOTS | null = yield select(previousConfirmationSlotSelector);

    if (previousConfirmationSlot !== null) {
      yield put(setConfirmationSlotAction(previousConfirmationSlot));
      return;
    }

    yield put(setClosestConfirmationSlotAction());
  }

  if (gameType === GameType.HHH) {
    // @TODO HHH
    const betValue = value as DiceBetType;

    if (value === null || balance === null || isUsed || isConfirmed) {
      return;
    }

    const lastBetSum = getDiceBetSum([betValue]);

    if (lastBetSum > balance) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    yield put(repeatBetSuccessAction());
    yield put(placeDiceBetSuccessAction(betValue));

    const previousConfirmationSlot: SLOTS | null = yield select(previousConfirmationSlotSelector);

    if (previousConfirmationSlot !== null) {
      yield put(setConfirmationSlotAction(previousConfirmationSlot));
      return;
    }

    yield put(setClosestConfirmationSlotAction());
  }
}
