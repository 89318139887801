import { ActionType, createReducer } from 'typesafe-actions';
import { setDiceWinStatusAction } from './actions';
import { IDiceWinStatusState } from './types';

export const diceWinStatusState: IDiceWinStatusState | null = null;

export const actions = {
  setDiceWinStatusAction,
};

export const diceWinStatusReducer = createReducer<
  IDiceWinStatusState | null,
  ActionType<typeof actions>
>(diceWinStatusState).handleAction(setDiceWinStatusAction, (state, { payload }) => payload);
