import React, { FunctionComponent, SVGProps } from 'react';
import * as Icons from './Icons';

export enum IconNames {
  Wallet = 'Wallet',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  TriangleDown = 'TriangleDown',
  WinnerRed = 'WinnerRed',
  WinnerBlue = 'WinnerBlue',
  WinnerGold = 'WinnerGold',
  BlueBall = 'BlueBall',
  RedBall = 'RedBall',
  TieBall = 'TieBall',
  DoubleBet = 'DoubleBet',
  DoubleBetDisabled = 'DoubleBetDisabled',
  RepeatBet = 'RepeatBet',
  RepeatBetDisabled = 'RepeatBetDisabled',
  GameRule = 'GameRule',
  CloseGame = 'CloseGame',
  CloseGameMirrored = 'CloseGameMirrored',
  ArrowDown = 'ArrowDown',
  Cross = 'Cross',
  Sound = 'Sound',
  NoSound = 'NoSound',
  Video = 'Video',
  General = 'General',
  FullscreenOff = 'FullscreenOff',
  FullscreenOn = 'FullscreenOn',
  Scoreboard = 'Scoreboard',
  Winners = 'Winners',
  Star = 'Star',
  GameRule2 = 'GameRule2',
  History = 'History',
  Checked = 'Checked',
  Unchecked = 'Unchecked',
  Dots_portrait = 'Dots_portrait',
  Dots_desktop = 'Dots_desktop',
  Chat = 'Chat',
  ArrowSend = 'ArrowSend',
  BlackArrowSend = 'BlackArrowSend',
  Info = 'Info',
}

export interface IProps {
  name: IconNames;
  onClick?: () => void;
  className?: string;
}

const IconsMap: Record<IconNames, FunctionComponent<SVGProps<SVGSVGElement>>> = Icons;

export const Icon = ({ name, onClick, className }: IProps) => {
  const IconComponent = IconsMap[name];

  return <IconComponent onClick={onClick} className={className} />;
};
