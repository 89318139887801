import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Device } from 'types';
import { Dice } from 'components/Dice';
import { ResultDice } from 'components/Dice/types';

import { Background, BorderGradient, DiceWrap, InnerWrapper, Winners, Wrapper } from './styles';
import { IDeviceDrawnDices } from './types';

export const BaseDrawnDices = ({ winners, deviceType = Device.Desktop }: IDeviceDrawnDices) => {
  return (
    <Wrapper $deviceType={deviceType}>
      <Winners $deviceType={deviceType}>
        {winners?.length
          ? winners?.map((dice) => (
              <DiceWrap key={uuidv4()} $deviceType={deviceType}>
                <Dice value={dice} />
              </DiceWrap>
            ))
          : [...Array(3)].map(() => (
              <DiceWrap key={uuidv4()} $deviceType={deviceType}>
                <Dice value={ResultDice.UNDEFINED} />
              </DiceWrap>
            ))}
      </Winners>
      <Background $deviceType={deviceType} />
      <InnerWrapper $deviceType={deviceType} />
      <BorderGradient $deviceType={deviceType} />
    </Wrapper>
  );
};
