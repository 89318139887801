import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { transformToCurrencyDecimal } from 'utils/transformToCurrency';
import { roundToFixed } from 'utils/roundToFix';
import { WinnerBall } from 'constants/common';
import { Icon, IconNames } from 'components/Icon';
import { historySelector } from 'core/widgets/History';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { Device } from 'types';
import {
  ListItem,
  Wrapper,
  ItemValue,
  Box,
  ItemType,
  SlotValue,
  WinLoss,
  Slots2,
  Row,
  ItemGradient,
  ArrowWrapper,
} from './stylesTable';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { EmptyHistory } from './styles';
import AudioService from 'services/AudioService';

export const HistoryTableMobile = ({ deviceType }: { deviceType: Device }) => {
  const { t } = useTranslation();

  const { currency, jackpotOn } = useSelector(authInfoSelector);
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const history = useSelector(historySelector);

  const [openedItem, setOpenedItem] = useState<string | null>(null);

  const toggleOpenedItem = (roundId: string) => () => {
    setOpenedItem(roundId === openedItem ? null : roundId);
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  };

  return (
    <>
      <Wrapper $deviceType={deviceType}>
        {history?.length === 0 && <EmptyHistory>{t('history.noBettingHistory')}</EmptyHistory>}
        {history?.map((round: any, index: number) => (
          <Row key={`${round?.roundId}`}>
            <ListItem
              onClick={toggleOpenedItem(round?.roundId)}
              key={`${round?.roundId}`}
              $isGray={!(index % 2)}
              $disable={false}
              $deviceType={deviceType}>
              {/* <Row> */}
              <Box $width={deviceType === Device.Mobile ? 40 : jackpotOn ? 30 : 34}>
                <ItemValue>
                  <ItemType>{t('history.id')}:&nbsp;</ItemType> {round.roundId}
                </ItemValue>
                <ItemValue>
                  <ItemType>{t('history.date')}:&nbsp;</ItemType>
                  {moment(new Date(round.roundDate)).format('DD.MM.YYYY HH:mm')}
                </ItemValue>
                <ItemValue>
                  <ItemType>{t('history.roundStatus')}:&nbsp;</ItemType>{' '}
                  {t(`history.${round.roundStatus}`)}
                </ItemValue>
                <ItemValue>
                  <ItemType>{t('history.balance')}:&nbsp;</ItemType>{' '}
                  {transformToCurrencyDecimal({
                    value: round.balance !== null ? roundToFixed(round.balance, 2) : 0,
                    currency,
                  }).replace(/-/g, '')}
                </ItemValue>
                {deviceType === Device.Mobile && jackpotOn && (
                  <ItemValue>
                    <ItemType>{t('history.jackpot')}:&nbsp;</ItemType>{' '}
                    {round.jackpot ? (
                      <Icon name={IconNames.Checked} />
                    ) : (
                      <Icon name={IconNames.Unchecked} />
                    )}{' '}
                  </ItemValue>
                )}
              </Box>
              <Box
                $width={deviceType === Device.Mobile ? (jackpotOn ? 27 : 26) : jackpotOn ? 28 : 30}>
                <ItemValue>
                  {round.betAmount === 0
                    ? '-'
                    : `${transformToCurrencyDecimal({ value: round.betAmount, currency })}`}
                </ItemValue>
              </Box>
              <Box $width={deviceType === Device.Mobile ? 30 : 32}>
                {round.resultAmount === 0 && round.roundStatus === 'CANCELED' ? (
                  <ItemValue>-</ItemValue>
                ) : (
                  <ItemValue>
                    <WinLoss $isWin={round.resultAmount > 0} $isZero={round.resultAmount === 0}>
                      {round.resultAmount === 0 ? '' : round.resultAmount > 0 ? '+ ' : '- '}
                      {transformToCurrencyDecimal({
                        value:
                          round.resultAmount !== null ? roundToFixed(round.resultAmount, 2) : 0,
                        currency,
                      }).replace(/-/g, '')}
                    </WinLoss>
                  </ItemValue>
                )}
              </Box>

              {deviceType !== Device.Mobile && jackpotOn && (
                <Box $width={7}>
                  <ItemValue>
                    {round.jackpot ? (
                      <Icon name={IconNames.Checked} />
                    ) : (
                      <Icon name={IconNames.Unchecked} />
                    )}{' '}
                  </ItemValue>
                </Box>
              )}

              <Box $width={3}>
                <ItemValue>
                  <ArrowWrapper $isRotate={openedItem === round.roundId}>
                    <Icon name={IconNames.ArrowDown} />
                  </ArrowWrapper>
                </ItemValue>
              </Box>
            </ListItem>
            {openedItem === round.roundId && (
              <ListItem $isGray={!(index % 2)} $disable $deviceType={deviceType}>
                <Box $width={14}>
                  <ItemValue>{t('history.betType')}: </ItemValue>
                </Box>
                <Box $width={86}>
                  {round.details.map(({ type, betAmount, resultAmount, inJackpot }: any) => (
                    <Slots2 key={type}>
                      <Box $width={deviceType === Device.Mobile ? 30 : jackpotOn ? 18.5 : 23.5}>
                        <ItemValue>
                          {type === 'JACKPOT' ? (
                            'Jackpot'
                          ) : (
                            <SlotValue slot={type}>
                              {' '}
                              {t(`ball.${type.toLowerCase()}`).toLowerCase()}
                            </SlotValue>
                          )}
                        </ItemValue>
                      </Box>
                      <Box
                        $width={
                          deviceType === Device.Mobile
                            ? jackpotOn
                              ? 32
                              : 30
                            : jackpotOn
                              ? 33
                              : 34.5
                        }>
                        {inJackpot ? (
                          <ItemValue>
                            <ItemGradient>
                              {transformToCurrencyDecimal({
                                value: betAmount !== null ? roundToFixed(betAmount, 2) : 0,
                                currency,
                              }).replace(/-/g, '')}
                            </ItemGradient>
                          </ItemValue>
                        ) : (
                          <ItemValue>
                            {betAmount === 0
                              ? '-'
                              : transformToCurrencyDecimal({
                                  value: betAmount !== null ? roundToFixed(betAmount, 2) : 0,
                                  currency,
                                }).replace(/-/g, '')}
                          </ItemValue>
                        )}
                      </Box>
                      <Box $width={36}>
                        {type === 'JACKPOT' && resultAmount === 0 ? (
                          <ItemValue>-</ItemValue>
                        ) : resultAmount === 0 && round.roundStatus === 'CANCELED' ? (
                          <ItemValue>-</ItemValue>
                        ) : (
                          <ItemValue>
                            <WinLoss $isWin={resultAmount > 0} $isZero={resultAmount === 0}>
                              {round.resultAmount === 0 ? '' : resultAmount > 0 ? '+ ' : '- '}
                              {transformToCurrencyDecimal({
                                value: resultAmount !== null ? roundToFixed(resultAmount, 2) : 0,
                                currency,
                              }).replace(/-/g, '')}
                            </WinLoss>
                          </ItemValue>
                        )}
                      </Box>
                    </Slots2>
                  ))}
                </Box>
              </ListItem>
            )}
          </Row>
        ))}
      </Wrapper>
    </>
  );
};
