import { ResultDice } from 'components/Dice/types';
import { DICE_SLOTS } from 'types';

const isTriple = (array: string[]): boolean => {
  return array.length === 3 && array[0] === array[1] && array[1] === array[2];
};

export const getWinnerSlots = (winners: ResultDice[]) => {
  const isTripleSlot = isTriple(winners);

  return isTripleSlot
    ? [DICE_SLOTS.triple, DICE_SLOTS[winners[0].toLowerCase() as unknown as DICE_SLOTS]]
    : winners.map((winnerDice) => DICE_SLOTS[winnerDice.toLowerCase() as unknown as DICE_SLOTS]);
};
