import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { GameType } from 'constants/common';
import { IState } from './reduxTypes';
import { createSelector } from '@reduxjs/toolkit';

interface IGameTypeState {
  gameType: GameType | null;
}

enum ACTIONS {
  SET_GAME_TYPE = 'SET_GAME_TYPE',
}

export const setGameType = createAction(ACTIONS.SET_GAME_TYPE)<{ gameType: GameType }>();

const actions = {
  setGameType,
};

const gameTypeState: IGameTypeState = {
  gameType: null,
};

export const gameTypeReducer = createReducer<IGameTypeState, ActionType<typeof actions>>(
  gameTypeState,
).handleAction(setGameType, (state, { payload: { gameType } }) => ({
  ...state,
  gameType,
}));

export const gameTypeSelector = createSelector(
  (state: IState): IGameTypeState => state.diceReducer.gameTypeReducer,
  (state: IGameTypeState) => state.gameType,
);
