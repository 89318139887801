import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { transformToCurrency } from 'utils/transformToCurrency';
import transformToShortNumber from 'utils/transformToShortNumber';
import { tableInfoSelector } from 'core/widgets/TableSettings';
import { totalBetSelector } from 'core/widgets/User';
import { TableInfoResponsive } from './Responsive';
import { ITableInfoItem } from './types';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { SettingPanels } from 'widgets/Settings/types';
import { GameType } from 'constants/common';
import { gameTypeSelector } from 'core/gameTypeReducer';

export const TableInfo = () => {
  const totalBet = useSelector(totalBetSelector);
  const tableInfo = useSelector(tableInfoSelector);
  const { currency } = useSelector(authInfoSelector);
  const gameType = useSelector(gameTypeSelector);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const betLimitsShort: string = useMemo(() => {
    if (tableInfo?.betLimit === undefined) {
      return '';
    }

    const { min: minBetLimit, max: maxBetLimit } = tableInfo.betLimit;
    if (!minBetLimit || !maxBetLimit) {
      return '';
    }
    const transformedMinBetLimit = transformToShortNumber(minBetLimit);
    const transformedMaxBetLimit = transformToShortNumber(maxBetLimit);

    return `${transformedMinBetLimit}-${transformedMaxBetLimit}`;
  }, [tableInfo?.betLimit]);

  const betLimitsDicesShort: string = useMemo(() => {
    // @TODO HHH
    if (tableInfo?.dicesLimits?.totalMinBet === undefined) {
      return '';
    }

    const { totalMinBet: minBetLimit, totalMaxBet: maxBetLimit } = tableInfo.dicesLimits;
    const transformedMinBetLimit = transformToShortNumber(minBetLimit);
    const transformedMaxBetLimit = transformToShortNumber(maxBetLimit);

    return `${transformedMinBetLimit}-${transformedMaxBetLimit}`;
  }, [tableInfo?.dicesLimits]);

  const onCloseLimits = useCallback(() => {
    dispatch(
      manageSettingsPanelAction({
        settingsPanel: SettingPanels.BetLimits,
      }),
    );
  }, [dispatch]);

  if (tableInfo === null) {
    return null;
  }

  const betsInfo: ITableInfoItem[] = [
    {
      label: t('table.totalBetLabel'),
      value: transformToCurrency({ value: totalBet || 0, currency }),
    },
    {
      label: t('table.betLimitLabel'),
      value: `${currency} ${gameType === GameType.HHH ? betLimitsDicesShort : betLimitsShort}`, // @TODO HHH
    },
  ];

  const gameInfo: ITableInfoItem[] = [
    { label: t('table.gameIdLabel'), value: tableInfo?.gameId || '-' },
    { label: t('table.tableIdLabel'), value: tableInfo?.tableId || '-' },
    { label: t('table.dealerLabel'), value: tableInfo?.dealer?.nickname || '-' },
  ];

  return (
    <TableInfoResponsive
      betsInfo={betsInfo}
      gameInfo={gameInfo}
      onCloseLimits={onCloseLimits}
      gameType={gameType || GameType.BAC}
    />
  );
};
