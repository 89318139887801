import { DICE_SLOTS, SLOTS } from 'types';
import { preciseAddSubtract, roundToFixed } from 'utils/roundToFix';
import { BetType, DiceBetType } from '../types';

export const getSummaryBet = (bets: BetType[]): BetType =>
  Object.values(bets).reduce(
    (summaryBet, bet) => ({
      [SLOTS.player]: preciseAddSubtract(summaryBet[SLOTS.player], bet[SLOTS.player], 'add'),
      [SLOTS.tie]: preciseAddSubtract(summaryBet[SLOTS.tie], bet[SLOTS.tie], 'add'),
      [SLOTS.banker]: preciseAddSubtract(summaryBet[SLOTS.banker], bet[SLOTS.banker], 'add'),
    }),
    {
      [SLOTS.player]: 0,
      [SLOTS.tie]: 0,
      [SLOTS.banker]: 0,
    },
  );

export const getSummaryDiceBet = (bets: DiceBetType[]): DiceBetType =>
  Object.values(bets).reduce(
    (summaryBet, bet) => ({
      [DICE_SLOTS.red]: preciseAddSubtract(summaryBet[DICE_SLOTS.red], bet[DICE_SLOTS.red], 'add'),
      [DICE_SLOTS.orange]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.orange],
        bet[DICE_SLOTS.orange],
        'add',
      ),
      [DICE_SLOTS.yellow]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.yellow],
        bet[DICE_SLOTS.yellow],
        'add',
      ),
      [DICE_SLOTS.green]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.green],
        bet[DICE_SLOTS.green],
        'add',
      ),
      [DICE_SLOTS.blue]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.blue],
        bet[DICE_SLOTS.blue],
        'add',
      ),
      [DICE_SLOTS.purple]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.purple],
        bet[DICE_SLOTS.purple],
        'add',
      ),
      [DICE_SLOTS.triple]: preciseAddSubtract(
        summaryBet[DICE_SLOTS.triple],
        bet[DICE_SLOTS.triple],
        'add',
      ),
    }),
    {
      [DICE_SLOTS.red]: 0,
      [DICE_SLOTS.orange]: 0,
      [DICE_SLOTS.yellow]: 0,
      [DICE_SLOTS.green]: 0,
      [DICE_SLOTS.blue]: 0,
      [DICE_SLOTS.purple]: 0,
      [DICE_SLOTS.triple]: 0,
    },
  );
