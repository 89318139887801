import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconNames } from 'components';
import { GameType } from 'constants/common';

import { ITableInfoProps } from './types';
import {
  LandscapeWrapper,
  ContainerLeft,
  ItemInfoLandscape,
  ItemInfoLabel,
  ItemInfoValue,
  ContainerRight,
  IconStyled,
} from './styles';

export const TableInfoLandscape = ({
  betsInfo,
  gameInfo,
  gameType,
  onCloseLimits,
}: ITableInfoProps) => {
  const { i18n } = useTranslation();
  return (
    <LandscapeWrapper>
      <ContainerLeft>
        {betsInfo.map(({ label, value }, i) => (
          <>
            <ItemInfoLandscape key={i} $isBigContent={i18n.language === 'tl'}>
              <ItemInfoLabel>{label}: </ItemInfoLabel>
              <ItemInfoValue>{value}</ItemInfoValue>
            </ItemInfoLandscape>
            {gameType === GameType.HHH && i === 1 && (
              <IconStyled name={IconNames.Info} onClick={onCloseLimits} />
            )}
          </>
        ))}
      </ContainerLeft>
      <ContainerRight>
        {gameInfo.map(({ label, value }, i) => (
          <ItemInfoLandscape key={i} $isBigContent={i18n.language === 'tl'}>
            <ItemInfoLabel>{label}: </ItemInfoLabel>
            <ItemInfoValue>{value}</ItemInfoValue>
          </ItemInfoLandscape>
        ))}
      </ContainerRight>
    </LandscapeWrapper>
  );
};
