import React from 'react';

import { WinnerDices } from '../types';
import { diceColors } from '../data';

export const LeftDiceBase = ({ color }: { color: WinnerDices }) => (
  <g filter="url(#filter0_d_688_63009)">
    <mask
      id="mask0_688_63009"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="49"
      y="8"
      width="39"
      height="40">
      <rect x="49" y="8" width="38.2792" height="40" fill="url(#pattern0_688_63009)" />
    </mask>
    <g mask="url(#mask0_688_63009)">
      <rect x="49" y="8" width="38.2792" height="40" fill="url(#pattern1_688_63009)" />
      <rect
        x="49"
        y="8"
        width="38.2792"
        height="40"
        fill={diceColors[color]}
        style={{ mixBlendMode: 'overlay' }}
      />
      <rect x="49" y="8" width="38.2792" height="40" fill={diceColors[color]} fill-opacity="0.2" />
    </g>
  </g>
);
