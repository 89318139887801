import { ResultDice } from 'components/Dice/types';
import { IWinnerDice } from 'constants/common';

export const getDiceColorsStatistics = (dices: IWinnerDice[]) => {
  const colorArrays = dices;
  const initialColorsCount: any = {
    [ResultDice.RED]: 0,
    [ResultDice.ORANGE]: 0,
    [ResultDice.YELLOW]: 0,
    [ResultDice.GREEN]: 0,
    [ResultDice.BLUE]: 0,
    [ResultDice.PURPLE]: 0,
  };

  if (colorArrays.length === 0) {
    return {
      colorPercentages: {
        [ResultDice.RED]: '0,0 %',
        [ResultDice.ORANGE]: '0,0 %',
        [ResultDice.YELLOW]: '0,0 %',
        [ResultDice.GREEN]: '0,0 %',
        [ResultDice.BLUE]: '0,0 %',
        [ResultDice.PURPLE]: '0,0 %',
      },
      triplePercentage: '0,0 %',
    };
  }

  const TOTAL_ARRAY_LENGTH = colorArrays.length;
  const TOTAL_COLORS = colorArrays.length * 3;

  const tripleCount = colorArrays.filter((dicesResultRound) => dicesResultRound.isTriple).length;

  const colorsCount = colorArrays.reduce(
    (counts, dicesResultRound) => {
      [dicesResultRound.firstDice, dicesResultRound.secondDice, dicesResultRound.thirdDice].forEach(
        (color) => {
          if (Object.prototype.hasOwnProperty.call(counts, color)) {
            counts[color]++;
          }
        },
      );
      return counts;
    },
    { ...initialColorsCount },
  );

  const colorPercentages = Object.entries(colorsCount).reduce(
    (percentages, [color, count]: any) => {
      return { ...percentages, [color]: ((count / TOTAL_COLORS) * 100).toFixed(1) + '%' };
    },
    {},
  );

  const triplePercentage = ((tripleCount / TOTAL_ARRAY_LENGTH) * 100).toFixed(1) + '%';

  return {
    colorPercentages,
    triplePercentage,
  };
};
