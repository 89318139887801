import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMIN20 } from './chipsValues';

export const CHIPS_MIN_20: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 20,
  },
  ...chipsMIN20,
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 10e3,
  },
];

export const CHIPS_MIN_20_v2: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 20,
  },
  {
    color: CHIPS_COLORS.YELLOW,
    value: 50,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 100,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 500,
  },
  {
    color: CHIPS_COLORS.GREEN,
    value: 1e3,
  },
  {
    color: CHIPS_COLORS.ORANGE,
    value: 5e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_BLUE,
    value: 10e3,
  },
  {
    color: CHIPS_COLORS.SCARLET,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.PURPLE,
    value: 100e3,
  },
  {
    color: CHIPS_COLORS.LIGHT_GREEN,
    value: 200e3,
  },
];
