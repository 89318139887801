import React from 'react';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { BaseDiceWinStatus } from './DiceWinStatus.base';
import { IDiceWinStatusProps } from './types';

export const DiceWinStatusResponsive = (props: IDiceWinStatusProps) => (
  <>
    <DesktopComponent>
      <BaseDiceWinStatus {...props} deviceType={Device.Desktop} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BaseDiceWinStatus {...props} deviceType={Device.Tablet_Landscape} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BaseDiceWinStatus {...props} deviceType={Device.Tablet_Portrait} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BaseDiceWinStatus {...props} deviceType={Device.Mobile} />
    </MobileComponent>
  </>
);
