import { ActionType } from 'typesafe-actions';
import { put, select } from 'redux-saga/effects';

import { preciseAddSubtract } from 'utils/roundToFix';
import { BetTypeDice } from '../types';
import { placeDiceBetAction, placeDiceBetSuccessAction } from '../actions';
import { placedDiceBetSelector } from '../selectors';
import { IDiceLimits } from 'constants/common';
import { tableDiceBetLimitSelector } from 'core/widgets/TableSettings';
import { balanceSelector } from 'core/widgets/User';
import { getBetSum } from 'core/widgets/Bet/utils';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { addToastAction } from 'core/widgets/Toast/actions';
import { betMessages } from 'constants/toastMessages';
import { ToastTypes } from 'types/toast';
import { DICE_SLOTS } from 'types';

// const test = {
//   colorMinBet: 0.5,
//   colorMaxBet: 10000,

//   tripleMinBet: 1,
//   tripleMaxBet: 1000,

//   totalMinBet: 0.5,
//   totalMaxBet: 10000,
// };

export function* placeDiceBetSaga({ payload: newBet }: ActionType<typeof placeDiceBetAction>) {
  const balance: number | null = yield select(balanceSelector);
  const placedBetResult: BetTypeDice = yield select(placedDiceBetSelector);
  const tableLimits: IDiceLimits = yield select(tableDiceBetLimitSelector);

  if (!balance || !tableLimits) {
    return;
  }

  const placedBet = placedBetResult;

  const placedBetSumBeforeNewBet: number = getDiceBetSum([placedBetResult]);
  const bet = newBet.value > balance ? newBet.value : balance;

  if (tableLimits.totalMinBet > bet && placedBet[newBet.slot] <= tableLimits.totalMinBet) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));

    return;
  }

  const resultBet: BetTypeDice = {
    ...placedBetResult,
    [newBet.slot]: preciseAddSubtract(newBet.value, placedBetResult[newBet.slot], 'add'),
  };

  if (placedBetSumBeforeNewBet >= tableLimits.totalMaxBet) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    return;
  }

  const availableRestBalance: number = preciseAddSubtract(
    balance,
    placedBetSumBeforeNewBet,
    'subtract',
  );

  if (!availableRestBalance) {
    yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
    return;
  }

  if (newBet.slot === DICE_SLOTS.triple) {
    const currentTripleBet = placedBet[newBet.slot];

    if (currentTripleBet >= tableLimits.tripleMaxBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      return;
    }

    const remainTotalBets =
      tableLimits.totalMaxBet - placedBetSumBeforeNewBet < tableLimits.tripleMaxBet
        ? tableLimits.totalMaxBet - placedBetSumBeforeNewBet
        : tableLimits.tripleMaxBet;

    if (currentTripleBet === 0) {
      if (balance < tableLimits.tripleMinBet || availableRestBalance < tableLimits.tripleMinBet) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
        return;
      }

      if (remainTotalBets < tableLimits.tripleMinBet) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
        return;
      }

      if (newBet.value < tableLimits.tripleMinBet) {
        yield put(addToastAction({ type: ToastTypes.DEFAULT, value: betMessages.MIN_BET_REACHED }));
        const resultBet: BetTypeDice = {
          ...placedBet,
          [newBet.slot]: preciseAddSubtract(
            tableLimits.tripleMinBet,
            placedBet[newBet.slot],
            'add',
          ),
        };

        yield put(placeDiceBetSuccessAction(resultBet));
        return;
      }
    }

    const remainTripleBet = preciseAddSubtract(
      tableLimits.tripleMaxBet,
      placedBet[newBet.slot],
      'subtract',
    );

    const newAvailableBet = Math.min(remainTotalBets, remainTripleBet, newBet.value);

    const newAvailableBetCheckingBalance = newAvailableBet > balance ? balance : newAvailableBet;
    const newBetCheckingBalance =
      newBet.value > availableRestBalance ? availableRestBalance : newBet.value;

    const availableBetTriple =
      newBetCheckingBalance > newAvailableBetCheckingBalance
        ? newAvailableBetCheckingBalance
        : newBetCheckingBalance;

    const newTripleBet = preciseAddSubtract(availableBetTriple, currentTripleBet, 'add');

    if (currentTripleBet < tableLimits.tripleMaxBet) {
      const resultBet: BetTypeDice = {
        ...placedBet,
        [newBet.slot]: newTripleBet,
      };

      if (
        newAvailableBet < balance &&
        newAvailableBet < availableRestBalance &&
        (newBet.value > remainTripleBet || newBet.value > remainTotalBets)
      ) {
        yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      }

      yield put(placeDiceBetSuccessAction(resultBet));
      return;
    }
  }

  const newPlacedBetSum: number = preciseAddSubtract(placedBetSumBeforeNewBet, newBet.value, 'add');

  if (newPlacedBetSum > tableLimits.totalMaxBet) {
    const totalMaxBet = tableLimits.totalMaxBet > balance ? balance : tableLimits.totalMaxBet;
    const availableSumBet = preciseAddSubtract(totalMaxBet, placedBetSumBeforeNewBet, 'subtract');

    const currentBet = newBet.value > availableSumBet ? availableSumBet : newBet.value;
    const currentBetColor =
      currentBet > tableLimits?.colorMaxBet
        ? preciseAddSubtract(tableLimits?.colorMaxBet, placedBet[newBet.slot], 'subtract')
        : currentBet;

    const resultBet: BetTypeDice = {
      ...placedBet,
      [newBet.slot]: preciseAddSubtract(currentBetColor, placedBet[newBet.slot], 'add'),
    };

    if (newBet.value <= balance && newBet.value <= availableRestBalance) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
    }

    yield put(placeDiceBetSuccessAction(resultBet));
    return;
  }

  if (newBet.value > availableRestBalance) {
    if (!availableRestBalance && balance < tableLimits.totalMaxBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    if (availableRestBalance < tableLimits.totalMinBet && placedBet[newBet.slot] === 0) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    const resultBet: BetTypeDice = {
      ...placedBet,
      [newBet.slot]: preciseAddSubtract(availableRestBalance, placedBet[newBet.slot], 'add'),
    };

    yield put(placeDiceBetSuccessAction(resultBet));
    return;
  }

  yield put(placeDiceBetSuccessAction(resultBet));
}
