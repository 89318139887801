import { betMessages } from 'constants/toastMessages';
import { clearConfirmationSlotAction } from 'core/widgets/BetConfirmation/actions';
import { confirmDoubleBetAction, errorDoubleBetAction } from 'core/widgets/BetDouble/actions';
import { confirmRepeatBetAction, errorRepeatBetAction } from 'core/widgets/BetRepeat/actions';
import { addToastAction } from 'core/widgets/Toast/actions';
import { updateBalanceAction, updateTotalBetAction } from 'core/widgets/User/actions';
import { call, put, select } from 'redux-saga/effects';
import { ConfirmBetResponseDataType, RestService } from 'services/RestAPIService';
import { ToastTypes } from 'types/toast';
import { confirmBetErrorAction, confirmBetSuccessAction } from '../actions';
import { confirmedBetSelector, placedBetSelector } from '../selectors';
import { BetType } from '../types';
import { getBetSum, getSummaryBet } from '../utils';
import {
  confirmDiceBetErrorAction,
  confirmDiceBetSuccessAction,
} from 'core/widgets/DiceBet/actions';

export function* confirmBetSaga() {
  const placedBet: BetType = yield select(placedBetSelector);
  const betSum = getBetSum([placedBet]);
  const betTimeStamp = new Date().getTime();

  const confirmedBets: BetType[] = yield select(confirmedBetSelector);
  const totalConfirmedBets: BetType = getSummaryBet(confirmedBets);
  const confirmedBetSum = getBetSum([totalConfirmedBets]);

  yield put(updateBalanceAction(-betSum));
  yield put(updateTotalBetAction(betSum));
  yield put(confirmBetSuccessAction(betTimeStamp));
  // @TODO HHH
  yield put(confirmDiceBetSuccessAction(betTimeStamp));

  yield put(clearConfirmationSlotAction());

  try {
    const response: ConfirmBetResponseDataType | null = yield call(() =>
      RestService.confirmBet(placedBet),
    );

    if (response !== null) {
      yield put(addToastAction({ type: ToastTypes.SUCCESS, value: betMessages.ACCEPTED }));
      yield put(confirmDoubleBetAction());
      yield put(confirmRepeatBetAction());
    }
  } catch {
    if (!confirmedBetSum) {
      yield put(updateBalanceAction(betSum));
      yield put(updateTotalBetAction(-betSum));
      yield put(confirmBetErrorAction(betTimeStamp));
      // @TODO HHH
      yield put(confirmDiceBetErrorAction(betTimeStamp));
      yield put(errorDoubleBetAction());
      yield put(errorRepeatBetAction());
    }
  }
}
