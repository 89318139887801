import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { transformToCurrencyDecimal } from 'utils/transformToCurrency';
import { roundToFixed } from 'utils/roundToFix';
import { WinnerBall } from 'constants/common';
import { Icon, IconNames } from 'components/Icon';
import { historySelector } from 'core/widgets/History';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import {
  ListItem,
  Wrapper,
  ItemValue,
  Box,
  ItemType,
  SlotValue,
  Slots,
  SlotsWrapper,
  WinLoss,
  Slots2,
  Row,
  ItemGradient,
  ArrowWrapper,
} from './stylesTable';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { Device } from 'types';
import { EmptyHistory } from './styles';
import AudioService from 'services/AudioService';

export const HistoryTable = ({ deviceType }: { deviceType: Device }) => {
  const history = useSelector(historySelector);
  const { jackpotOn } = useSelector(authInfoSelector);

  const { t } = useTranslation();

  const { currency } = useSelector(authInfoSelector);

  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const [openedItem, setOpenedItem] = useState<string | null>(null);

  const toggleOpenedItem = (roundId: string) => () => {
    setOpenedItem(roundId === openedItem ? null : roundId);
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
  };
  return (
    <>
      <Wrapper $deviceType={deviceType}>
        {history?.length === 0 && <EmptyHistory>{t('history.noBettingHistory')}</EmptyHistory>}
        {history?.map((round: any, index: number) => (
          <Row key={`${round?.roundId}`}>
            <ListItem
              onClick={toggleOpenedItem(round?.roundId)}
              key={`${round?.roundId}`}
              $isGray={!(index % 2)}
              $disable={false}>
              <Box $width={22}>
                <ItemValue>
                  <ItemType>{t('history.id')}:&nbsp;</ItemType> {round.roundId}
                </ItemValue>
                <ItemValue>
                  <ItemType>{t('history.date')}:&nbsp;</ItemType>
                  {moment(new Date(round.roundDate)).format('DD.MM.YYYY HH:mm')}
                </ItemValue>
                <ItemValue>
                  <ItemType>{t('history.roundStatus')}:&nbsp;</ItemType>
                  {t(`history.${round.roundStatus}`)}
                </ItemValue>
              </Box>
              <Box $width={jackpotOn ? 19 : 20}>
                <ItemValue>
                  {transformToCurrencyDecimal({
                    value: round.balance !== null ? roundToFixed(round.balance, 2) : 0,
                    currency,
                  }).replace(/-/g, '')}
                </ItemValue>
              </Box>
              <Box $width={12}>
                <SlotsWrapper>
                  {round.slots?.length
                    ? round.slots.map((slot: string, slotIndex: number) => (
                        <Slots key={slot}>
                          <SlotValue slot={slot}>
                            {t(`ball.${slot.toLowerCase()}`).toLowerCase()}
                          </SlotValue>
                          {slotIndex + 1 !== round.slots?.length && <>,&nbsp;</>}
                        </Slots>
                      ))
                    : '-'}
                </SlotsWrapper>
              </Box>
              <Box $width={jackpotOn ? 19 : 21}>
                <ItemValue>
                  {round.betAmount === 0
                    ? '-'
                    : transformToCurrencyDecimal({
                        value: round.betAmount !== null ? roundToFixed(round.betAmount, 2) : 0,
                        currency,
                      }).replace(/-/g, '')}
                </ItemValue>
              </Box>
              <Box $width={jackpotOn ? 21 : 23}>
                {round.resultAmount === 0 && round.roundStatus === 'CANCELED' ? (
                  <ItemValue>-</ItemValue>
                ) : (
                  <ItemValue>
                    <WinLoss $isWin={round.resultAmount > 0} $isZero={round.resultAmount === 0}>
                      {round.resultAmount === 0 ? '' : round.resultAmount > 0 ? '+ ' : '- '}
                      {transformToCurrencyDecimal({
                        currency,
                        value:
                          round.resultAmount !== null ? roundToFixed(round.resultAmount, 2) : 0,
                      }).replace(/-/g, '')}
                    </WinLoss>
                  </ItemValue>
                )}
              </Box>
              {jackpotOn && (
                <Box $width={5}>
                  {round.jackpot ? (
                    <Icon name={IconNames.Checked} />
                  ) : (
                    <Icon name={IconNames.Unchecked} />
                  )}
                </Box>
              )}
              <Box $width={2}>
                <ArrowWrapper $isRotate={openedItem === round.roundId}>
                  <Icon name={IconNames.ArrowDown} />
                </ArrowWrapper>
              </Box>
            </ListItem>
            {openedItem === round.roundId && (
              <ListItem $isGray={!(index % 2)} $disable>
                <Box $width={jackpotOn ? 41.1 : 42} />
                <Box $width={jackpotOn ? 58.9 : 58}>
                  {round.details.map(({ type, betAmount, resultAmount, inJackpot }: any) => (
                    <Slots2 key={type}>
                      <Box $width={20}>
                        <ItemValue>
                          {type === 'JACKPOT' ? (
                            t('history.jackpot')
                          ) : (
                            <SlotValue slot={type}>
                              {t(`ball.${type.toLowerCase()}`).toLowerCase()}
                            </SlotValue>
                          )}
                        </ItemValue>
                      </Box>
                      <Box $width={jackpotOn ? 32.5 : 36.5}>
                        {inJackpot ? (
                          <ItemValue>
                            <ItemGradient>
                              {transformToCurrencyDecimal({
                                currency,
                                value: betAmount !== null ? roundToFixed(betAmount, 2) : 0,
                              }).replace(/-/g, '')}
                            </ItemGradient>
                          </ItemValue>
                        ) : (
                          <ItemValue>
                            {betAmount === 0
                              ? '-'
                              : transformToCurrencyDecimal({
                                  currency,
                                  value: betAmount !== null ? roundToFixed(betAmount, 2) : 0,
                                }).replace(/-/g, '')}
                          </ItemValue>
                        )}
                      </Box>
                      <Box $width={31}>
                        {type === 'JACKPOT' && resultAmount === 0 ? (
                          <ItemValue>-</ItemValue>
                        ) : resultAmount === 0 && round.roundStatus === 'CANCELED' ? (
                          <ItemValue>-</ItemValue>
                        ) : (
                          <ItemValue>
                            <WinLoss $isWin={resultAmount > 0} $isZero={resultAmount === 0}>
                              {resultAmount === 0 ? '' : resultAmount > 0 ? '+ ' : '- '}
                              {transformToCurrencyDecimal({
                                currency,
                                value: resultAmount !== null ? roundToFixed(resultAmount, 2) : 0,
                              }).replace(/-/g, '')}
                            </WinLoss>
                          </ItemValue>
                        )}
                      </Box>
                    </Slots2>
                  ))}
                </Box>
              </ListItem>
            )}
          </Row>
        ))}
      </Wrapper>
    </>
  );
};
