import { ResultDice } from 'components/Dice/types';
import { SLOTS } from 'types';

export enum WinnerBall {
  BLUE = 'BLUE',
  GOLD = 'GOLD',
  RED = 'RED',
}

type WinningAmountType = { [key in SLOTS]: number };

export const WINNING_AMOUNT: WinningAmountType = {
  [SLOTS.banker]: 1.1,
  [SLOTS.player]: 1.1,
  [SLOTS.tie]: 10,
};

export enum GameType {
  HHH = 'HHH',
  BAC = 'BAC',
}

export interface IWinnerDice {
  firstDice: ResultDice;
  secondDice: ResultDice;
  thirdDice: ResultDice;
  isTriple: boolean;
}

export interface IDiceLimits {
  colorMinBet: number;
  colorMaxBet: number;
  tripleMinBet: number;
  tripleMaxBet: number;
  totalMinBet: number;
  totalMaxBet: number;
}
