import React from 'react';

export const getValueChip = (value?: number) => {
  if (!value) {
    return null;
  }
  if (value === 0.2) {
    return get02();
  }
  if (value === 0.25) {
    return get025();
  }
  if (value === 0.5) {
    return get05();
  }
  if (value === 1) {
    return get1();
  }
  if (value === 2) {
    return get2();
  }

  if (value === 2.5) {
    return get2_5();
  }

  if (value === 5) {
    return get5();
  }
  if (value === 10) {
    return get10();
  }
  if (value === 20) {
    return get20();
  }
  if (value === 25) {
    return get25();
  }
  if (value === 50) {
    return get50();
  }
  if (value === 100) {
    return get100();
  }
  if (value === 200) {
    return get200();
  }

  if (value === 250) {
    return get250();
  }
  if (value === 300) {
    return get300();
  }
  if (value === 500) {
    return get500();
  }
  if (value === 1e3) {
    return get1k();
  }
  if (value === 2e3) {
    return get2k();
  }

  if (value === 2.5e3) {
    return get2_5k();
  }

  if (value === 5e3) {
    return get5k();
  }
  if (value === 10e3) {
    return get10k();
  }
  if (value === 20e3) {
    return get20k();
  }
  if (value === 50e3) {
    return get50k();
  }
  if (value === 100e3) {
    return get100k();
  }
  if (value === 200e3) {
    return get200k();
  }
  if (value === 500e3) {
    return get500k();
  }
  if (value === 1e6) {
    return get1m();
  }
  if (value === 2e6) {
    return get2m();
  }
  if (value === 5e6) {
    return get5m();
  }
  if (value === 10e6) {
    return get10m();
  }
  if (value === 50e6) {
    return get50m();
  }
  if (value === 100e6) {
    return get100m();
  }
};

export const get02 = () => (
  <>
    <path
      d="M33.8214 54.0647C44.7685 54.0647 53.6429 45.1904 53.6429 34.2433C53.6429 23.2962 44.7685 14.4219 33.8214 14.4219C22.8744 14.4219 14 23.2962 14 34.2433C14 45.1904 22.8744 54.0647 33.8214 54.0647Z"
      fill="#FFF7EB"
    />
    <path
      d="M44.1694 32.2801C44.1694 33.0734 43.8334 33.7594 43.1614 34.3381C42.956 34.5154 42.732 34.6927 42.4894 34.8701L39.4794 37.0401H44.1694V39.0001H36.6094V36.3401L41.6494 32.7001V32.0001C41.6494 31.4401 41.3694 31.1601 40.8094 31.1601H36.8894V29.2001H41.3694C42.3494 29.2001 43.0587 29.4241 43.4974 29.8721C43.9454 30.3107 44.1694 31.0201 44.1694 32.0001V32.2801Z"
      fill="#343434"
    />
    <path
      d="M34.2059 39.0001C33.8606 39.0001 33.6086 38.9301 33.4499 38.7901C33.2912 38.6501 33.2119 38.4167 33.2119 38.0901C33.2119 37.7541 33.2866 37.5207 33.4359 37.3901C33.5946 37.2501 33.8512 37.1801 34.2059 37.1801C34.5606 37.1801 34.8172 37.2501 34.9759 37.3901C35.1346 37.5207 35.2139 37.7541 35.2139 38.0901C35.2139 38.4074 35.1346 38.6407 34.9759 38.7901C34.8172 38.9301 34.5606 39.0001 34.2059 39.0001Z"
      fill="#343434"
    />
    <path
      d="M29.7127 31.8601C29.7127 31.3001 29.4327 31.0201 28.8727 31.0201H27.3327C26.7727 31.0201 26.4927 31.3001 26.4927 31.8601V36.3401C26.4927 36.9001 26.7727 37.1801 27.3327 37.1801H28.8727C29.4327 37.1801 29.7127 36.9001 29.7127 36.3401V31.8601ZM32.2327 36.3401C32.2327 37.3201 32.0087 38.0341 31.5607 38.4821C31.122 38.9207 30.4127 39.1401 29.4327 39.1401H26.7727C25.7927 39.1401 25.0787 38.9207 24.6307 38.4821C24.192 38.0341 23.9727 37.3201 23.9727 36.3401V31.8601C23.9727 30.8801 24.192 30.1707 24.6307 29.7321C25.0787 29.2841 25.7927 29.0601 26.7727 29.0601H29.4327C30.4127 29.0601 31.122 29.2841 31.5607 29.7321C32.0087 30.1707 32.2327 30.8801 32.2327 31.8601V36.3401Z"
      fill="#343434"
    />
  </>
);

export const get025 = () => (
  <>
    <path
      d="M45.8499 39.1401C44.2913 39.1401 42.5646 39.0467 40.6699 38.8601V36.9701C42.7326 37.1101 44.2726 37.1801 45.2899 37.1801C45.5046 37.1801 45.6726 37.1194 45.7939 36.9981C45.9246 36.8674 45.9899 36.6947 45.9899 36.4801V35.7801C45.9899 35.2201 45.7099 34.9401 45.1499 34.9401H41.0899V29.2001H47.8099V31.1601H43.6099V32.9801H45.8499C46.7646 32.9801 47.4366 33.1901 47.8659 33.6101C48.2953 34.0207 48.5099 34.6507 48.5099 35.5001V36.4801C48.5099 37.3947 48.2953 38.0667 47.8659 38.4961C47.4366 38.9254 46.7646 39.1401 45.8499 39.1401Z"
      fill="#343434"
    />
    <path
      d="M39.9721 32.2801C39.9721 33.0734 39.6361 33.7594 38.9641 34.3381C38.7588 34.5154 38.5348 34.6927 38.2921 34.8701L35.2821 37.0401H39.9721V39.0001H32.4121V36.3401L37.4521 32.7001V32.0001C37.4521 31.4401 37.1721 31.1601 36.6121 31.1601H32.6921V29.2001H37.1721C38.1521 29.2001 38.8614 29.4241 39.3001 29.8721C39.7481 30.3107 39.9721 31.0201 39.9721 32.0001V32.2801Z"
      fill="#343434"
    />
    <path
      d="M30.0086 39.0001C29.6633 39.0001 29.4113 38.9301 29.2526 38.7901C29.094 38.6501 29.0146 38.4167 29.0146 38.0901C29.0146 37.7541 29.0893 37.5207 29.2386 37.3901C29.3973 37.2501 29.654 37.1801 30.0086 37.1801C30.3633 37.1801 30.62 37.2501 30.7786 37.3901C30.9373 37.5207 31.0166 37.7541 31.0166 38.0901C31.0166 38.4074 30.9373 38.6407 30.7786 38.7901C30.62 38.9301 30.3633 39.0001 30.0086 39.0001Z"
      fill="#343434"
    />
    <path
      d="M25.5154 31.8601C25.5154 31.3001 25.2354 31.0201 24.6754 31.0201H23.1354C22.5754 31.0201 22.2954 31.3001 22.2954 31.8601V36.3401C22.2954 36.9001 22.5754 37.1801 23.1354 37.1801H24.6754C25.2354 37.1801 25.5154 36.9001 25.5154 36.3401V31.8601ZM28.0354 36.3401C28.0354 37.3201 27.8114 38.0341 27.3634 38.4821C26.9247 38.9207 26.2154 39.1401 25.2354 39.1401H22.5754C21.5954 39.1401 20.8814 38.9207 20.4334 38.4821C19.9947 38.0341 19.7754 37.3201 19.7754 36.3401V31.8601C19.7754 30.8801 19.9947 30.1707 20.4334 29.7321C20.8814 29.2841 21.5954 29.0601 22.5754 29.0601H25.2354C26.2154 29.0601 26.9247 29.2841 27.3634 29.7321C27.8114 30.1707 28.0354 30.8801 28.0354 31.8601V36.3401Z"
      fill="#343434"
    />
  </>
);

export const get05 = () => (
  <>
    <path
      d="M41.721 39.1401C40.1623 39.1401 38.4357 39.0467 36.541 38.8601V36.9701C38.6037 37.1101 40.1437 37.1801 41.161 37.1801C41.3757 37.1801 41.5437 37.1194 41.665 36.9981C41.7957 36.8674 41.861 36.6947 41.861 36.4801V35.7801C41.861 35.2201 41.581 34.9401 41.021 34.9401H36.961V29.2001H43.681V31.1601H39.481V32.9801H41.721C42.6357 32.9801 43.3077 33.1901 43.737 33.6101C44.1663 34.0207 44.381 34.6507 44.381 35.5001V36.4801C44.381 37.3947 44.1663 38.0667 43.737 38.4961C43.3077 38.9254 42.6357 39.1401 41.721 39.1401Z"
      fill="#343434"
    />
    <path
      d="M34.1376 39.0001C33.7922 39.0001 33.5402 38.9301 33.3816 38.7901C33.2229 38.6501 33.1436 38.4167 33.1436 38.0901C33.1436 37.7541 33.2182 37.5207 33.3676 37.3901C33.5262 37.2501 33.7829 37.1801 34.1376 37.1801C34.4922 37.1801 34.7489 37.2501 34.9076 37.3901C35.0662 37.5207 35.1456 37.7541 35.1456 38.0901C35.1456 38.4074 35.0662 38.6407 34.9076 38.7901C34.7489 38.9301 34.4922 39.0001 34.1376 39.0001Z"
      fill="#343434"
    />
    <path
      d="M29.6443 31.8601C29.6443 31.3001 29.3643 31.0201 28.8043 31.0201H27.2643C26.7043 31.0201 26.4243 31.3001 26.4243 31.8601V36.3401C26.4243 36.9001 26.7043 37.1801 27.2643 37.1801H28.8043C29.3643 37.1801 29.6443 36.9001 29.6443 36.3401V31.8601ZM32.1643 36.3401C32.1643 37.3201 31.9403 38.0341 31.4923 38.4821C31.0536 38.9207 30.3443 39.1401 29.3643 39.1401H26.7043C25.7243 39.1401 25.0103 38.9207 24.5623 38.4821C24.1236 38.0341 23.9043 37.3201 23.9043 36.3401V31.8601C23.9043 30.8801 24.1236 30.1707 24.5623 29.7321C25.0103 29.2841 25.7243 29.0601 26.7043 29.0601H29.3643C30.3443 29.0601 31.0536 29.2841 31.4923 29.7321C31.9403 30.1707 32.1643 30.8801 32.1643 31.8601V36.3401Z"
      fill="#343434"
    />
  </>
);

export const get1 = () => (
  <>
    <path
      d="M33.4731 39V31.37L31.8631 31.93L31.1631 30.32L33.7531 29.2H35.9931V39H33.4731Z"
      fill="#343434"
    />
  </>
);

export const get2 = () => (
  <>
    <path
      d="M37.7114 32.28C37.7114 33.0733 37.3754 33.7593 36.7034 34.338C36.498 34.5153 36.274 34.6926 36.0314 34.87L33.0214 37.04H37.7114V39H30.1514V36.34L35.1914 32.7V32C35.1914 31.44 34.9114 31.16 34.3514 31.16H30.4314V29.2H34.9114C35.8914 29.2 36.6007 29.424 37.0394 29.872C37.4874 30.3106 37.7114 31.02 37.7114 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get2_5 = () => (
  <>
    <path
      d="M41.5111 39.14C39.9524 39.14 38.2257 39.0466 36.3311 38.86V36.97C38.3937 37.11 39.9337 37.18 40.9511 37.18C41.1657 37.18 41.3337 37.1193 41.4551 36.998C41.5857 36.8673 41.6511 36.6946 41.6511 36.48V35.78C41.6511 35.22 41.3711 34.94 40.8111 34.94H36.7511V29.2H43.4711V31.16H39.2711V32.98H41.5111C42.4257 32.98 43.0977 33.19 43.5271 33.61C43.9564 34.0206 44.1711 34.6506 44.1711 35.5V36.48C44.1711 37.3946 43.9564 38.0666 43.5271 38.496C43.0977 38.9253 42.4257 39.14 41.5111 39.14Z"
      fill="#343434"
    />
    <path
      d="M33.9266 38.9999C33.5813 38.9999 33.3293 38.9299 33.1706 38.7899C33.0119 38.6499 32.9326 38.4166 32.9326 38.0899C32.9326 37.7539 33.0073 37.5206 33.1566 37.3899C33.3153 37.2499 33.5719 37.1799 33.9266 37.1799C34.2813 37.1799 34.538 37.2499 34.6966 37.3899C34.8553 37.5206 34.9346 37.7539 34.9346 38.0899C34.9346 38.4073 34.8553 38.6406 34.6966 38.7899C34.538 38.9299 34.2813 38.9999 33.9266 38.9999Z"
      fill="#343434"
    />
    <path
      d="M31.395 32.28C31.395 33.0733 31.059 33.7593 30.387 34.338C30.1816 34.5153 29.9576 34.6926 29.715 34.87L26.705 37.04H31.395V39H23.835V36.34L28.875 32.7V32C28.875 31.44 28.595 31.16 28.035 31.16H24.115V29.2H28.595C29.575 29.2 30.2843 29.424 30.723 29.872C31.171 30.3106 31.395 31.02 31.395 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get5 = () => (
  <>
    <path
      d="M35.263 39.14C33.7043 39.14 31.9777 39.0466 30.083 38.86V36.97C32.1457 37.11 33.6857 37.18 34.703 37.18C34.9177 37.18 35.0857 37.1193 35.207 36.998C35.3377 36.8673 35.403 36.6946 35.403 36.48V35.78C35.403 35.22 35.123 34.94 34.563 34.94H30.503V29.2H37.223V31.16H33.023V32.98H35.263C36.1777 32.98 36.8497 33.19 37.279 33.61C37.7083 34.0206 37.923 34.6506 37.923 35.5V36.48C37.923 37.3946 37.7083 38.0666 37.279 38.496C36.8497 38.9253 36.1777 39.14 35.263 39.14Z"
      fill="#343434"
    />
  </>
);

export const get10 = () => (
  <>
    <path
      d="M38.447 31.86C38.447 31.3 38.167 31.02 37.607 31.02H36.067C35.507 31.02 35.227 31.3 35.227 31.86V36.34C35.227 36.9 35.507 37.18 36.067 37.18H37.607C38.167 37.18 38.447 36.9 38.447 36.34V31.86ZM40.967 36.34C40.967 37.32 40.743 38.034 40.295 38.482C39.8564 38.9207 39.147 39.14 38.167 39.14H35.507C34.527 39.14 33.813 38.9207 33.365 38.482C32.9264 38.034 32.707 37.32 32.707 36.34V31.86C32.707 30.88 32.9264 30.1707 33.365 29.732C33.813 29.284 34.527 29.06 35.507 29.06H38.167C39.147 29.06 39.8564 29.284 40.295 29.732C40.743 30.1707 40.967 30.88 40.967 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M28.7836 39V31.37L27.1736 31.93L26.4736 30.32L29.0636 29.2H31.3036V39H28.7836Z"
      fill="#343434"
    />
  </>
);

export const get20 = () => (
  <>
    <path
      d="M39.74 31.86C39.74 31.3 39.46 31.02 38.9 31.02H37.36C36.8 31.02 36.52 31.3 36.52 31.86V36.34C36.52 36.9 36.8 37.18 37.36 37.18H38.9C39.46 37.18 39.74 36.9 39.74 36.34V31.86ZM42.26 36.34C42.26 37.32 42.036 38.034 41.588 38.482C41.1493 38.9207 40.44 39.14 39.46 39.14H36.8C35.82 39.14 35.106 38.9207 34.658 38.482C34.2193 38.034 34 37.32 34 36.34V31.86C34 30.88 34.2193 30.1707 34.658 29.732C35.106 29.284 35.82 29.06 36.8 29.06H39.46C40.44 29.06 41.1493 29.284 41.588 29.732C42.036 30.1707 42.26 30.88 42.26 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M33.0219 32.28C33.0219 33.0733 32.6859 33.7593 32.0139 34.338C31.8086 34.5153 31.5846 34.6927 31.3419 34.87L28.3319 37.04H33.0219V39H25.4619V36.34L30.5019 32.7V32C30.5019 31.44 30.2219 31.16 29.6619 31.16H25.7419V29.2H30.2219C31.2019 29.2 31.9112 29.424 32.3499 29.872C32.7979 30.3107 33.0219 31.02 33.0219 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get25 = () => (
  <>
    <path
      d="M39.3919 39.14C37.8332 39.14 36.1066 39.0467 34.2119 38.86V36.97C36.2746 37.11 37.8146 37.18 38.8319 37.18C39.0466 37.18 39.2146 37.1193 39.3359 36.998C39.4666 36.8673 39.5319 36.6947 39.5319 36.48V35.78C39.5319 35.22 39.2519 34.94 38.6919 34.94H34.6319V29.2H41.3519V31.16H37.1519V32.98H39.3919C40.3066 32.98 40.9786 33.19 41.4079 33.61C41.8372 34.0207 42.0519 34.6507 42.0519 35.5V36.48C42.0519 37.3947 41.8372 38.0667 41.4079 38.496C40.9786 38.9253 40.3066 39.14 39.3919 39.14Z"
      fill="#343434"
    />
    <path
      d="M33.5141 32.28C33.5141 33.0733 33.1781 33.7593 32.5061 34.338C32.3008 34.5153 32.0768 34.6927 31.8341 34.87L28.8241 37.04H33.5141V39H25.9541V36.34L30.9941 32.7V32C30.9941 31.44 30.7141 31.16 30.1541 31.16H26.2341V29.2H30.7141C31.6941 29.2 32.4034 29.424 32.8421 29.872C33.2901 30.3107 33.5141 31.02 33.5141 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get50 = () => (
  <>
    <path
      d="M39.8084 31.86C39.8084 31.3 39.5284 31.02 38.9684 31.02H37.4284C36.8684 31.02 36.5884 31.3 36.5884 31.86V36.34C36.5884 36.9 36.8684 37.18 37.4284 37.18H38.9684C39.5284 37.18 39.8084 36.9 39.8084 36.34V31.86ZM42.3284 36.34C42.3284 37.32 42.1044 38.034 41.6564 38.482C41.2177 38.9207 40.5084 39.14 39.5284 39.14H36.8684C35.8884 39.14 35.1744 38.9207 34.7264 38.482C34.2877 38.034 34.0684 37.32 34.0684 36.34V31.86C34.0684 30.88 34.2877 30.1707 34.7264 29.732C35.1744 29.284 35.8884 29.06 36.8684 29.06H39.5284C40.5084 29.06 41.2177 29.284 41.6564 29.732C42.1044 30.1707 42.3284 30.88 42.3284 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M30.5736 39.14C29.0149 39.14 27.2882 39.0467 25.3936 38.86V36.97C27.4562 37.11 28.9962 37.18 30.0136 37.18C30.2282 37.18 30.3962 37.1193 30.5176 36.998C30.6482 36.8673 30.7136 36.6947 30.7136 36.48V35.78C30.7136 35.22 30.4336 34.94 29.8736 34.94H25.8136V29.2H32.5336V31.16H28.3336V32.98H30.5736C31.4882 32.98 32.1602 33.19 32.5896 33.61C33.0189 34.0207 33.2336 34.6507 33.2336 35.5V36.48C33.2336 37.3947 33.0189 38.0667 32.5896 38.496C32.1602 38.9253 31.4882 39.14 30.5736 39.14Z"
      fill="#343434"
    />
  </>
);

export const get100 = () => (
  <>
    <path
      d="M43.1365 31.86C43.1365 31.3 42.8565 31.02 42.2965 31.02H40.7565C40.1965 31.02 39.9165 31.3 39.9165 31.86V36.34C39.9165 36.9 40.1965 37.18 40.7565 37.18H42.2965C42.8565 37.18 43.1365 36.9 43.1365 36.34V31.86ZM45.6565 36.34C45.6565 37.32 45.4325 38.034 44.9845 38.482C44.5458 38.9207 43.8365 39.14 42.8565 39.14H40.1965C39.2165 39.14 38.5025 38.9207 38.0545 38.482C37.6158 38.034 37.3965 37.32 37.3965 36.34V31.86C37.3965 30.88 37.6158 30.1707 38.0545 29.732C38.5025 29.284 39.2165 29.06 40.1965 29.06H42.8565C43.8365 29.06 44.5458 29.284 44.9845 29.732C45.4325 30.1707 45.6565 30.88 45.6565 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M33.7576 31.86C33.7576 31.3 33.4776 31.02 32.9176 31.02H31.3776C30.8176 31.02 30.5376 31.3 30.5376 31.86V36.34C30.5376 36.9 30.8176 37.18 31.3776 37.18H32.9176C33.4776 37.18 33.7576 36.9 33.7576 36.34V31.86ZM36.2776 36.34C36.2776 37.32 36.0536 38.034 35.6056 38.482C35.1669 38.9207 34.4576 39.14 33.4776 39.14H30.8176C29.8376 39.14 29.1236 38.9207 28.6756 38.482C28.2369 38.034 28.0176 37.32 28.0176 36.34V31.86C28.0176 30.88 28.2369 30.1707 28.6756 29.732C29.1236 29.284 29.8376 29.06 30.8176 29.06H33.4776C34.4576 29.06 35.1669 29.284 35.6056 29.732C36.0536 30.1707 36.2776 30.88 36.2776 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M24.0942 39V31.37L22.4842 31.93L21.7842 30.32L24.3742 29.2H26.6142V39H24.0942Z"
      fill="#343434"
    />
  </>
);

export const get200 = () => (
  <>
    <path
      d="M44.4295 31.86C44.4295 31.3 44.1495 31.02 43.5895 31.02H42.0495C41.4895 31.02 41.2095 31.3 41.2095 31.86V36.34C41.2095 36.9 41.4895 37.18 42.0495 37.18H43.5895C44.1495 37.18 44.4295 36.9 44.4295 36.34V31.86ZM46.9495 36.34C46.9495 37.32 46.7255 38.034 46.2775 38.482C45.8388 38.9207 45.1295 39.14 44.1495 39.14H41.4895C40.5095 39.14 39.7955 38.9207 39.3475 38.482C38.9088 38.034 38.6895 37.32 38.6895 36.34V31.86C38.6895 30.88 38.9088 30.1707 39.3475 29.732C39.7955 29.284 40.5095 29.06 41.4895 29.06H44.1495C45.1295 29.06 45.8388 29.284 46.2775 29.732C46.7255 30.1707 46.9495 30.88 46.9495 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M35.0505 31.86C35.0505 31.3 34.7705 31.02 34.2105 31.02H32.6705C32.1105 31.02 31.8305 31.3 31.8305 31.86V36.34C31.8305 36.9 32.1105 37.18 32.6705 37.18H34.2105C34.7705 37.18 35.0505 36.9 35.0505 36.34V31.86ZM37.5705 36.34C37.5705 37.32 37.3465 38.034 36.8985 38.482C36.4599 38.9207 35.7505 39.14 34.7705 39.14H32.1105C31.1305 39.14 30.4165 38.9207 29.9685 38.482C29.5299 38.034 29.3105 37.32 29.3105 36.34V31.86C29.3105 30.88 29.5299 30.1707 29.9685 29.732C30.4165 29.284 31.1305 29.06 32.1105 29.06H34.7705C35.7505 29.06 36.4599 29.284 36.8985 29.732C37.3465 30.1707 37.5705 30.88 37.5705 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M28.3325 32.28C28.3325 33.0733 27.9965 33.7593 27.3245 34.338C27.1191 34.5153 26.8951 34.6927 26.6525 34.87L23.6425 37.04H28.3325V39H20.7725V36.34L25.8125 32.7V32C25.8125 31.44 25.5325 31.16 24.9725 31.16H21.0525V29.2H25.5325C26.5125 29.2 27.2218 29.424 27.6605 29.872C28.1085 30.3107 28.3325 31.02 28.3325 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get250 = () => (
  <>
    <path
      d="M43.9373 31.86C43.9373 31.3 43.6573 31.02 43.0973 31.02H41.5573C40.9973 31.02 40.7173 31.3 40.7173 31.86V36.34C40.7173 36.9 40.9973 37.18 41.5573 37.18H43.0973C43.6573 37.18 43.9373 36.9 43.9373 36.34V31.86ZM46.4573 36.34C46.4573 37.32 46.2333 38.034 45.7853 38.482C45.3466 38.9207 44.6373 39.14 43.6573 39.14H40.9973C40.0173 39.14 39.3033 38.9207 38.8553 38.482C38.4166 38.034 38.1973 37.32 38.1973 36.34V31.86C38.1973 30.88 38.4166 30.1707 38.8553 29.732C39.3033 29.284 40.0173 29.06 40.9973 29.06H43.6573C44.6373 29.06 45.3466 29.284 45.7853 29.732C46.2333 30.1707 46.4573 30.88 46.4573 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M34.7025 39.14C33.1438 39.14 31.4171 39.0467 29.5225 38.86V36.97C31.5851 37.11 33.1251 37.18 34.1425 37.18C34.3571 37.18 34.5251 37.1193 34.6465 36.998C34.7771 36.8673 34.8425 36.6947 34.8425 36.48V35.78C34.8425 35.22 34.5625 34.94 34.0025 34.94H29.9425V29.2H36.6625V31.16H32.4625V32.98H34.7025C35.6171 32.98 36.2891 33.19 36.7185 33.61C37.1478 34.0207 37.3625 34.6507 37.3625 35.5V36.48C37.3625 37.3947 37.1478 38.0667 36.7185 38.496C36.2891 38.9253 35.6171 39.14 34.7025 39.14Z"
      fill="#343434"
    />
    <path
      d="M28.8246 32.28C28.8246 33.0733 28.4886 33.7593 27.8166 34.338C27.6113 34.5153 27.3873 34.6927 27.1446 34.87L24.1346 37.04H28.8246V39H21.2646V36.34L26.3046 32.7V32C26.3046 31.44 26.0246 31.16 25.4646 31.16H21.5446V29.2H26.0246C27.0046 29.2 27.714 29.424 28.1526 29.872C28.6006 30.3107 28.8246 31.02 28.8246 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get300 = () => (
  <>
    <path
      d="M44.4978 31.86C44.4978 31.3 44.2178 31.02 43.6578 31.02H42.1178C41.5578 31.02 41.2778 31.3 41.2778 31.86V36.34C41.2778 36.9 41.5578 37.18 42.1178 37.18H43.6578C44.2178 37.18 44.4978 36.9 44.4978 36.34V31.86ZM47.0178 36.34C47.0178 37.32 46.7938 38.034 46.3458 38.482C45.9071 38.9207 45.1978 39.14 44.2178 39.14H41.5578C40.5778 39.14 39.8638 38.9207 39.4158 38.482C38.9771 38.034 38.7578 37.32 38.7578 36.34V31.86C38.7578 30.88 38.9771 30.1707 39.4158 29.732C39.8638 29.284 40.5778 29.06 41.5578 29.06H44.2178C45.1978 29.06 45.9071 29.284 46.3458 29.732C46.7938 30.1707 47.0178 30.88 47.0178 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M35.1189 31.86C35.1189 31.3 34.8389 31.02 34.2789 31.02H32.7389C32.1789 31.02 31.8989 31.3 31.8989 31.86V36.34C31.8989 36.9 32.1789 37.18 32.7389 37.18H34.2789C34.8389 37.18 35.1189 36.9 35.1189 36.34V31.86ZM37.6389 36.34C37.6389 37.32 37.4149 38.034 36.9669 38.482C36.5282 38.9207 35.8189 39.14 34.8389 39.14H32.1789C31.1989 39.14 30.4849 38.9207 30.0369 38.482C29.5982 38.034 29.3789 37.32 29.3789 36.34V31.86C29.3789 30.88 29.5982 30.1707 30.0369 29.732C30.4849 29.284 31.1989 29.06 32.1789 29.06H34.8389C35.8189 29.06 36.5282 29.284 36.9669 29.732C37.4149 30.1707 37.6389 30.88 37.6389 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M25.80933 39.14C24.35066 39.14 22.62499 39.0467 20.730336 38.86001V36.97001C22.79301 37.11001 24.33301 37.18001 25.35034 37.18001C25.565 37.18001 25.733 37.11935 25.85434 36.99801C25.985 36.86735 26.05034 36.69468 26.05034 36.48001V35.92001C26.05034 35.36001 25.77034 35.08001 25.21034 35.08001H22.27034V33.12001H24.93034C25.49034 33.12001 25.77034 32.84001 25.77034 32.28001V32.00001C25.77034 31.44001 25.49034 31.16001 24.93034 31.16001H20.870336V29.200012H25.63034C26.545 29.200012 27.217 29.410012 27.65634 29.830012C28.08666 30.24068 28.30134 30.87068 28.30134 31.72001C28.30134 32.28001 28.17066 32.73735 27.90934 33.09201C27.65734 33.43735 27.32134 33.68001 26.90134 33.82001C27.65734 34.02535 28.17066 34.52935 28.44134 35.33201C28.53466 35.62135 28.58134 35.95735 28.58134 36.34001V36.48001C28.58134 37.39468 28.35666 38.06668 27.92734 38.49601C27.498 38.92535 26.82699 39.14 25.80933 39.14Z"
      fill="#343434"
    />
  </>
);

export const get500 = () => (
  <>
    <path
      d="M44.4978 31.86C44.4978 31.3 44.2178 31.02 43.6578 31.02H42.1178C41.5578 31.02 41.2778 31.3 41.2778 31.86V36.34C41.2778 36.9 41.5578 37.18 42.1178 37.18H43.6578C44.2178 37.18 44.4978 36.9 44.4978 36.34V31.86ZM47.0178 36.34C47.0178 37.32 46.7938 38.034 46.3458 38.482C45.9071 38.9207 45.1978 39.14 44.2178 39.14H41.5578C40.5778 39.14 39.8638 38.9207 39.4158 38.482C38.9771 38.034 38.7578 37.32 38.7578 36.34V31.86C38.7578 30.88 38.9771 30.1707 39.4158 29.732C39.8638 29.284 40.5778 29.06 41.5578 29.06H44.2178C45.1978 29.06 45.9071 29.284 46.3458 29.732C46.7938 30.1707 47.0178 30.88 47.0178 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M35.1189 31.86C35.1189 31.3 34.8389 31.02 34.2789 31.02H32.7389C32.1789 31.02 31.8989 31.3 31.8989 31.86V36.34C31.8989 36.9 32.1789 37.18 32.7389 37.18H34.2789C34.8389 37.18 35.1189 36.9 35.1189 36.34V31.86ZM37.6389 36.34C37.6389 37.32 37.4149 38.034 36.9669 38.482C36.5282 38.9207 35.8189 39.14 34.8389 39.14H32.1789C31.1989 39.14 30.4849 38.9207 30.0369 38.482C29.5982 38.034 29.3789 37.32 29.3789 36.34V31.86C29.3789 30.88 29.5982 30.1707 30.0369 29.732C30.4849 29.284 31.1989 29.06 32.1789 29.06H34.8389C35.8189 29.06 36.5282 29.284 36.9669 29.732C37.4149 30.1707 37.6389 30.88 37.6389 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M25.8841 39.14C24.3254 39.14 22.5988 39.0467 20.7041 38.86V36.97C22.7668 37.11 24.3068 37.18 25.3241 37.18C25.5388 37.18 25.7068 37.1193 25.8281 36.998C25.9588 36.8673 26.0241 36.6947 26.0241 36.48V35.78C26.0241 35.22 25.7441 34.94 25.1841 34.94H21.1241V29.2H27.8441V31.16H23.6441V32.98H25.8841C26.7988 32.98 27.4708 33.19 27.9001 33.61C28.3294 34.0207 28.5441 34.6507 28.5441 35.5V36.48C28.5441 37.3947 28.3294 38.0667 27.9001 38.496C27.4708 38.9253 26.7988 39.14 25.8841 39.14Z"
      fill="#343434"
    />
  </>
);

export const get1k = () => (
  <>
    <path
      d="M40.0551 39.14C39.5977 39.14 39.1964 39.0933 38.8511 39C38.5151 38.9067 38.1931 38.706 37.8851 38.398C37.6984 38.2113 37.5117 38.0247 37.3251 37.838C37.1384 37.6513 36.9517 37.4647 36.7651 37.278C36.6157 37.1193 36.4944 36.998 36.4011 36.914C36.3077 36.8207 36.2144 36.76 36.1211 36.732C36.0371 36.6947 35.9157 36.676 35.7571 36.676C35.5517 36.676 35.4304 36.6947 35.3931 36.732C35.3557 36.7693 35.3371 36.8953 35.3371 37.11V39H32.7891V30.138H35.3371V34.884H35.7851C35.9437 34.884 36.0931 34.8187 36.2331 34.688C36.3171 34.604 36.4431 34.45 36.6111 34.226C36.7884 34.002 36.9797 33.7547 37.1851 33.484C37.3904 33.2133 37.5771 32.966 37.7451 32.742H40.6851L40.4611 33.176C40.2371 33.456 39.9757 33.764 39.6771 34.1C39.3877 34.436 39.1031 34.744 38.8231 35.024C38.5524 35.304 38.3331 35.5 38.1651 35.612C38.5291 35.8547 38.8697 36.116 39.1871 36.396C39.5044 36.6667 39.9104 36.9093 40.4051 37.124C40.7317 37.264 41.0071 37.3573 41.2311 37.404C41.4644 37.4413 41.6277 37.46 41.7211 37.46L41.3291 39C41.2357 39.028 41.0771 39.056 40.8531 39.084C40.6384 39.1213 40.3724 39.14 40.0551 39.14Z"
      fill="#343434"
    />
    <path
      d="M28.5854 39V31.37L26.9754 31.93L26.2754 30.32L28.8654 29.2H31.1054V39H28.5854Z"
      fill="#343434"
    />
  </>
);

export const get2k = () => (
  <>
    <path
      d="M41.3471 39.14C40.8897 39.14 40.4884 39.0933 40.1431 39C39.8071 38.9067 39.4851 38.706 39.1771 38.398C38.9904 38.2113 38.8037 38.0247 38.6171 37.838C38.4304 37.6513 38.2437 37.4647 38.0571 37.278C37.9077 37.1193 37.7864 36.998 37.6931 36.914C37.5997 36.8207 37.5064 36.76 37.4131 36.732C37.3291 36.6947 37.2077 36.676 37.0491 36.676C36.8437 36.676 36.7224 36.6947 36.6851 36.732C36.6477 36.7693 36.6291 36.8953 36.6291 37.11V39H34.0811V30.138H36.6291V34.884H37.0771C37.2357 34.884 37.3851 34.8187 37.5251 34.688C37.6091 34.604 37.7351 34.45 37.9031 34.226C38.0804 34.002 38.2717 33.7547 38.4771 33.484C38.6824 33.2133 38.8691 32.966 39.0371 32.742H41.9771L41.7531 33.176C41.5291 33.456 41.2677 33.764 40.9691 34.1C40.6797 34.436 40.3951 34.744 40.1151 35.024C39.8444 35.304 39.6251 35.5 39.4571 35.612C39.8211 35.8547 40.1617 36.116 40.4791 36.396C40.7964 36.6667 41.2024 36.9093 41.6971 37.124C42.0237 37.264 42.2991 37.3573 42.5231 37.404C42.7564 37.4413 42.9197 37.46 43.0131 37.46L42.6211 39C42.5277 39.028 42.3691 39.056 42.1451 39.084C41.9304 39.1213 41.6644 39.14 41.3471 39.14Z"
      fill="#343434"
    />
    <path
      d="M32.8237 32.28C32.8237 33.0733 32.4877 33.7593 31.8157 34.338C31.6103 34.5153 31.3863 34.6927 31.1437 34.87L28.1337 37.04H32.8237V39H25.2637V36.34L30.3037 32.7V32C30.3037 31.44 30.0237 31.16 29.4637 31.16H25.5437V29.2H30.0237C31.0037 29.2 31.713 29.424 32.1517 29.872C32.5997 30.3107 32.8237 31.02 32.8237 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get2_5k = () => (
  <>
    <path
      d="M47.5238 39.14C47.0665 39.14 46.6651 39.0933 46.3198 39C45.9838 38.9067 45.6618 38.706 45.3538 38.398C45.1671 38.2113 44.9805 38.0247 44.7938 37.838C44.6071 37.6513 44.4205 37.4647 44.2338 37.278C44.0845 37.1193 43.9631 36.998 43.8698 36.914C43.7765 36.8207 43.6831 36.76 43.5898 36.732C43.5058 36.6947 43.3845 36.676 43.2258 36.676C43.0205 36.676 42.8991 36.6947 42.8618 36.732C42.8245 36.7693 42.8058 36.8953 42.8058 37.11V39H40.2578V30.138H42.8058V34.884H43.2538C43.4125 34.884 43.5618 34.8187 43.7018 34.688C43.7858 34.604 43.9118 34.45 44.0798 34.226C44.2571 34.002 44.4485 33.7547 44.6538 33.484C44.8591 33.2133 45.0458 32.966 45.2138 32.742H48.1538L47.9298 33.176C47.7058 33.456 47.4445 33.764 47.1458 34.1C46.8565 34.436 46.5718 34.744 46.2918 35.024C46.0211 35.304 45.8018 35.5 45.6338 35.612C45.9978 35.8547 46.3385 36.116 46.6558 36.396C46.9731 36.6667 47.3791 36.9093 47.8738 37.124C48.2005 37.264 48.4758 37.3573 48.6998 37.404C48.9331 37.4413 49.0965 37.46 49.1898 37.46L48.7978 39C48.7045 39.028 48.5458 39.056 48.3218 39.084C48.1071 39.1213 47.8411 39.14 47.5238 39.14Z"
      fill="#343434"
    />
    <path
      d="M36.4827 39.14C34.9241 39.14 33.1974 39.0467 31.3027 38.86V36.97C33.3654 37.11 34.9054 37.18 35.9227 37.18C36.1374 37.18 36.3054 37.1193 36.4267 36.998C36.5574 36.8673 36.6227 36.6947 36.6227 36.48V35.78C36.6227 35.22 36.3427 34.94 35.7827 34.94H31.7227V29.2H38.4427V31.16H34.2427V32.98H36.4827C37.3974 32.98 38.0694 33.19 38.4987 33.61C38.9281 34.0207 39.1427 34.6507 39.1427 35.5V36.48C39.1427 37.3947 38.9281 38.0667 38.4987 38.496C38.0694 38.9253 37.3974 39.14 36.4827 39.14Z"
      fill="#343434"
    />
    <path
      d="M28.8983 39C28.553 39 28.301 38.93 28.1423 38.79C27.9836 38.65 27.9043 38.4167 27.9043 38.09C27.9043 37.754 27.979 37.5207 28.1283 37.39C28.287 37.25 28.5436 37.18 28.8983 37.18C29.253 37.18 29.5096 37.25 29.6683 37.39C29.827 37.5207 29.9063 37.754 29.9063 38.09C29.9063 38.4073 29.827 38.6407 29.6683 38.79C29.5096 38.93 29.253 39 28.8983 39Z"
      fill="#343434"
    />
    <path
      d="M26.6469 32.28C26.6469 33.0733 26.3109 33.7593 25.6389 34.338C25.4336 34.5153 25.2096 34.6927 24.9669 34.87L21.9569 37.04H26.6469V39H19.0869V36.34L24.1269 32.7V32C24.1269 31.44 23.8469 31.16 23.2869 31.16H19.3669V29.2H23.8469C24.8269 29.2 25.5362 29.424 25.9749 29.872C26.4229 30.3107 26.6469 31.02 26.6469 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get5k = () => (
  <>
    <path
      d="M41.4154 39.14C40.9581 39.14 40.5567 39.0933 40.2114 39C39.8754 38.9067 39.5534 38.706 39.2454 38.398C39.0587 38.2113 38.8721 38.0247 38.6854 37.838C38.4987 37.6513 38.3121 37.4647 38.1254 37.278C37.9761 37.1193 37.8547 36.998 37.7614 36.914C37.6681 36.8207 37.5747 36.76 37.4814 36.732C37.3974 36.6947 37.2761 36.676 37.1174 36.676C36.9121 36.676 36.7907 36.6947 36.7534 36.732C36.7161 36.7693 36.6974 36.8953 36.6974 37.11V39H34.1494V30.138H36.6974V34.884H37.1454C37.3041 34.884 37.4534 34.8187 37.5934 34.688C37.6774 34.604 37.8034 34.45 37.9714 34.226C38.1487 34.002 38.3401 33.7547 38.5454 33.484C38.7507 33.2133 38.9374 32.966 39.1054 32.742H42.0454L41.8214 33.176C41.5974 33.456 41.3361 33.764 41.0374 34.1C40.7481 34.436 40.4634 34.744 40.1834 35.024C39.9127 35.304 39.6934 35.5 39.5254 35.612C39.8894 35.8547 40.2301 36.116 40.5474 36.396C40.8647 36.6667 41.2707 36.9093 41.7654 37.124C42.0921 37.264 42.3674 37.3573 42.5914 37.404C42.8247 37.4413 42.9881 37.46 43.0814 37.46L42.6894 39C42.5961 39.028 42.4374 39.056 42.2134 39.084C41.9987 39.1213 41.7327 39.14 41.4154 39.14Z"
      fill="#343434"
    />
    <path
      d="M30.3753 39.14C28.8166 39.14 27.09 39.0467 25.1953 38.86V36.97C27.258 37.11 28.798 37.18 29.8153 37.18C30.03 37.18 30.198 37.1193 30.3193 36.998C30.45 36.8673 30.5153 36.6947 30.5153 36.48V35.78C30.5153 35.22 30.2353 34.94 29.6753 34.94H25.6153V29.2H32.3353V31.16H28.1353V32.98H30.3753C31.29 32.98 31.962 33.19 32.3913 33.61C32.8206 34.0207 33.0353 34.6507 33.0353 35.5V36.48C33.0353 37.3947 32.8206 38.0667 32.3913 38.496C31.962 38.9253 31.29 39.14 30.3753 39.14Z"
      fill="#343434"
    />
  </>
);

export const get10k = () => (
  <>
    <path
      d="M44.7445 39.14C44.2872 39.14 43.8858 39.0933 43.5405 39C43.2045 38.9067 42.8825 38.706 42.5745 38.398C42.3878 38.2113 42.2012 38.0247 42.0145 37.838C41.8278 37.6513 41.6412 37.4647 41.4545 37.278C41.3052 37.1193 41.1838 36.998 41.0905 36.914C40.9972 36.8207 40.9038 36.76 40.8105 36.732C40.7265 36.6947 40.6052 36.676 40.4465 36.676C40.2412 36.676 40.1198 36.6947 40.0825 36.732C40.0452 36.7693 40.0265 36.8953 40.0265 37.11V39H37.4785V30.138H40.0265V34.884H40.4745C40.6332 34.884 40.7825 34.8187 40.9225 34.688C41.0065 34.604 41.1325 34.45 41.3005 34.226C41.4778 34.002 41.6692 33.7547 41.8745 33.484C42.0798 33.2133 42.2665 32.966 42.4345 32.742H45.3745L45.1505 33.176C44.9265 33.456 44.6652 33.764 44.3665 34.1C44.0772 34.436 43.7925 34.744 43.5125 35.024C43.2418 35.304 43.0225 35.5 42.8545 35.612C43.2185 35.8547 43.5592 36.116 43.8765 36.396C44.1938 36.6667 44.5998 36.9093 45.0945 37.124C45.4212 37.264 45.6965 37.3573 45.9205 37.404C46.1539 37.4413 46.3172 37.46 46.4105 37.46L46.0185 39C45.9252 39.028 45.7665 39.056 45.5425 39.084C45.3278 39.1213 45.0618 39.14 44.7445 39.14Z"
      fill="#343434"
    />
    <path
      d="M33.5593 31.86C33.5593 31.3 33.2793 31.02 32.7193 31.02H31.1793C30.6193 31.02 30.3393 31.3 30.3393 31.86V36.34C30.3393 36.9 30.6193 37.18 31.1793 37.18H32.7193C33.2793 37.18 33.5593 36.9 33.5593 36.34V31.86ZM36.0793 36.34C36.0793 37.32 35.8553 38.034 35.4073 38.482C34.9687 38.9207 34.2593 39.14 33.2793 39.14H30.6193C29.6393 39.14 28.9253 38.9207 28.4773 38.482C28.0387 38.034 27.8193 37.32 27.8193 36.34V31.86C27.8193 30.88 28.0387 30.1707 28.4773 29.732C28.9253 29.284 29.6393 29.06 30.6193 29.06H33.2793C34.2593 29.06 34.9687 29.284 35.4073 29.732C35.8553 30.1707 36.0793 30.88 36.0793 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M23.8959 39V31.37L22.2859 31.93L21.5859 30.32L24.1759 29.2H26.4159V39H23.8959Z"
      fill="#343434"
    />
  </>
);

export const get20k = () => (
  <>
    <path
      d="M46.0365 39.14C45.5792 39.14 45.1778 39.0933 44.8325 39C44.4965 38.9067 44.1745 38.706 43.8665 38.398C43.6798 38.2113 43.4932 38.0247 43.3065 37.838C43.1198 37.6513 42.9332 37.4647 42.7465 37.278C42.5972 37.1193 42.4758 36.998 42.3825 36.914C42.2892 36.8207 42.1958 36.76 42.1025 36.732C42.0185 36.6947 41.8972 36.676 41.7385 36.676C41.5332 36.676 41.4118 36.6947 41.3745 36.732C41.3372 36.7693 41.3185 36.8953 41.3185 37.11V39H38.7705V30.138H41.3185V34.884H41.7665C41.9252 34.884 42.0745 34.8187 42.2145 34.688C42.2985 34.604 42.4245 34.45 42.5925 34.226C42.7698 34.002 42.9612 33.7547 43.1665 33.484C43.3718 33.2133 43.5585 32.966 43.7265 32.742H46.6665L46.4425 33.176C46.2185 33.456 45.9572 33.764 45.6585 34.1C45.3692 34.436 45.0845 34.744 44.8045 35.024C44.5338 35.304 44.3145 35.5 44.1465 35.612C44.5105 35.8547 44.8512 36.116 45.1685 36.396C45.4858 36.6667 45.8918 36.9093 46.3865 37.124C46.7132 37.264 46.9885 37.3573 47.2125 37.404C47.4458 37.4413 47.6092 37.46 47.7025 37.46L47.3105 39C47.2172 39.028 47.0585 39.056 46.8345 39.084C46.6198 39.1213 46.3538 39.14 46.0365 39.14Z"
      fill="#343434"
    />
    <path
      d="M34.8523 31.86C34.8523 31.3 34.5723 31.02 34.0123 31.02H32.4723C31.9123 31.02 31.6323 31.3 31.6323 31.86V36.34C31.6323 36.9 31.9123 37.18 32.4723 37.18H34.0123C34.5723 37.18 34.8523 36.9 34.8523 36.34V31.86ZM37.3723 36.34C37.3723 37.32 37.1483 38.034 36.7003 38.482C36.2616 38.9207 35.5523 39.14 34.5723 39.14H31.9123C30.9323 39.14 30.2183 38.9207 29.7703 38.482C29.3316 38.034 29.1123 37.32 29.1123 36.34V31.86C29.1123 30.88 29.3316 30.1707 29.7703 29.732C30.2183 29.284 30.9323 29.06 31.9123 29.06H34.5723C35.5523 29.06 36.2616 29.284 36.7003 29.732C37.1483 30.1707 37.3723 30.88 37.3723 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M28.1342 32.28C28.1342 33.0733 27.7982 33.7593 27.1262 34.338C26.9209 34.5153 26.6969 34.6927 26.4542 34.87L23.4442 37.04H28.1342V39H20.5742V36.34L25.6142 32.7V32C25.6142 31.44 25.3342 31.16 24.7742 31.16H20.8542V29.2H25.3342C26.3142 29.2 27.0236 29.424 27.4622 29.872C27.9102 30.3107 28.1342 31.02 28.1342 32V32.28Z"
      fill="#343434"
    />
  </>
);

export const get50k = () => (
  <>
    <path
      d="M46.1049 39.14C45.6475 39.14 45.2462 39.0933 44.9009 39C44.5649 38.9067 44.2429 38.706 43.9349 38.398C43.7482 38.2113 43.5615 38.0247 43.3749 37.838C43.1882 37.6513 43.0015 37.4647 42.8149 37.278C42.6655 37.1193 42.5442 36.998 42.4509 36.914C42.3575 36.8207 42.2642 36.76 42.1709 36.732C42.0869 36.6947 41.9655 36.676 41.8069 36.676C41.6015 36.676 41.4802 36.6947 41.4429 36.732C41.4055 36.7693 41.3869 36.8953 41.3869 37.11V39H38.8389V30.138H41.3869V34.884H41.8349C41.9935 34.884 42.1429 34.8187 42.2829 34.688C42.3669 34.604 42.4929 34.45 42.6609 34.226C42.8382 34.002 43.0295 33.7547 43.2349 33.484C43.4402 33.2133 43.6269 32.966 43.7949 32.742H46.7349L46.5109 33.176C46.2869 33.456 46.0255 33.764 45.7269 34.1C45.4375 34.436 45.1529 34.744 44.8729 35.024C44.6022 35.304 44.3829 35.5 44.2149 35.612C44.5789 35.8547 44.9195 36.116 45.2369 36.396C45.5542 36.6667 45.9602 36.9093 46.4549 37.124C46.7815 37.264 47.0569 37.3573 47.2809 37.404C47.5142 37.4413 47.6775 37.46 47.7709 37.46L47.3789 39C47.2855 39.028 47.1269 39.056 46.9029 39.084C46.6882 39.1213 46.4222 39.14 46.1049 39.14Z"
      fill="#343434"
    />
    <path
      d="M34.9207 31.86C34.9207 31.3 34.6407 31.02 34.0807 31.02H32.5407C31.9807 31.02 31.7007 31.3 31.7007 31.86V36.34C31.7007 36.9 31.9807 37.18 32.5407 37.18H34.0807C34.6407 37.18 34.9207 36.9 34.9207 36.34V31.86ZM37.4407 36.34C37.4407 37.32 37.2167 38.034 36.7687 38.482C36.33 38.9207 35.6207 39.14 34.6407 39.14H31.9807C31.0007 39.14 30.2867 38.9207 29.8387 38.482C29.4 38.034 29.1807 37.32 29.1807 36.34V31.86C29.1807 30.88 29.4 30.1707 29.8387 29.732C30.2867 29.284 31.0007 29.06 31.9807 29.06H34.6407C35.6207 29.06 36.33 29.284 36.7687 29.732C37.2167 30.1707 37.4407 30.88 37.4407 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M25.6859 39.14C24.1272 39.14 22.4005 39.0467 20.5059 38.86V36.97C22.5685 37.11 24.1085 37.18 25.1259 37.18C25.3405 37.18 25.5085 37.1193 25.6299 36.998C25.7605 36.8673 25.8259 36.6947 25.8259 36.48V35.78C25.8259 35.22 25.5459 34.94 24.9859 34.94H20.9259V29.2H27.6459V31.16H23.4459V32.98H25.6859C26.6005 32.98 27.2725 33.19 27.7019 33.61C28.1312 34.0207 28.3459 34.6507 28.3459 35.5V36.48C28.3459 37.3947 28.1312 38.0667 27.7019 38.496C27.2725 38.9253 26.6005 39.14 25.6859 39.14Z"
      fill="#343434"
    />
  </>
);

export const get100k = () => (
  <>
    <path
      d="M48.5941 39.1397C48.1368 39.1397 47.7355 39.093 47.3901 38.9997C47.0541 38.9064 46.7321 38.7057 46.4241 38.3977C46.2375 38.211 46.0508 38.0244 45.8641 37.8377C45.6775 37.651 45.4908 37.4644 45.3041 37.2777C45.1548 37.119 45.0335 36.9977 44.9401 36.9137C44.8468 36.8204 44.7535 36.7597 44.6601 36.7317C44.5761 36.6944 44.4548 36.6757 44.2961 36.6757C44.0908 36.6757 43.9695 36.6944 43.9321 36.7317C43.8948 36.769 43.8761 36.895 43.8761 37.1097V38.9997H41.3281V30.1377H43.8761V34.8837H44.3241C44.4828 34.8837 44.6321 34.8184 44.7721 34.6877C44.8561 34.6037 44.9821 34.4497 45.1501 34.2257C45.3275 34.0017 45.5188 33.7544 45.7241 33.4837C45.9295 33.213 46.1161 32.9657 46.2841 32.7417H49.2241L49.0001 33.1757C48.7761 33.4557 48.5148 33.7637 48.2161 34.0997C47.9268 34.4357 47.6421 34.7437 47.3621 35.0237C47.0915 35.3037 46.8721 35.4997 46.7041 35.6117C47.0681 35.8544 47.4088 36.1157 47.7261 36.3957C48.0435 36.6664 48.4495 36.909 48.9441 37.1237C49.2708 37.2637 49.5461 37.357 49.7701 37.4037C50.0035 37.441 50.1668 37.4597 50.2601 37.4597L49.8681 38.9997C49.7748 39.0277 49.6161 39.0557 49.3921 39.0837C49.1775 39.121 48.9115 39.1397 48.5941 39.1397Z"
      fill="#343434"
    />
    <path
      d="M37.9666 31.8596C37.9666 31.2996 37.6866 31.0196 37.1266 31.0196H35.5866C35.0266 31.0196 34.7466 31.2996 34.7466 31.8596V36.3396C34.7466 36.8996 35.0266 37.1796 35.5866 37.1796H37.1266C37.6866 37.1796 37.9666 36.8996 37.9666 36.3396V31.8596ZM40.4866 36.3396C40.4866 37.3196 40.2626 38.0336 39.8146 38.4816C39.3759 38.9202 38.6666 39.1396 37.6866 39.1396H35.0266C34.0466 39.1396 33.3326 38.9202 32.8846 38.4816C32.4459 38.0336 32.2266 37.3196 32.2266 36.3396V31.8596C32.2266 30.8796 32.4459 30.1702 32.8846 29.7316C33.3326 29.2836 34.0466 29.0596 35.0266 29.0596H37.6866C38.6666 29.0596 39.3759 29.2836 39.8146 29.7316C40.2626 30.1702 40.4866 30.8796 40.4866 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M29.1462 31.8596C29.1462 31.2996 28.8662 31.0196 28.3062 31.0196H26.7662C26.2062 31.0196 25.9262 31.2996 25.9262 31.8596V36.3396C25.9262 36.8996 26.2062 37.1796 26.7662 37.1796H28.3062C28.8662 37.1796 29.1462 36.8996 29.1462 36.3396V31.8596ZM31.6663 36.3396C31.6663 37.3196 31.4423 38.0336 30.9943 38.4816C30.5556 38.9202 29.8463 39.1396 28.8663 39.1396H26.2063C25.2263 39.1396 24.5123 38.9202 24.0642 38.4816C23.6256 38.0336 23.4062 37.3196 23.4062 36.3396V31.8596C23.4062 30.8796 23.6256 30.1702 24.0642 29.7316C24.5123 29.2836 25.2263 29.0596 26.2063 29.0596H28.8663C29.8463 29.0596 30.5556 29.2836 30.9943 29.7316C31.4423 30.1702 31.6663 30.8796 31.6663 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M20.0444 38.9992V31.3692L18.4344 31.9292L17.7344 30.3192L20.3244 29.1992H22.5644V38.9992H20.0444Z"
      fill="#343434"
    />
  </>
);

export const get200k = () => (
  <>
    <path
      d="M49.891 39.1397C49.4337 39.1397 49.0323 39.093 48.687 38.9997C48.351 38.9064 48.029 38.7057 47.721 38.3977C47.5343 38.211 47.3477 38.0244 47.161 37.8377C46.9743 37.651 46.7877 37.4644 46.601 37.2777C46.4517 37.119 46.3303 36.9977 46.237 36.9137C46.1437 36.8204 46.0503 36.7597 45.957 36.7317C45.873 36.6944 45.7517 36.6757 45.593 36.6757C45.3877 36.6757 45.2663 36.6944 45.229 36.7317C45.1917 36.769 45.173 36.895 45.173 37.1097V38.9997H42.625V30.1377H45.173V34.8837H45.621C45.7797 34.8837 45.929 34.8184 46.069 34.6877C46.153 34.6037 46.279 34.4497 46.447 34.2257C46.6243 34.0017 46.8157 33.7544 47.021 33.4837C47.2263 33.213 47.413 32.9657 47.581 32.7417H50.521L50.297 33.1757C50.073 33.4557 49.8117 33.7637 49.513 34.0997C49.2237 34.4357 48.939 34.7437 48.659 35.0237C48.3883 35.3037 48.169 35.4997 48.001 35.6117C48.365 35.8544 48.7057 36.1157 49.023 36.3957C49.3403 36.6664 49.7463 36.909 50.241 37.1237C50.5677 37.2637 50.843 37.357 51.067 37.4037C51.3003 37.441 51.4637 37.4597 51.557 37.4597L51.165 38.9997C51.0717 39.0277 50.913 39.0557 50.689 39.0837C50.4743 39.121 50.2083 39.1397 49.891 39.1397Z"
      fill="#343434"
    />
    <path
      d="M39.2634 31.8596C39.2634 31.2996 38.9834 31.0196 38.4234 31.0196H36.8834C36.3234 31.0196 36.0434 31.2996 36.0434 31.8596V36.3396C36.0434 36.8996 36.3234 37.1796 36.8834 37.1796H38.4234C38.9834 37.1796 39.2634 36.8996 39.2634 36.3396V31.8596ZM41.7834 36.3396C41.7834 37.3196 41.5594 38.0336 41.1114 38.4816C40.6728 38.9202 39.9634 39.1396 38.9834 39.1396H36.3234C35.3434 39.1396 34.6294 38.9202 34.1814 38.4816C33.7428 38.0336 33.5234 37.3196 33.5234 36.3396V31.8596C33.5234 30.8796 33.7428 30.1702 34.1814 29.7316C34.6294 29.2836 35.3434 29.0596 36.3234 29.0596H38.9834C39.9634 29.0596 40.6728 29.2836 41.1114 29.7316C41.5594 30.1702 41.7834 30.8796 41.7834 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M30.4431 31.8596C30.4431 31.2996 30.1631 31.0196 29.6031 31.0196H28.0631C27.5031 31.0196 27.2231 31.2996 27.2231 31.8596V36.3396C27.2231 36.8996 27.5031 37.1796 28.0631 37.1796H29.6031C30.1631 37.1796 30.4431 36.8996 30.4431 36.3396V31.8596ZM32.9631 36.3396C32.9631 37.3196 32.7391 38.0336 32.2911 38.4816C31.8525 38.9202 31.1431 39.1396 30.1631 39.1396H27.5031C26.5231 39.1396 25.8091 38.9202 25.3611 38.4816C24.9225 38.0336 24.7031 37.3196 24.7031 36.3396V31.8596C24.7031 30.8796 24.9225 30.1702 25.3611 29.7316C25.8091 29.2836 26.5231 29.0596 27.5031 29.0596H30.1631C31.1431 29.0596 31.8525 29.2836 32.2911 29.7316C32.7391 30.1702 32.9631 30.8796 32.9631 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M24.2866 32.2792C24.2866 33.0726 23.9506 33.7586 23.2786 34.3372C23.0732 34.5146 22.8492 34.6919 22.6066 34.8692L19.5966 37.0392H24.2866V38.9992H16.7266V36.3392L21.7666 32.6992V31.9992C21.7666 31.4392 21.4866 31.1592 20.9266 31.1592H17.0066V29.1992H21.4866C22.4666 29.1992 23.1759 29.4232 23.6146 29.8712C24.0626 30.3099 24.2866 31.0192 24.2866 31.9992V32.2792Z"
      fill="#343434"
    />
  </>
);

export const get500k = () => (
  <>
    <path
      d="M49.9545 39.14C49.4971 39.14 49.0958 39.0933 48.7505 39C48.4145 38.9067 48.0925 38.706 47.7845 38.398C47.5978 38.2113 47.4111 38.0247 47.2245 37.838C47.0378 37.6513 46.8511 37.4647 46.6645 37.278C46.5151 37.1193 46.3938 36.998 46.3005 36.914C46.2071 36.8207 46.1138 36.76 46.0205 36.732C45.9365 36.6947 45.8151 36.676 45.6565 36.676C45.4511 36.676 45.3298 36.6947 45.2925 36.732C45.2551 36.7693 45.2365 36.8953 45.2365 37.11V39H42.6885V30.138H45.2365V34.884H45.6845C45.8431 34.884 45.9925 34.8187 46.1325 34.688C46.2165 34.604 46.3425 34.45 46.5105 34.226C46.6878 34.002 46.8791 33.7547 47.0845 33.484C47.2898 33.2133 47.4765 32.966 47.6445 32.742H50.5845L50.3605 33.176C50.1365 33.456 49.8751 33.764 49.5765 34.1C49.2871 34.436 49.0025 34.744 48.7225 35.024C48.4518 35.304 48.2325 35.5 48.0645 35.612C48.4285 35.8547 48.7691 36.116 49.0865 36.396C49.4038 36.6667 49.8098 36.9093 50.3045 37.124C50.6311 37.264 50.9065 37.3573 51.1305 37.404C51.3638 37.4413 51.5271 37.46 51.6205 37.46L51.2285 39C51.1351 39.028 50.9765 39.056 50.7525 39.084C50.5378 39.1213 50.2718 39.14 49.9545 39.14Z"
      fill="#343434"
    />
    <path
      d="M39.3298 31.86C39.3298 31.3 39.0498 31.02 38.4898 31.02H36.9498C36.3898 31.02 36.1098 31.3 36.1098 31.86V36.34C36.1098 36.9 36.3898 37.18 36.9498 37.18H38.4898C39.0498 37.18 39.3298 36.9 39.3298 36.34V31.86ZM41.8498 36.34C41.8498 37.32 41.6258 38.034 41.1778 38.482C40.7392 38.9207 40.0298 39.14 39.0498 39.14H36.3898C35.4098 39.14 34.6958 38.9207 34.2478 38.482C33.8092 38.034 33.5898 37.32 33.5898 36.34V31.86C33.5898 30.88 33.8092 30.1707 34.2478 29.732C34.6958 29.284 35.4098 29.06 36.3898 29.06H39.0498C40.0298 29.06 40.7392 29.284 41.1778 29.732C41.6258 30.1707 41.8498 30.88 41.8498 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M30.5105 31.86C30.5105 31.3 30.2305 31.02 29.6705 31.02H28.1305C27.5705 31.02 27.2905 31.3 27.2905 31.86V36.34C27.2905 36.9 27.5705 37.18 28.1305 37.18H29.6705C30.2305 37.18 30.5105 36.9 30.5105 36.34V31.86ZM33.0305 36.34C33.0305 37.32 32.8065 38.034 32.3585 38.482C31.9198 38.9207 31.2105 39.14 30.2305 39.14H27.5705C26.5905 39.14 25.8765 38.9207 25.4285 38.482C24.9898 38.034 24.7705 37.32 24.7705 36.34V31.86C24.7705 30.88 24.9898 30.1707 25.4285 29.732C25.8765 29.284 26.5905 29.06 27.5705 29.06H30.2305C31.2105 29.06 31.9198 29.284 32.3585 29.732C32.8065 30.1707 33.0305 30.88 33.0305 31.86V36.34Z"
      fill="#343434"
    />
    <path
      d="M21.8363 39.14C20.2776 39.14 18.5509 39.0467 16.6562 38.86V36.97C18.7189 37.11 20.2589 37.18 21.2763 37.18C21.4909 37.18 21.6589 37.1193 21.7803 36.998C21.9109 36.8673 21.9762 36.6947 21.9762 36.48V35.78C21.9762 35.22 21.6963 34.94 21.1363 34.94H17.0763V29.2H23.7963V31.16H19.5963V32.98H21.8363C22.7509 32.98 23.4229 33.19 23.8523 33.61C24.2816 34.0207 24.4963 34.6507 24.4963 35.5V36.48C24.4963 37.3947 24.2816 38.0667 23.8523 38.496C23.4229 38.9253 22.7509 39.14 21.8363 39.14Z"
      fill="#343434"
    />
  </>
);

export const get1m = () => (
  <>
    <path
      d="M31.1719 39.0005V32.7285H33.2019L33.5519 32.8965C33.8225 32.8498 34.1259 32.8125 34.4619 32.7845C34.8072 32.7472 35.1759 32.7285 35.5679 32.7285H36.3239C36.5945 32.7285 36.8559 32.7658 37.1079 32.8405C37.3599 32.9058 37.5465 33.0038 37.6679 33.1345C37.8265 33.0505 37.9992 32.9805 38.1859 32.9245C38.3725 32.8592 38.6105 32.8125 38.8999 32.7845C39.1985 32.7472 39.5765 32.7285 40.0339 32.7285H40.7339C41.2005 32.7285 41.5599 32.7938 41.8119 32.9245C42.0639 33.0552 42.2412 33.2185 42.3439 33.4145C42.4559 33.6105 42.5212 33.8112 42.5399 34.0165C42.5679 34.2125 42.5819 34.3805 42.5819 34.5205V39.0005H40.0199V35.0525C40.0199 34.6792 39.7959 34.4925 39.3479 34.4925H38.5639C38.4145 34.4925 38.3119 34.5112 38.2559 34.5485C38.1999 34.5858 38.1719 34.6698 38.1719 34.8005V39.0005H35.5679V35.0525C35.5679 34.6792 35.3439 34.4925 34.8959 34.4925H34.1819C34.0045 34.4925 33.8879 34.5112 33.8319 34.5485C33.7852 34.5765 33.7619 34.6605 33.7619 34.8005V39.0005H31.1719Z"
      fill="#343434"
    />
    <path
      d="M26.9663 39.0002V31.3702L25.3562 31.9302L24.6562 30.3202L27.2463 29.2002H29.4862V39.0002H26.9663Z"
      fill="#343434"
    />
  </>
);

export const get2m = () => (
  <>
    <path
      d="M32.4609 39.0005V32.7285H34.4909L34.8409 32.8965C35.1116 32.8498 35.4149 32.8125 35.7509 32.7845C36.0963 32.7472 36.4649 32.7285 36.8569 32.7285H37.6129C37.8836 32.7285 38.1449 32.7658 38.3969 32.8405C38.6489 32.9058 38.8356 33.0038 38.9569 33.1345C39.1156 33.0505 39.2883 32.9805 39.4749 32.9245C39.6616 32.8592 39.8996 32.8125 40.1889 32.7845C40.4876 32.7472 40.8656 32.7285 41.3229 32.7285H42.0229C42.4896 32.7285 42.8489 32.7938 43.1009 32.9245C43.3529 33.0552 43.5303 33.2185 43.6329 33.4145C43.7449 33.6105 43.8103 33.8112 43.8289 34.0165C43.8569 34.2125 43.8709 34.3805 43.8709 34.5205V39.0005H41.3089V35.0525C41.3089 34.6792 41.0849 34.4925 40.6369 34.4925H39.8529C39.7036 34.4925 39.6009 34.5112 39.5449 34.5485C39.4889 34.5858 39.4609 34.6698 39.4609 34.8005V39.0005H36.8569V35.0525C36.8569 34.6792 36.6329 34.4925 36.1849 34.4925H35.4709C35.2936 34.4925 35.1769 34.5112 35.1209 34.5485C35.0743 34.5765 35.0509 34.6605 35.0509 34.8005V39.0005H32.4609Z"
      fill="#343434"
    />
    <path
      d="M31.2006 32.2802C31.2006 33.0735 30.8646 33.7595 30.1926 34.3382C29.9873 34.5155 29.7633 34.6929 29.5206 34.8702L26.5106 37.0402H31.2006V39.0002H23.6406V36.3402L28.6806 32.7002V32.0002C28.6806 31.4402 28.4006 31.1602 27.8406 31.1602H23.9206V29.2002H28.4006C29.3806 29.2002 30.09 29.4242 30.5286 29.8722C30.9766 30.3109 31.2006 31.0202 31.2006 32.0002V32.2802Z"
      fill="#343434"
    />
  </>
);

export const get5m = () => (
  <>
    <path
      d="M32.5312 39.0005V32.7285H34.5613L34.9112 32.8965C35.1819 32.8498 35.4853 32.8125 35.8213 32.7845C36.1666 32.7472 36.5352 32.7285 36.9272 32.7285H37.6833C37.9539 32.7285 38.2153 32.7658 38.4673 32.8405C38.7193 32.9058 38.9059 33.0038 39.0273 33.1345C39.1859 33.0505 39.3586 32.9805 39.5452 32.9245C39.7319 32.8592 39.9699 32.8125 40.2593 32.7845C40.5579 32.7472 40.9359 32.7285 41.3932 32.7285H42.0933C42.5599 32.7285 42.9193 32.7938 43.1713 32.9245C43.4233 33.0552 43.6006 33.2185 43.7033 33.4145C43.8153 33.6105 43.8806 33.8112 43.8993 34.0165C43.9273 34.2125 43.9412 34.3805 43.9412 34.5205V39.0005H41.3792V35.0525C41.3792 34.6792 41.1553 34.4925 40.7073 34.4925H39.9233C39.7739 34.4925 39.6712 34.5112 39.6152 34.5485C39.5592 34.5858 39.5312 34.6698 39.5312 34.8005V39.0005H36.9272V35.0525C36.9272 34.6792 36.7032 34.4925 36.2552 34.4925H35.5413C35.3639 34.4925 35.2472 34.5112 35.1912 34.5485C35.1446 34.5765 35.1213 34.6605 35.1213 34.8005V39.0005H32.5312Z"
      fill="#343434"
    />
    <path
      d="M28.7581 39.1402C27.1995 39.1402 25.4728 39.0469 23.5781 38.8602V36.9702C25.6408 37.1102 27.1808 37.1802 28.1981 37.1802C28.4128 37.1802 28.5808 37.1195 28.7021 36.9982C28.8328 36.8675 28.8981 36.6949 28.8981 36.4802V35.7802C28.8981 35.2202 28.6181 34.9402 28.0581 34.9402H23.9981V29.2002H30.7181V31.1602H26.5181V32.9802H28.7581C29.6728 32.9802 30.3448 33.1902 30.7741 33.6102C31.2035 34.0209 31.4181 34.6509 31.4181 35.5002V36.4802C31.4181 37.3949 31.2035 38.0669 30.7741 38.4962C30.3448 38.9255 29.6728 39.1402 28.7581 39.1402Z"
      fill="#343434"
    />
  </>
);

export const get10m = () => (
  <>
    <path
      d="M35.8594 38.9995V32.7275H37.8894L38.2394 32.8955C38.51 32.8489 38.8134 32.8115 39.1494 32.7835C39.4947 32.7462 39.8634 32.7275 40.2554 32.7275H41.0114C41.282 32.7275 41.5434 32.7649 41.7954 32.8395C42.0474 32.9049 42.234 33.0029 42.3554 33.1335C42.514 33.0495 42.6867 32.9795 42.8734 32.9235C43.06 32.8582 43.298 32.8115 43.5874 32.7835C43.886 32.7462 44.264 32.7275 44.7214 32.7275H45.4214C45.888 32.7275 46.2474 32.7929 46.4994 32.9235C46.7514 33.0542 46.9287 33.2175 47.0314 33.4135C47.1434 33.6095 47.2087 33.8102 47.2274 34.0155C47.2554 34.2115 47.2694 34.3795 47.2694 34.5195V38.9995H44.7074V35.0515C44.7074 34.6782 44.4834 34.4915 44.0354 34.4915H43.2514C43.102 34.4915 42.9994 34.5102 42.9434 34.5475C42.8874 34.5849 42.8594 34.6689 42.8594 34.7995V38.9995H40.2554V35.0515C40.2554 34.6782 40.0314 34.4915 39.5834 34.4915H38.8694C38.692 34.4915 38.5754 34.5102 38.5194 34.5475C38.4727 34.5755 38.4494 34.6595 38.4494 34.7995V38.9995H35.8594Z"
      fill="#343434"
    />
    <path
      d="M31.9431 31.8596C31.9431 31.2996 31.6631 31.0196 31.1031 31.0196H29.5631C29.0031 31.0196 28.7231 31.2996 28.7231 31.8596V36.3396C28.7231 36.8996 29.0031 37.1796 29.5631 37.1796H31.1031C31.6631 37.1796 31.9431 36.8996 31.9431 36.3396V31.8596ZM34.4631 36.3396C34.4631 37.3196 34.2391 38.0336 33.7911 38.4816C33.3525 38.9202 32.6431 39.1396 31.6631 39.1396H29.0031C28.0231 39.1396 27.3091 38.9202 26.8611 38.4816C26.4225 38.0336 26.2031 37.3196 26.2031 36.3396V31.8596C26.2031 30.8796 26.4225 30.1702 26.8611 29.7316C27.3091 29.2836 28.0231 29.0596 29.0031 29.0596H31.6631C32.6431 29.0596 33.3525 29.2836 33.7911 29.7316C34.2391 30.1702 34.4631 30.8796 34.4631 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M22.2788 38.9992V31.3692L20.6687 31.9292L19.9688 30.3192L22.5588 29.1992H24.7987V38.9992H22.2788Z"
      fill="#343434"
    />
  </>
);

export const get50m = () => (
  <>
    <path
      d="M37.8594 38.9995V32.7275H39.8894L40.2394 32.8955C40.51 32.8489 40.8134 32.8115 41.1494 32.7835C41.4947 32.7462 41.8634 32.7275 42.2554 32.7275H43.0114C43.282 32.7275 43.5434 32.7649 43.7954 32.8395C44.0474 32.9049 44.234 33.0029 44.3554 33.1335C44.514 33.0495 44.6867 32.9795 44.8734 32.9235C45.06 32.8582 45.298 32.8115 45.5874 32.7835C45.886 32.7462 46.264 32.7275 46.7214 32.7275H47.4214C47.888 32.7275 48.2474 32.7929 48.4994 32.9235C48.7514 33.0542 48.9287 33.2175 49.0314 33.4135C49.1434 33.6095 49.2087 33.8102 49.2274 34.0155C49.2554 34.2115 49.2694 34.3795 49.2694 34.5195V38.9995H46.7074V35.0515C46.7074 34.6782 46.4834 34.4915 46.0354 34.4915H45.2514C45.102 34.4915 44.9994 34.5102 44.9434 34.5475C44.8874 34.5849 44.8594 34.6689 44.8594 34.7995V38.9995H42.2554V35.0515C42.2554 34.6782 42.0314 34.4915 41.5834 34.4915H40.8694C40.692 34.4915 40.5754 34.5102 40.5194 34.5475C40.4727 34.5755 40.4494 34.6595 40.4494 34.7995V38.9995H37.8594Z"
      fill="#343434"
    />
    <path
      d="M33.9431 31.8596C33.9431 31.2996 33.6631 31.0196 33.1031 31.0196H31.5631C31.0031 31.0196 30.7231 31.2996 30.7231 31.8596V36.3396C30.7231 36.8996 31.0031 37.1796 31.5631 37.1796H33.1031C33.6631 37.1796 33.9431 36.8996 33.9431 36.3396V31.8596ZM36.4631 36.3396C36.4631 37.3196 36.2391 38.0336 35.7911 38.4816C35.3525 38.9202 34.6431 39.1396 33.6631 39.1396H31.0031C30.0231 39.1396 29.3091 38.9202 28.8611 38.4816C28.4225 38.0336 28.2031 37.3196 28.2031 36.3396V31.8596C28.2031 30.8796 28.4225 30.1702 28.8611 29.7316C29.3091 29.2836 30.0231 29.0596 31.0031 29.0596H33.6631C34.6431 29.0596 35.3525 29.2836 35.7911 29.7316C36.2391 30.1702 36.4631 30.8796 36.4631 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M24.8381 39.1402C23.2795 39.1402 21.5528 39.0469 19.6581 38.8602V36.9702C21.7208 37.1102 23.2608 37.1802 24.2781 37.1802C24.4928 37.1802 24.6608 37.1195 24.7821 36.9982C24.9128 36.8675 24.9781 36.6949 24.9781 36.4802V35.7802C24.9781 35.2202 24.6981 34.9402 24.1381 34.9402H20.0781V29.2002H26.7981V31.1602H22.5981V32.9802H24.8381C25.7528 32.9802 26.4248 33.1902 26.8541 33.6102C27.2835 34.0209 27.4981 34.6509 27.4981 35.5002V36.4802C27.4981 37.3949 27.2835 38.0669 26.8541 38.4962C26.4248 38.9255 25.7528 39.1402 24.8381 39.1402Z"
      fill="#343434"
    />
  </>
);

export const get100m = () => (
  <>
    <path
      d="M39.8594 38.9995V32.7275H41.8894L42.2394 32.8955C42.51 32.8489 42.8134 32.8115 43.1494 32.7835C43.4947 32.7462 43.8634 32.7275 44.2554 32.7275H45.0114C45.282 32.7275 45.5434 32.7649 45.7954 32.8395C46.0474 32.9049 46.234 33.0029 46.3554 33.1335C46.514 33.0495 46.6867 32.9795 46.8734 32.9235C47.06 32.8582 47.298 32.8115 47.5874 32.7835C47.886 32.7462 48.264 32.7275 48.7214 32.7275H49.4214C49.888 32.7275 50.2474 32.7929 50.4994 32.9235C50.7514 33.0542 50.9287 33.2175 51.0314 33.4135C51.1434 33.6095 51.2087 33.8102 51.2274 34.0155C51.2554 34.2115 51.2694 34.3795 51.2694 34.5195V38.9995H48.7074V35.0515C48.7074 34.6782 48.4834 34.4915 48.0354 34.4915H47.2514C47.102 34.4915 46.9994 34.5102 46.9434 34.5475C46.8874 34.5849 46.8594 34.6689 46.8594 34.7995V38.9995H44.2554V35.0515C44.2554 34.6782 44.0314 34.4915 43.5834 34.4915H42.8694C42.692 34.4915 42.5754 34.5102 42.5194 34.5475C42.4727 34.5755 42.4494 34.6595 42.4494 34.7995V38.9995H39.8594Z"
      fill="#343434"
    />
    <path
      d="M36.4666 31.8596C36.4666 31.2996 36.1866 31.0196 35.6266 31.0196H34.0866C33.5266 31.0196 33.2466 31.2996 33.2466 31.8596V36.3396C33.2466 36.8996 33.5266 37.1796 34.0866 37.1796H35.6266C36.1866 37.1796 36.4666 36.8996 36.4666 36.3396V31.8596ZM38.9866 36.3396C38.9866 37.3196 38.7626 38.0336 38.3146 38.4816C37.8759 38.9202 37.1666 39.1396 36.1866 39.1396H33.5266C32.5466 39.1396 31.8326 38.9202 31.3846 38.4816C30.9459 38.0336 30.7266 37.3196 30.7266 36.3396V31.8596C30.7266 30.8796 30.9459 30.1702 31.3846 29.7316C31.8326 29.2836 32.5466 29.0596 33.5266 29.0596H36.1866C37.1666 29.0596 37.8759 29.2836 38.3146 29.7316C38.7626 30.1702 38.9866 30.8796 38.9866 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M27.6462 31.8596C27.6462 31.2996 27.3662 31.0196 26.8062 31.0196H25.2662C24.7062 31.0196 24.4262 31.2996 24.4262 31.8596V36.3396C24.4262 36.8996 24.7062 37.1796 25.2662 37.1796H26.8062C27.3662 37.1796 27.6462 36.8996 27.6462 36.3396V31.8596ZM30.1663 36.3396C30.1663 37.3196 29.9423 38.0336 29.4943 38.4816C29.0556 38.9202 28.3463 39.1396 27.3663 39.1396H24.7063C23.7263 39.1396 23.0123 38.9202 22.5642 38.4816C22.1256 38.0336 21.9062 37.3196 21.9062 36.3396V31.8596C21.9062 30.8796 22.1256 30.1702 22.5642 29.7316C23.0123 29.2836 23.7263 29.0596 24.7063 29.0596H27.3663C28.3463 29.0596 29.0556 29.2836 29.4943 29.7316C29.9423 30.1702 30.1663 30.8796 30.1663 31.8596V36.3396Z"
      fill="#343434"
    />
    <path
      d="M18.5444 38.9992V31.3692L16.9344 31.9292L16.2344 30.3192L18.8244 29.1992H21.0644V38.9992H18.5444Z"
      fill="#343434"
    />
  </>
);
