import { ActionType, createReducer } from 'typesafe-actions';
import { IDiceStatistics } from './types';
import { setDiceStatistics, addNewRoundToStatistics } from './actions';

export const defaultDiceStatistics: IDiceStatistics = {
  roundResults: [],
};

const actions = {
  setDiceStatistics,
  addNewRoundToStatistics,
};

export const diceStatisticsReducer = createReducer<IDiceStatistics, ActionType<typeof actions>>(
  defaultDiceStatistics,
)
  .handleAction(setDiceStatistics, (state, { payload }) => ({
    ...state,
    roundResults: payload,
  }))
  .handleAction(addNewRoundToStatistics, (state, { payload }) => ({
    ...state,
    roundResults: [
      payload,
      ...(state.roundResults.length === 10 ? state.roundResults.slice(0, -1) : state.roundResults),
    ],
  }));
