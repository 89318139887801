import React from 'react';
import { Device } from 'types';
import { transformToCurrency } from 'utils/transformToCurrency';
import { BetValueInnerWrapper, BetValue, BetValueWrapper, ChipStyled, Wrapper } from './styles';
import { ISlotBetProps } from './types';

export const SlotBetMobile = ({ bet, x, y, isActiveJP, color, slotId }: ISlotBetProps) => (
  <Wrapper x={x} y={y} width="24" height="24" viewBox="0 0 24 24">
    <ChipStyled
      size={{ width: 24, height: 24 }}
      color={color}
      isActiveJP={isActiveJP}
      slotId={slotId}
    />
    <foreignObject x="-45" y="0" height="24" width="114" overflow="visible">
      <BetValueWrapper>
        <BetValueInnerWrapper>
          <BetValue $deviceType={Device.Mobile}>
            {transformToCurrency({ value: bet, showCurrency: false })}
          </BetValue>
        </BetValueInnerWrapper>
      </BetValueWrapper>
    </foreignObject>
  </Wrapper>
);
