import { isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import { isMobile } from 'react-device-detect';
import styled, { css, keyframes } from 'styled-components';
import { Device } from 'types';

const getDeltaHeight = (deviceType: Device) => {
  if (deviceType === Device.Mobile) {
    return isInIframe() ? 164 : 186;
  }

  if (deviceType === Device.Tablet_Portrait) {
    return isOldBrowserIOS() ? 230 : 210;
  }

  if (deviceType === Device.Tablet_Landscape) {
    return isInIframe() ? 230 : 350;
  }
  return 150;
};

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const SoundSettingsWrapper = styled.div``;

export const SoundSwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundSwitchToggle = styled.div``;

export const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SoundWrapper = styled.div`
  border-bottom: 1rem solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(270deg, #121011 0%, #434343 52.08%, #121011 100%);
  font-size: 12rem;
  padding-bottom: 40rem;
`;

export const SoundSlider = styled.div`
  margin-bottom: 40rem;
`;

const swapAnimation = keyframes`
  0% {

  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    position: absolute;
    top: 60rem;
    left: 24rem;
    width: calc(100% - 40rem);
  }

`;

const swapAnimationMobile = keyframes`
  0% {

  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    position: absolute;
    top: 24rem;
    left: 24rem;
    width: calc(100% - 40rem);
  }

`;

const swapAnimationList = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    opacity: 0;
    transform: translateY(30px); 
  }
  100% {
    opacity: 1;
    transform: translateY(60px);
  }

`;

export const ListContainer = styled.div<{ $hasShadow: boolean; $isReversed: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  animation: ${({ $isReversed }) => ($isReversed ? swapAnimationList : 'none')} 0.2s ease forwards;

  ${({ $hasShadow, $isReversed }) => {
    if ($hasShadow && !$isReversed) {
      return css`
        &::after {
          transition: 0.2s;
          content: '';
          background: linear-gradient(0deg, rgba(27, 25, 26, 0) 21.79%, #121011 100%);
          top: -5px;
          left: 0;
          position: absolute;
          z-index: 1000;
          height: 72px;
          width: 100%;

          @media only screen and (max-width: 450px) {
            height: 30px;
          }
        }
      `;
    }
  }}
`;

export const ButtonWrapper = styled.div<{ $isReversed: boolean; $deviceType?: Device }>`
  width: calc(100% + 14rem);
  margin-left: -2rem;
  margin-top: 24rem;

  ${({ $deviceType, $isReversed }) => {
    if ($deviceType === Device.Tablet_Landscape) {
      return css`
        animation: ${$isReversed ? swapAnimation : 'none'} 0.2s ease forwards;
      `;
    }
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        animation: ${$isReversed ? swapAnimation : 'none'} 0.2s ease forwards;
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        animation: ${$isReversed ? swapAnimationMobile : 'none'} 0.2s ease forwards;
        width: 100%;
      `;
    }
  }}

  margin-bottom: ${({ $isReversed }) => ($isReversed ? '20rem' : '0')};
  z-index: 10;
`;

export const LimitText = styled.div`
  color: var(--gray-434343, #434343);
  font-family: Goldman;
  font-size: 10rem;
  font-style: normal;
  font-weight: 400;
  line-height: 14rem;
  margin-top: 8rem;
`;

export const ChatContainerScroll = styled.div<{ $deviceType?: Device }>`
  padding: 0rem 12rem 0rem 0;
  z-index: 103;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
  overflow-scrolling: touch;
  overflow-x: hidden;

  .simplebar-scrollable-y {
    width: 100%;
  }

  .simplebar-scrollable-y {
    /* height: calc(100vh - 260rem) !important; */
  }

  .simplebar-track {
    right: -10px;
  }

  .simplebar-track.simplebar-vertical {
    width: 1px;
    background: #434343;

    .simplebar-scrollbar {
      width: 3px;
      border-radius: 2px;

      background-color: #d4bb93;
    }
  }

  &::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 2px;

    background-color: #d4bb93;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background: #434343;
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Landscape) {
      return css`
        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
      `;
    }
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        width: calc(100% + 3rem);

        & > div {
          width: 100%;
        }
        .simplebar-content {
          height: 100%;
        }

        .simplebar-scrollable-x {
          width: 100%;
        }
        /* padding: 0rem 16rem 0rem 16rem;
        margin-right: 12rem;
        margin-top: 16rem;
        margin-bottom: 24rem; */

        &::-webkit-scrollbar-thumb {
          width: 2px;
        }
        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 2px;
        }

        & p {
          font-size: 10rem;
        }

        & h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: 12rem;
        }

        & ul {
          font-size: 10rem;
        }
      `;
    }
  }}
`;

export const ChatWrapper = styled.div<{
  $isColumnReverse: boolean;
  $hasShadow: boolean;
  $deviceType: Device;
}>`
  opacity: ${({ $hasShadow }) => ($hasShadow ? '0' : '1')};

  .bar_scroll {
    ${({ $deviceType }) => {
      if ($deviceType === Device.Tablet_Landscape) {
        return css`
          height: ${`calc(100vh - ${getDeltaHeight($deviceType)}rem) ! important`};
        `;
      }

      if ($deviceType === Device.Mobile) {
        return css`
          height: ${`calc(600rem - ${getDeltaHeight($deviceType)}rem) ! important`};
        `;
      }
    }}
  }
`;
