import React, { forwardRef } from 'react';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { Device } from 'types';
import { BasePopupLimits } from './BasePopupLimits';
import { IPopupLimitsProps } from './types';

export const PopupLimits = forwardRef((props: IPopupLimitsProps, ref) => (
  <>
    <DesktopComponent>
      <BasePopupLimits {...props} deviceType={Device.Desktop} ref={ref} />
    </DesktopComponent>
    <TabletLandscapeComponent>
      <BasePopupLimits {...props} deviceType={Device.Tablet_Landscape} ref={ref} />
    </TabletLandscapeComponent>
    <TabletPortraitComponent>
      <BasePopupLimits {...props} deviceType={Device.Tablet_Portrait} ref={ref} />
    </TabletPortraitComponent>
    <MobileComponent>
      <BasePopupLimits {...props} deviceType={Device.Mobile} ref={ref} />
    </MobileComponent>
  </>
));
