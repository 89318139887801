import { changeRoundStateAction } from 'core/widgets/RoundStates/actions';
import { put, select } from 'redux-saga/effects';
import { Notifications } from 'types';
import { saveWinnerBallToStoreAction } from 'core/widgets/WinnerBall/actions';
import { updateBallsCounterAction } from 'core/widgets/Scoreboard/BallsCounter/actions';
import { addScoreboardItemAction } from 'core/widgets/Scoreboard/Boards/actions';
import { clearAllBetsAction } from 'core/widgets/Bet/actions';
import { confirmedBetSelector } from 'core/widgets/Bet';
import { BetType } from 'core/widgets/Bet/types';
import { resetRepeatBetAction, saveRoundBetAction } from 'core/widgets/BetRepeat/actions';
import { resetDoubleBetAction } from 'core/widgets/BetDouble/actions';
import { setTotalBetAction } from 'core/widgets/User/actions';
import { RoundStates } from '../../RoundStates/types';
import { OnResultConfirmedData } from '../../WinnerBall/types';
import { saveDrawnDicesAction } from 'core/widgets/DrawnDices/actions';
import { ResultDice } from 'components/Dice/types';
import { addNewRoundToStatistics } from 'core/widgets/DiceStatistics/actions';
import { clearAllDiceBetsAction } from 'core/widgets/DiceBet/actions';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType } from 'constants/common';
import { confirmedDiceBetSelector } from 'core/widgets/DiceBet';

export function* onResultConfirmedSaga({
  type: notificationType,
  data: result,
}: OnResultConfirmedData) {
  if (notificationType === Notifications.RESULT_CONFIRMED) {
    const { winner, winners } = result;
    const confirmedBets: BetType[] = yield select(confirmedBetSelector);
    const confirmedDiceBets: BetType[] = yield select(confirmedDiceBetSelector); // @TODO HHH
    const gameType: GameType = yield select(gameTypeSelector); // @TODO HHH

    yield put(setTotalBetAction(0));

    if (gameType === GameType.BAC) {
      yield put(saveRoundBetAction(confirmedBets));
    }

    yield put(resetDoubleBetAction());
    yield put(resetRepeatBetAction());
    yield put(saveWinnerBallToStoreAction({ winner }));

    yield put(changeRoundStateAction({ roundState: RoundStates.ResultConfirmed }));
    yield put(updateBallsCounterAction({ winnerBall: winner }));
    yield put(addScoreboardItemAction({ winnerBall: winner }));

    if (gameType === GameType.HHH) {
      yield put(saveRoundBetAction(confirmedDiceBets));
      // @TODO HHH
      yield put(
        saveDrawnDicesAction({
          winners: [winners.firstDice, winners.secondDice, winners.thirdDice],
        }),
      );
      // @TODO HHH

      yield put(addNewRoundToStatistics(winners));
    }

    yield put(clearAllBetsAction());
    yield put(clearAllDiceBetsAction());
  }
}
