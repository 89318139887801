import { CHIPS_COLORS } from './colors';
import { ChipType } from '../types';
import { chipsMIN200k } from './chipsValues';

export const CHIPS_MIN_20k: ChipType[] = [
  {
    color: CHIPS_COLORS.BLUE,
    value: 20e3,
  },
  {
    color: CHIPS_COLORS.YELLOW,
    value: 50e3,
  },
  {
    color: CHIPS_COLORS.RED,
    value: 100e3,
  },
  {
    color: CHIPS_COLORS.VIOLET,
    value: 200e3,
  },
  ...chipsMIN200k,
];
