import { put } from 'redux-saga/effects';
import { ClientSideNotifications } from 'types';
import { socketСonnectedAction } from '../actions';

export function* onSocketConnectedSaga(notification: string) {
  if (notification === ClientSideNotifications.SOCKET_CONNECTED) {
    yield put(socketСonnectedAction());
  }
}

export function* onSocketChatConnectedSaga(notification: string) {
  if (notification === ClientSideNotifications.SOCKET_CONNECTED) {
    yield;
  }
}
