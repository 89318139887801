import React, { createRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, isSafari, isTablet } from 'react-device-detect';

import { Device } from 'types';
import { IconNames } from 'components';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { setIsShowJackpotRuleAction } from 'core/widgets/GameRules/actions';
import { manageSettingsPanelAction } from 'core/widgets/SettingsPanel/actions';
import { ButtonsWrapper, ContentWrapper, MobileScreenBackground, Wrapper, Overlay } from './styles';
import { ISettingsProps, SettingPanels } from './types';
import { ButtonSettings } from './components';
import AudioService from 'services/AudioService';
import { ISoundSettingsState } from 'core/widgets/SoundSettings/types';

export const BaseSettings = ({
  children,
  openedPanel,
  isFullScreen,
  deviceType = Device.Desktop,
  onSettingsButtonClick,
  onFullScreenClick,
}: ISettingsProps) => {
  const isIPadNonSafari = !isSafari && isIOS && isTablet;
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
  const { jackpotOn } = useSelector(authInfoSelector);
  const dispatch = useDispatch();

  const onClickButton = (value: SettingPanels) => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }

    onSettingsButtonClick(value);
  };

  const onClickFullScreen = () => {
    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('click', soundEffects.volume);
    }
    onFullScreenClick();
  };

  const popupRef2 = createRef() as any;

  const onCloseClick = useCallback(() => {
    dispatch(manageSettingsPanelAction({ settingsPanel: null }));

    dispatch(setIsShowJackpotRuleAction(false));
  }, [dispatch]);

  return (
    <MobileScreenBackground $isOpened={openedPanel !== null && deviceType === Device.Mobile}>
      {(deviceType === Device.Mobile || deviceType === Device.Tablet_Portrait) && (
        <Overlay
          $isOpened={
            openedPanel !== null &&
            (deviceType === Device.Mobile || deviceType === Device.Tablet_Portrait)
          }
          onClick={onCloseClick}
        />
      )}
      <Wrapper
        ref={popupRef2}
        $isOpened={
          openedPanel !== null &&
          openedPanel !== SettingPanels.Rules &&
          openedPanel !== SettingPanels.History &&
          openedPanel !== SettingPanels.BetLimits
        }
        $deviceType={deviceType}>
        {(deviceType === Device.Desktop || deviceType === Device.Tablet_Landscape) && (
          <ButtonsWrapper $hasJP={jackpotOn}>
            <ButtonSettings
              isActive={openedPanel === SettingPanels.Sound}
              variant={gameSoundMute ? IconNames.NoSound : IconNames.Sound}
              onClick={() => onClickButton(SettingPanels.Sound)}
            />
            <ButtonSettings
              isActive={openedPanel === SettingPanels.Video}
              variant={IconNames.Video}
              onClick={() => onClickButton(SettingPanels.Video)}
            />
            <ButtonSettings
              isActive={openedPanel === SettingPanels.General}
              variant={IconNames.General}
              onClick={() => onClickButton(SettingPanels.General)}
            />
            <ButtonSettings
              isActive={openedPanel === SettingPanels.Rules}
              variant={IconNames.GameRule}
              onClick={() => onClickButton(SettingPanels.Rules)}
            />
            {jackpotOn && (
              <ButtonSettings
                isActive={openedPanel === SettingPanels.Winners}
                variant={IconNames.Winners}
                onClick={() => onClickButton(SettingPanels.Winners)}
              />
            )}
            <ButtonSettings
              isActive={openedPanel === SettingPanels.History}
              variant={IconNames.History}
              onClick={() => onClickButton(SettingPanels.History)}
            />
            <ButtonSettings
              isActive={openedPanel === SettingPanels.Chat}
              variant={IconNames.Chat}
              onClick={() => onClickButton(SettingPanels.Chat)}
            />
            {!isIPadNonSafari && (
              <ButtonSettings
                isActive={isFullScreen}
                variant={isFullScreen ? IconNames.FullscreenOn : IconNames.FullscreenOff}
                onClick={onClickFullScreen}
              />
            )}
          </ButtonsWrapper>
        )}
        <ContentWrapper $deviceType={deviceType}>{children}</ContentWrapper>
      </Wrapper>
    </MobileScreenBackground>
  );
};
