import React, { forwardRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Device } from 'types';
import { PopupContainer } from 'components/PopupContainer';
import { Icon, IconNames } from 'components/Icon';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import AudioService from 'services/AudioService';
import {
  LimitsWrapper,
  TableContainer,
  CloseButton,
  Head,
  HeadTable,
  HeadRow,
  Box,
  ItemValue,
  TableRow,
  TableContent,
} from './styles';
import { IDevicePopupLimitsProps } from './types';
import { tableDiceBetLimitSelector } from 'core/widgets/TableSettings';
import { authInfoSelector } from 'core/widgets/Auth/selectors';
import { transformToCurrencyDecimal } from 'utils/transformToCurrency';

export const BasePopupLimits = forwardRef(
  ({ onClose, deviceType = Device.Desktop }: IDevicePopupLimitsProps, ref) => {
    const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);
    const tableLimits = useSelector(tableDiceBetLimitSelector);
    const { currency } = useSelector(authInfoSelector);

    const onCloseAudio = useCallback(() => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('click', soundEffects.volume);
      }
    }, [gameSoundMute, soundEffects]);

    useEffect(() => {
      if (!gameSoundMute && !soundEffects.mute) {
        AudioService.playClickSound('alert', soundEffects.volume);
      }
    }, [gameSoundMute, soundEffects]);

    const closeModalPopup = () => {
      onCloseAudio();
      if (onClose) {
        onClose();
      }
    };

    return (
      <PopupContainer deviceType={deviceType}>
        <LimitsWrapper $deviceType={deviceType} ref={ref as any}>
          <Head $deviceType={deviceType}>holi hhh Limits</Head>
          {onClose && (
            <CloseButton onClick={closeModalPopup}>
              <Icon name={IconNames.Cross} />
            </CloseButton>
          )}
          <HeadTable $deviceType={deviceType}>
            <HeadRow $deviceType={deviceType}>
              <Box $width={40}>
                <ItemValue>Bet</ItemValue>
              </Box>
              <Box $width={36}>
                <ItemValue>Min Limit</ItemValue>
              </Box>
              <Box $width={26}>
                <ItemValue>Max Limit</ItemValue>
              </Box>
            </HeadRow>
          </HeadTable>
          <TableContainer $deviceType={deviceType}>
            <TableContent $deviceType={deviceType}>
              <TableRow>
                <Box $width={40}>
                  <ItemValue>Color Bet</ItemValue>
                </Box>
                <Box $width={36}>
                  <ItemValue>
                    {transformToCurrencyDecimal({
                      value: tableLimits?.colorMinBet || 0,
                      showCurrency: true,
                      currency,
                    })}
                  </ItemValue>
                </Box>
                <Box $width={26}>
                  <ItemValue>
                    {transformToCurrencyDecimal({
                      value: tableLimits?.colorMaxBet || 0,
                      showCurrency: true,
                      currency,
                    })}
                  </ItemValue>
                </Box>
              </TableRow>
              <TableRow>
                <Box $width={40}>
                  <ItemValue>Triple Bet</ItemValue>
                </Box>
                <Box $width={36}>
                  <ItemValue>
                    {transformToCurrencyDecimal({
                      value: tableLimits?.tripleMinBet || 0,
                      showCurrency: true,
                      currency,
                    })}
                  </ItemValue>
                </Box>
                <Box $width={26}>
                  <ItemValue>
                    {transformToCurrencyDecimal({
                      value: tableLimits?.tripleMaxBet || 0,
                      showCurrency: true,
                      currency,
                    })}
                  </ItemValue>
                </Box>
              </TableRow>
              <TableRow>
                <Box $width={40}>
                  <ItemValue>Total Bet</ItemValue>
                </Box>
                <Box $width={36}>
                  <ItemValue>
                    {transformToCurrencyDecimal({
                      value: tableLimits?.totalMinBet || 0,
                      showCurrency: true,
                      currency,
                    })}
                  </ItemValue>
                </Box>
                <Box $width={26}>
                  <ItemValue>
                    {transformToCurrencyDecimal({
                      value: tableLimits?.totalMaxBet || 0,
                      showCurrency: true,
                      currency,
                    })}
                  </ItemValue>
                </Box>
              </TableRow>
            </TableContent>
          </TableContainer>
        </LimitsWrapper>
      </PopupContainer>
    );
  },
);
