import React, { useMemo } from 'react';
import { Device } from 'types';
import { Dice } from 'components/Dice';
import { ResultDice } from 'components/Dice/types';

import { getDiceColorsStatistics } from './utils';

import {
  DicePercentage,
  Wrapper,
  Text,
  TripleWrap,
  DiceWrap,
  Line,
  Triple,
  TriplePercentage,
} from './styles';
import { IDeviceBaseWinPercentageProps } from './types';

export const BaseWinPercentage = ({
  deviceType = Device.Desktop,
  diceData,
}: IDeviceBaseWinPercentageProps) => {
  const { colorPercentages, triplePercentage } = useMemo(
    () => getDiceColorsStatistics(diceData),
    [diceData],
  );
  return (
    <Wrapper $deviceType={deviceType}>
      {Object.entries(colorPercentages).map(([color, percentage], index) => (
        <>
          <DicePercentage $deviceType={deviceType}>
            <DiceWrap>
              <Dice value={color as ResultDice} />
            </DiceWrap>
            <Text>{percentage as string}</Text>
          </DicePercentage>
          {index === 3 && deviceType != Device.Mobile && <Line />}
        </>
      ))}

      <TriplePercentage $deviceType={deviceType}>
        <TripleWrap>
          <Triple $deviceType={deviceType}>TRIPLE</Triple>
        </TripleWrap>
        <Text>{triplePercentage}</Text>
      </TriplePercentage>
    </Wrapper>
  );
};
