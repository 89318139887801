import { WinnerBall } from 'constants/common';
import { colors } from 'constants/theme';
import { isIOS } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import { WinnerDices } from 'widgets/DiceWinStatus/types';

export const Text = styled.p`
  font-weight: 400;
  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `}
`;

export const HeadRow = styled.div<{ $deviceType?: Device }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--clash-gold-bb-9370, #bb9370);
  top: 0;
  left: 0;
  height: 100%;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        min-height: 34rem;
        padding: 11rem 16rem;
        min-height: 38rem;

        position: absolute;
        top: 10rem;
        left: 0;
        z-index: 104;

        background: #131011;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        z-index: 1;
        padding: 10rem 16rem;
        margin-left: -0.6px;
      `;
    }
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        min-height: 25rem;
        padding: 11rem 16rem;

        /* top: 0; */
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        min-height: 25rem;
        padding: 8rem 8rem;
        height: 100%;
        font-size: 6rem;
        /* top: 0; */
      `;
    }
  }}
`;

export const HistoryWrapper = styled.div`
  height: 1px;
  width: 100%;

  &::after {
    content: '';
    background: linear-gradient(180deg, #1b191a00 21.79%, #1b191a 70%, #1b191a 100%);
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 1000;
    height: 64px;
    width: 100%;

    @media only screen and (max-width: 450px) {
      height: 56px;
    }
  }
`;

export const ItemValue = styled.p`
  color: ${colors.SlotText};
  font-size: 10rem;
  position: relative;
  padding: 0 14rem 0 0;
  word-break: break-word;

  @media only screen and (max-width: 7680px) {
    padding: 0 12rem 0 0;
  }
`;

export const HeadTable = styled.div<{ $deviceType?: Device }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: #131011;

  ${ItemValue} {
    color: #e7d5b9;
    font-size: 10rem;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        padding: 0rem 32rem 0rem 32rem;
        width: calc(100% - 104rem - 1px);
        margin: 24rem 25rem 0 0;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;

        min-height: 38rem;
        position: relative;
        cursor: pointer;
        z-index: 104;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        ${ItemValue} {
          font-size: 5rem;
        }
      `;
    }
  }}
`;

export const Wrapper = styled.div<{ $deviceType?: Device }>`
  overflow-scrolling: touch;
  margin: 40rem 0 20px;
  width: 100%;
  border-bottom: 1px solid var(--clash-gold-bb-9370, #bb9370);

  @media only screen and (max-width: 650px) {
    margin: 25rem 0 20px;
  }

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait || $deviceType === Device.Mobile) {
      return css`
        margin: 36rem 0 20px;
      `;
    }
  }}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Slots2 = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0 10rem;
  justify-content: flex-start;

  &:last-of-type {
    padding: 0;
  }
`;

export const ArrowWrapper = styled.div<{ $isRotate: boolean }>`
  ${({ $isRotate }) => css`
    svg {
      transition: transform 0.3s linear;
      transform: ${$isRotate ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
  `};
`;

export const ListItem = styled.div<{ $isGray: boolean; $disable: boolean; $deviceType?: Device }>`
  width: 100%;
  /* min-height: 58rem; */
  font-style: normal;
  font-weight: 400;
  padding: 10rem 16rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  border-right: 1px solid var(--clash-gold-bb-9370, #bb9370);
  border-left: 1px solid var(--clash-gold-bb-9370, #bb9370);

  ${({ $isGray }) => css`
    background: ${$isGray ? colors.MediumDark : '#1B191A'};
  `};

  ${({ $deviceType }) => css`
    padding: ${$deviceType === Device.Mobile ? '8rem 8rem' : '10rem 16rem'};
  `};

  position: relative;
  cursor: pointer;

  ${({ $disable, $deviceType }) => {
    if ($disable) {
      return css`
        align-items: baseline;
        &::before {
          content: '';
          background: #434343;
          top: 0;
          position: absolute;
          z-index: 1;
          height: 1px;
          width: ${$deviceType === Device.Mobile ? 'calc(100% - 16rem)' : 'calc(100% - 32rem)'};
          left: ${$deviceType === Device.Mobile ? '8rem' : '16rem'};
        }
      `;
    }
  }};

  ${({ $deviceType }) => {
    if ($deviceType === Device.Mobile) {
      return css`
        svg {
          width: 8px;
          height: 8px;
          position: relative;
          left: 0;
          top: 4px;
        }
      `;
    }
  }};
`;

export const ItemGradient = styled.span`
  background: -webkit-linear-gradient(#fff 30%, #b900fa 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const ItemNickname = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10rem;
`;

export const Divider = styled.div`
  color: var(--light-gold-e-7-d-5-b-9, #e7d5b9);
  font-size: 10px;
  font-weight: 700;
  line-height: 18px; /* 180% */
  text-transform: uppercase;
  border-top: 1px solid var(--clash-gold-bb-9370, #bb9370);
  border-bottom: 1px solid var(--clash-gold-bb-9370, #bb9370);
  background: var(--dark-1-b-191-a, #1b191a);
  padding: 6rem 10rem 6rem;
  margin-top: 18rem;
`;

export const Box = styled.div<{ $width: number }>`
  ${({ $width }) => css`
    width: ${`${$width}%`};
  `};

  svg {
    height: 12rem;
    width: 12rem;

    @media only screen and (max-width: 760px) {
      height: 9rem;
      width: 8rem;
    }
  }
`;

export const ItemType = styled.span`
  color: ${colors.LightGold};

  font-style: normal;
`;

export const SlotValue = styled.p<{
  slot: string;
}>`
  text-transform: capitalize;
  ${({ slot }) => css`
    color: ${() => {
      if (slot === WinnerBall.BLUE) {
        return '#41B6E6';
      }
      if (slot === WinnerBall.GOLD) {
        return '#FEDB00';
      }
      if (slot === WinnerBall.RED) {
        return '#FF163C';
      }
      if (slot === WinnerDices.ORANGE) {
        return '#FF6C2F';
      }
      if (slot === WinnerDices.YELLOW) {
        return '#FFCA41';
      }
      if (slot === WinnerDices.GREEN) {
        return '#A8D32C';
      }
      if (slot === WinnerDices.PURPLE) {
        return '#A361F4';
      }
      return '#FFFFFF';
    }};
  `};
`;

export const Slots = styled.p`
  display: flex;
`;

export const SlotsWrapper = styled.p`
  display: flex;
  flex-wrap: wrap;
`;

export const WinLoss = styled.p<{ $isWin: boolean; $isZero: boolean }>`
  ${({ $isWin, $isZero }) => css`
    color: ${$isZero ? colors.SlotText : $isWin ? colors.LightGreen : colors.Ruby};
  `};
`;
