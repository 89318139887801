import React from 'react';

import { ResultDice } from './types';
import { DiceStyled } from './styles';

export interface IProps {
  value: ResultDice;
  className?: string;
}

export const Dice = ({ value, className }: IProps) => (
  <DiceStyled value={value} className={className} />
);
