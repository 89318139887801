import React from 'react';

import { Device, DICE_SLOTS } from 'types';
import { IDeviceDiceSlotProps } from './types';
import { Wrapper } from './styles';
import { DiceSlotContent, IDiceSlotInnerPositionsConfig } from '../DiceSlotContent';

export const DiceSlotBase = ({
  deviceType = Device.Desktop,
  isMinimized,
  hasPlacedBet,
  slot,
  bet,
  activeChip,
  winningAmount,
  isBettingTime,
  isActiveJP,
  onClick,
  onBetConfirm,
  onBetClear,
  onErrorClick,
}: IDeviceDiceSlotProps) => {
  const innerContentPositions = (): IDiceSlotInnerPositionsConfig => {
    if (deviceType === Device.Tablet_Portrait) {
      return {
        betValue: {
          positionX: isMinimized ? '14rem' : '40rem',
          positionY: '30rem',
        },
        confirmBet: {
          positionX: '-14rem',
          positionY: '2rem',
        },
      };
    }

    if (deviceType === Device.Mobile) {
      return {
        betValue: {
          positionX: isMinimized ? '6rem' : '14rem',
          positionY: slot === DICE_SLOTS.triple ? '62rem' : '22rem',
          size: 26,
        },
        confirmBet: {
          positionX: '-12rem',
          positionY: slot === DICE_SLOTS.triple ? '36rem' : '-4rem',
        },
      };
    }

    return {
      betValue: {
        positionX: '20rem',
        positionY: '22rem',
      },
      confirmBet: {
        positionX: '4rem',
        positionY: '2rem',
      },
    };
  };

  return (
    <Wrapper
      $isBettingTime={isBettingTime}
      $activeChip={activeChip}
      onClick={isBettingTime ? onClick : onErrorClick}>
      <DiceSlotContent
        bet={bet}
        onBetConfirm={onBetConfirm}
        onBetClear={onBetClear}
        slot={slot}
        deviceType={deviceType}
        hasPlacedBet={hasPlacedBet}
        innerPositionsConfig={innerContentPositions()}
        isActiveJP={isActiveJP}
        isBettingTime={isBettingTime}
        winningAmount={winningAmount}
      />
    </Wrapper>
  );
};
