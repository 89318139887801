import React, { useMemo } from 'react';
import { Device } from 'types';
import { Dice } from 'components/Dice';
import { ResultDice } from 'components/Dice/types';

import {
  DicesColumn,
  Wrapper,
  DiceWrap,
  LastWinContainer,
  RestWinsContainer,
  Line,
} from './styles';
import { IDeviceBaseWinHistoryProps } from './types';

export const BaseWinHistory = ({
  deviceType = Device.Desktop,
  diceData,
}: IDeviceBaseWinHistoryProps) => {
  const lastWin = useMemo(() => diceData[0], [diceData]);
  const restWins = useMemo(() => diceData.slice(1), [diceData]);

  return (
    <Wrapper $deviceType={deviceType}>
      <LastWinContainer $hasDices={Boolean(lastWin?.firstDice)}>
        <DicesColumn>
          {lastWin && (
            <>
              <DiceWrap>
                <Dice value={lastWin?.firstDice as ResultDice} />
              </DiceWrap>
              <DiceWrap>
                <Dice value={lastWin?.secondDice as ResultDice} />
              </DiceWrap>
              <DiceWrap>
                <Dice value={lastWin?.thirdDice as ResultDice} />
              </DiceWrap>
            </>
          )}
        </DicesColumn>
      </LastWinContainer>
      <RestWinsContainer>
        {restWins &&
          restWins.map((diceColors, index) => (
            <>
              {index != 0 && deviceType != Device.Mobile && <Line />}
              <DicesColumn>
                {[diceColors.firstDice, diceColors.secondDice, diceColors.thirdDice].map(
                  (color) => (
                    <DiceWrap>
                      <Dice value={color as ResultDice} />
                    </DiceWrap>
                  ),
                )}
              </DicesColumn>
            </>
          ))}
      </RestWinsContainer>
    </Wrapper>
  );
};
