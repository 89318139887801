import React from 'react';
import { Trans } from 'react-i18next';
import { Loader } from 'components';
import { Device } from 'types';
import { useSelector } from 'react-redux';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { LoaderWrapper, TryingToReload, Wrapper } from './styles';
import { IDeviceVideoStreamProps } from './types';

export const BaseVideoStream = ({
  id,
  isLoading,
  isTryingToReload,
  deviceType = Device.Desktop,
}: IDeviceVideoStreamProps) => {
  const gameType = useSelector(gameTypeSelector);

  return (
    <Wrapper id={id} $deviceType={deviceType}>
      {isTryingToReload && (
        <TryingToReload>
          <Trans
            i18nKey="stream.supportText"
            components={{
              break: deviceType === Device.Mobile ? <br /> : <span />,
            }}
          />
        </TryingToReload>
      )}
      {isLoading && !isTryingToReload ? (
        <LoaderWrapper $gameType={gameType}>
          <Loader />
        </LoaderWrapper>
      ) : null}
    </Wrapper>
  );
};
