import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Device } from 'types';
import {
  DesktopComponent,
  MobileComponent,
  TabletLandscapeComponent,
  TabletPortraitComponent,
} from 'components';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import {
  AspectRatioIphone,
  getAspectRatioIphone,
  isInIframe,
  isOldBrowserIOS,
} from 'hooks/getDefineDevEnv';

import { BaseDiceSlots } from './DiceSlots';
import { IDiceSlotsComponentProps } from './types';

export const DiceSlotsResponsive = (props: IDiceSlotsComponentProps) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);

  return (
    <>
      <DesktopComponent>
        <SlotsWrapperLandscape>
          <BaseDiceSlots {...props} deviceType={Device.Desktop} />
        </SlotsWrapperLandscape>
      </DesktopComponent>
      <TabletLandscapeComponent>
        <SlotsWrapperLandscape>
          <BaseDiceSlots {...props} deviceType={Device.Tablet_Landscape} />
        </SlotsWrapperLandscape>
      </TabletLandscapeComponent>
      <TabletPortraitComponent>
        <SlotsPortraitWrapper $isMinimized={isScoreboardOpen} $deviceType={Device.Tablet_Portrait}>
          <SlotsPortraitContentWrapper>
            <BaseDiceSlots {...props} deviceType={Device.Tablet_Portrait} />
          </SlotsPortraitContentWrapper>
        </SlotsPortraitWrapper>
      </TabletPortraitComponent>
      <MobileComponent>
        <SlotsPortraitWrapper $isMinimized={isScoreboardOpen} $deviceType={Device.Mobile}>
          <SlotsPortraitContentWrapper>
            <BaseDiceSlots {...props} deviceType={Device.Mobile} />
          </SlotsPortraitContentWrapper>
        </SlotsPortraitWrapper>
      </MobileComponent>
    </>
  );
};

const SlotsWrapperLandscape = styled.div`
  position: absolute;
  bottom: 134rem;
  left: 50%;
  width: 640rem;
  transform: translateX(-50%);
`;

const SlotsPortraitContentWrapper = styled.div`
  transform: ${isOldBrowserIOS() && getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
    ? 'scale(0.96)'
    : 'none'};
`;

const SlotsPortraitWrapper = styled.div<{ $isMinimized: boolean; $deviceType: Device }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme: { background } }) => css`
    background: ${background.slotsMobileDefault};
  `}

  ${({ $deviceType, $isMinimized }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        height: ${$isMinimized
          ? isInIframe()
            ? '262rem'
            : '276rem'
          : isInIframe()
            ? '372rem'
            : '406rem'};

        ${SlotsPortraitContentWrapper} {
          width: ${isInIframe() ? '96%' : '722rem'};
          height: ${$isMinimized
            ? isInIframe()
              ? '235rem'
              : '245rem'
            : isInIframe()
              ? '340rem'
              : '375rem'};
        }
      `;
    }

    if ($deviceType === Device.Mobile) {
      if (isOldBrowserIOS() && isInIframe()) {
        return css`
          height: ${$isMinimized ? '132rem' : '264rem'};

          ${SlotsPortraitContentWrapper} {
            width: 346rem;
            height: ${$isMinimized ? '114rem' : '260rem'};
          }
        `;
      }
      return css`
        height: ${isOldBrowserIOS()
          ? $isMinimized
            ? '127rem'
            : getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
              ? '245rem'
              : '275rem'
          : $isMinimized
            ? '200rem'
            : '275rem'};

        ${SlotsPortraitContentWrapper} {
          width: 346rem;
          height: ${isOldBrowserIOS()
            ? $isMinimized
              ? '114rem'
              : getAspectRatioIphone() === AspectRatioIphone.RATIO_16_9
                ? '241rem'
                : '260rem'
            : $isMinimized
              ? '194rem'
              : '260rem'};
        }
      `;
    }
  }}
`;
