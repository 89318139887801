import { ToastDisplayTime, ToastMessagesType } from 'types/toast';

export const roundStatusMessages: ToastMessagesType = {
  BETTING_TIME_STARTED: {
    text: 'roundStatus.placeBetMessage',
    displayTime: ToastDisplayTime.ROUND_STATUS,
  },
  BETTING_TIME_FINISHED: {
    text: 'roundStatus.noMoreBetsMessage',
    displayTime: ToastDisplayTime.ROUND_STATUS,
  },
  DRAW_FINISHED: {
    text: 'roundStatus.ballWasDrawnMessage',
    displayTime: ToastDisplayTime.ROUND_STATUS,
  },
  DRAW_FINISHED_DICE: {
    text: 'roundStatus.diceWereDrawnMessage',
    displayTime: ToastDisplayTime.ROUND_STATUS,
  },
};

export const betMessages: ToastMessagesType = {
  ACCEPTED: {
    text: 'bets.accepted',
    displayTime: ToastDisplayTime.BETS_VALIDATION,
  },
  MIN_BET_REACHED: {
    text: 'bets.minLimitReached',
    displayTime: ToastDisplayTime.BETS_VALIDATION,
  },
  MAX_BET_REACHED: {
    text: 'bets.maxLimitReached',
    displayTime: ToastDisplayTime.BETS_VALIDATION,
  },
  LOW_BALANCE: {
    text: 'bets.lowBalance',
    displayTime: ToastDisplayTime.BETS_VALIDATION,
  },
};
