import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmptySlotsSelector } from 'core/widgets/Bet';
import { betDoubleRootSelector } from 'core/widgets/BetDouble';
import { doubleBetAction } from 'core/widgets/BetDouble/actions';
import { roundStateSelector } from 'core/widgets/RoundStates';
import { RoundStates } from 'core/widgets/RoundStates/types';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import { DoubleBetButtonResponsive } from './Responsive';
import AudioService from 'services/AudioService';
import { isEmptyDiceSlotsSelector } from 'core/widgets/DiceBet';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType } from 'constants/common';

export const DoubleBetButton = () => {
  const dispatch = useDispatch();
  const roundState = useSelector(roundStateSelector);
  // const { isUsed: isDoubleBetUsed, isConfirmed: isDoubleBetConfirmed } =
  //   useSelector(betDoubleRootSelector);
  const isEmptySlots = useSelector(isEmptySlotsSelector);
  const isEmptyDiceSlots = useSelector(isEmptyDiceSlotsSelector); // @TODO HHH
  const { gameSoundMute, soundEffects } = useSelector(soundSettingsSelector);

  const gameType = useSelector(gameTypeSelector);
  const isEmpty = gameType === GameType.BAC ? isEmptySlots : isEmptyDiceSlots;

  const isDoubleBetAvailable: boolean = useMemo(() => {
    if (roundState !== RoundStates.BettingTimeStarted) {
      return false;
    }

    // if (isDoubleBetUsed || isDoubleBetConfirmed) {
    //   return false;
    // }

    if (isEmpty) {
      return false;
    }

    return true;
  }, [roundState, isEmpty]);

  const onDoubleBetClick = useCallback(() => {
    if (isDoubleBetAvailable) {
      dispatch(doubleBetAction());
    }

    if (isDoubleBetAvailable && !gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('confirm', soundEffects.volume);
      return;
    }

    if (!gameSoundMute && !soundEffects.mute) {
      AudioService.playClickSound('error', soundEffects.volume);
    }
  }, [dispatch, isDoubleBetAvailable, gameSoundMute, soundEffects]);

  return (
    <>
      <DoubleBetButtonResponsive
        onDoubleBetClick={onDoubleBetClick}
        isDisabled={!isDoubleBetAvailable}
      />
    </>
  );
};
