// import { isIOS } from 'react-device-detect';
import { isInIframe, isOldBrowserIOS } from 'hooks/getDefineDevEnv';
import styled, { css } from 'styled-components';
import { Device } from 'types';

export const MobileScreenBackground = styled.div<{ $isOpened: boolean }>`
  ${({ $isOpened }) => {
    if ($isOpened) {
      return css`
        position: absolute;
        z-index: 101;
        max-width: 100vw;
        width: 100%;
        /* width: calc(124dvh * 3 / 5); */
        /* width: ${isOldBrowserIOS() ? 'calc(100vh * 3 / 5)' : 'calc(124dvh * 3 / 5)'}; */
        max-height: calc(100vw * 5 / 3);
        height: 100dvh;
        height: ${isOldBrowserIOS() ? (isInIframe() ? '100vh' : 'calc(100vh - 50px)') : '100dvh'};
        overflow: hidden;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        background-color: rgba(187, 147, 112, 0.4);

        /* @media only screen and (max-width: 390px) {
          transform: translateX(-56%);
        } */
      `;
    }
  }}
`;

const wrapperTransformConfig: Record<Device, string> = {
  [Device.Desktop]: '278rem',
  [Device.Tablet_Landscape]: '278rem',
  [Device.Tablet_Portrait]: '246rem',
  [Device.Mobile]: '272rem',
};

export const Wrapper = styled.div<{ $deviceType: Device; $isOpened: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: min-content;
  pointer-events: none;
  transform: translateX(${({ $deviceType }) => wrapperTransformConfig[$deviceType]});
  transition: transform 0.25s;
  z-index: 99;

  ${({ $isOpened }) => {
    if ($isOpened) {
      return css`
        transform: translateX(0);
      `;
    }
  }}
`;

export const ButtonsWrapper = styled.div<{ $hasJP: boolean }>`
  display: grid;
  align-content: flex-start;
  row-gap: ${({ $hasJP }) => ($hasJP ? '2rem' : '8rem')};
  margin: 24rem 24rem 0 0;

  & > * {
    pointer-events: auto;
  }
`;

const contentWidthConfig: Record<Device, string> = {
  [Device.Desktop]: '278rem',
  [Device.Tablet_Landscape]: '278rem',
  [Device.Tablet_Portrait]: '246rem',
  [Device.Mobile]: '272rem',
};

export const ContentWrapper = styled.div<{ $deviceType: Device }>`
  width: ${({ $deviceType }) => contentWidthConfig[$deviceType]};
  border: 1px solid;
  border-image-source: linear-gradient(
    270deg,
    rgba(241, 230, 210, 0) 0%,
    #f1e6d2 50%,
    rgba(241, 230, 210, 0) 100%
  );
  border-image-slice: 1;
  background: linear-gradient(180deg, #121011 40%, rgba(52, 52, 52, 0.2) 100%);
  pointer-events: auto;

  ${({ theme: { background } }) => css`
    background-color: ${background.primary};
  `}
`;

export const Overlay = styled.div<{ $isOpened: boolean }>`
  ${({ $isOpened }) => {
    if ($isOpened) {
      return css`
        position: absolute;
        z-index: 101;
        max-width: 100vw;
        width: 100%;
        height: ${isOldBrowserIOS() ? 'calc(124vh * 3 / 5)' : 'calc(124dvh * 3 / 5)'};
        max-height: calc(100vw * 5 / 3);
        height: 100dvh;
        height: ${isOldBrowserIOS() ? '100vh' : '100dvh'};
        overflow: hidden;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        /* background-color: rgba(137, 18, 112, 0.4); */
        z-index: 1;
      `;
    }
  }}
`;

export const SettingsWrapper = styled.div`
  height: 100%;
`;
