import { BetType, DiceBetType } from 'core/widgets/Bet/types';
import { getBetSum, getSummaryBet } from 'core/widgets/Bet/utils';
import { put, select } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { clearConfirmationSlotAction } from 'core/widgets/BetConfirmation/actions';
import { saveRoundBetAction, saveRoundBetSuccessAction } from '../actions';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { GameType } from 'constants/common';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';

export function* saveRoundBetSaga({
  payload: confirmedBets,
}: ActionType<typeof saveRoundBetAction>) {
  const gameType: GameType = yield select(gameTypeSelector);

  if (gameType === GameType.BAC) {
    // @TODO HHH
    const bets = confirmedBets as BetType[];
    const betSum = confirmedBets.length ? getBetSum(bets) : 0;
    const roundBet: BetType | null = betSum > 0 ? getSummaryBet(bets) : null;
    yield put(clearConfirmationSlotAction());

    yield put(saveRoundBetSuccessAction(roundBet));
  }

  if (gameType === GameType.HHH) {
    const bets = confirmedBets as DiceBetType[];
    // @TODO HHH
    const betSum = confirmedBets.length ? getDiceBetSum(bets) : 0;
    // const roundBet: DiceBetType | null = betSum > 0 ? getDiceBetSum(bets) : null;
    yield put(clearConfirmationSlotAction());

    yield put(saveRoundBetSuccessAction(confirmedBets[0]));
  }
}
