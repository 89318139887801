import React from 'react';

import { Device } from 'types';
import { transformToCurrency } from 'utils/transformToCurrency';
import { useTranslation } from 'react-i18next';

import { IDeviceDiceWinStatusProps } from './types';
import { DiceWinGradientBase } from './Components/Gradient.base';
import { LeftDiceBase } from './Components/LeftDice.base';
import { CenterDiceBase } from './Components/CenterDice.base';
import { RightDiceBase } from './Components/RightDice.base';
import { Wrapper } from './styles';

import { WinAmount, WinTitle } from '../WinStatus/styles';

export const BaseDiceWinStatus = ({
  winnerDices,
  winnerAmount,
  deviceType = Device.Desktop,
  currency,
}: IDeviceDiceWinStatusProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper $deviceType={deviceType}>
      <svg
        width="216"
        height="132"
        viewBox="0 0 216 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <path
          d="M-0.000488281 132L216 132V20L-0.000488281 20L-0.000488281 132Z"
          fill="#121011"
          fill-opacity="0.6"
        />
        <path
          d="M-0.000488281 132L216 132V20L-0.000488281 20L-0.000488281 132Z"
          fill="url(#paint0_linear_688_63009)"
          fill-opacity="0.8"
        />
        <path
          d="M2.49951 129.5L213.5 129.5L213.5 22.5L2.49951 22.5L2.49951 129.5Z"
          stroke="url(#paint1_linear_688_63009)"
        />
        <mask id="path-3-inside-1_688_63009" fill="white">
          <path d="M-0.000488281 20H216V63H-0.000488281V20Z" />
        </mask>
        <path
          d="M216 62H-0.000488281V64H216V62Z"
          fill="url(#paint2_linear_688_63009)"
          mask="url(#path-3-inside-1_688_63009)"
        />
        <WinAmount x="50%" y="86" dominantBaseline="hanging" textAnchor="middle">
          {transformToCurrency({
            value: winnerAmount,
            isWithoutCurrencySeparator: true,
            currency,
          })}
        </WinAmount>
        <LeftDiceBase color={winnerDices[0]} />
        <CenterDiceBase color={winnerDices[1]} />
        <RightDiceBase color={winnerDices[2]} />
        <path
          d="M64.6807 62.9906L72.3216 54.5H143.677L151.327 63L143.679 71.4985L72.3147 71.015L64.6807 62.9906Z"
          fill="url(#paint3_linear_688_63009)"
        />
        <path
          d="M64.6807 62.9906L72.3216 54.5H143.677L151.327 63L143.679 71.4985L72.3147 71.015L64.6807 62.9906Z"
          fill="url(#paint4_linear_688_63009)"
        />
        <path
          d="M64.6807 62.9906L72.3216 54.5H143.677L151.327 63L143.679 71.4985L72.3147 71.015L64.6807 62.9906Z"
          stroke="url(#paint5_linear_688_63009)"
        />
        <WinTitle x="50%" y="63" dominantBaseline="central" textAnchor="middle">
          {t('winStatus.title')}
        </WinTitle>
        <DiceWinGradientBase />
      </svg>
    </Wrapper>
  );
};
