import React from 'react';

import { WinnerDices } from '../types';
import { diceColors } from '../data';

export const RightDiceBase = ({ color }: { color: WinnerDices }) => (
  <g filter="url(#filter2_d_688_63009)">
    <mask
      id="mask2_688_63009"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="128"
      y="8"
      width="39"
      height="40">
      <rect x="128.167" y="8" width="38.2792" height="40" fill="url(#pattern4_688_63009)" />
    </mask>
    <g mask="url(#mask2_688_63009)">
      <rect x="128.167" y="8" width="38.2792" height="40" fill="url(#pattern5_688_63009)" />
      <rect
        x="128.167"
        y="8"
        width="38.2792"
        height="40"
        fill={diceColors[color]}
        style={{ mixBlendMode: 'overlay' }}
      />
      <rect
        x="128.167"
        y="8"
        width="38.2792"
        height="40"
        fill={diceColors[color]}
        fill-opacity="0.2"
      />
    </g>
  </g>
);
