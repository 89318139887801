import { WinnerDices } from './types';

export const diceColors: { [key in WinnerDices]: string } = {
  RED: '#ff2455',
  ORANGE: '#ff5e24',
  YELLOW: '#ffca41',
  GREEN: '#a8d32c',
  BLUE: '#2bc5ef',
  PURPLE: '#a361f4',
};
