import { betMessages } from 'constants/toastMessages';
import { placeBetSuccessAction } from 'core/widgets/Bet/actions';
import { placedBetSelector, confirmedBetSelector } from 'core/widgets/Bet/selectors';
import { BetType, DiceBetType } from 'core/widgets/Bet/types';
import { getSummaryBet, getDoubleBet, getBetSum } from 'core/widgets/Bet/utils';
import { tableBetLimitSelector, tableDiceBetLimitSelector } from 'core/widgets/TableSettings';
import { IBetLimit } from 'core/widgets/TableSettings/types';
import { addToastAction } from 'core/widgets/Toast/actions';
import { balanceSelector } from 'core/widgets/User';
import { put, select } from 'redux-saga/effects';
import { DICE_SLOTS, SLOTS } from 'types';
import { ToastTypes } from 'types/toast';
import { doubleBetSuccessAction } from '../actions';
import { GameType, IDiceLimits } from 'constants/common';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { placedDiceBetSelector } from 'core/widgets/DiceBet';
import { BetTypeDice } from 'core/widgets/DiceBet/types';
import { getDiceBetSum } from 'core/widgets/Bet/utils/getBetSum';
import { getSummaryDiceBet } from 'core/widgets/Bet/utils/getSummaryBet';
import { getDoubleDiceBet } from 'core/widgets/Bet/utils/getDoubleBet';
import { placeDiceBetSuccessAction } from 'core/widgets/DiceBet/actions';

export function* doubleBetSaga() {
  const balance: number | null = yield select(balanceSelector);
  const gameType: GameType = yield select(gameTypeSelector);

  if (gameType === GameType.BAC) {
    const tableLimits: IBetLimit | undefined = yield select(tableBetLimitSelector);
    const placedBet: BetType = yield select(placedBetSelector);
    const confirmedBets: BetType[] = yield select(confirmedBetSelector);

    if (!balance || !tableLimits) {
      return;
    }

    const summaryConfirmedBet: BetType = getSummaryBet(confirmedBets);
    const { doubledBet, isMaxBetLimitReached } = getDoubleBet({
      confirmedBet: summaryConfirmedBet,
      placedBet: {
        [SLOTS.player]: placedBet.player,
        [SLOTS.banker]: placedBet.banker,
        [SLOTS.tie]: placedBet.tie,
      },
      maxBetLimit: tableLimits.max,
    });
    const doubledBetSum: number = getBetSum([doubledBet]);

    if (doubledBetSum > balance) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    if (doubledBetSum > tableLimits.max) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      return;
    }

    yield put(doubleBetSuccessAction());
    yield put(placeBetSuccessAction(doubledBet));
  }

  if (gameType === GameType.HHH) {
    const tableLimits: IDiceLimits | undefined = yield select(tableDiceBetLimitSelector);
    const placedBet: BetTypeDice = yield select(placedDiceBetSelector);
    const confirmedBets: BetTypeDice[] = yield select(confirmedBetSelector);

    if (!balance || !tableLimits) {
      return;
    }

    const summaryConfirmedBet: DiceBetType = getSummaryDiceBet(confirmedBets);
    const { doubledBet, isMaxBetLimitReached } = getDoubleDiceBet({
      confirmedBet: summaryConfirmedBet,
      placedBet: {
        [DICE_SLOTS.red]: placedBet.red,
        [DICE_SLOTS.orange]: placedBet.orange,
        [DICE_SLOTS.yellow]: placedBet.yellow,
        [DICE_SLOTS.green]: placedBet.green,
        [DICE_SLOTS.blue]: placedBet.blue,
        [DICE_SLOTS.purple]: placedBet.purple,
        [DICE_SLOTS.triple]: placedBet.triple,
      },
      tableLimits,
    });
    const doubledBetSum: number = getDiceBetSum([doubledBet]);

    if (isMaxBetLimitReached) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      return;
    }

    if (doubledBetSum > balance) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.LOW_BALANCE }));
      return;
    }

    if (doubledBetSum > tableLimits.totalMaxBet) {
      yield put(addToastAction({ type: ToastTypes.ERROR, value: betMessages.MAX_BET_REACHED }));
      return;
    }

    yield put(doubleBetSuccessAction());
    yield put(placeDiceBetSuccessAction(doubledBet));
  }
}
