export interface ITransformToCurrencyProps {
  value: number;
  showCurrency?: boolean;
  isWithoutCurrencySeparator?: boolean;
  currency?: string | null;
}

export const transformToCurrency = ({
  value,
  showCurrency = true,
  isWithoutCurrencySeparator = false,
  currency = 'USD',
}: ITransformToCurrencyProps): string => {
  const result = value.toFixed(10);

  const [integerPart, decimalPart] = result.split('.');
  const truncatedDecimalPart = decimalPart.slice(0, 2);

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const formattedResult =
    truncatedDecimalPart === '00'
      ? formattedIntegerPart
      : `${formattedIntegerPart}.${truncatedDecimalPart}`;

  if (showCurrency) {
    return `${currency} ${formattedResult}`;
  }

  return formattedResult;
};

export const transformToCurrencyDecimal = ({
  value,
  showCurrency = true,
  isWithoutCurrencySeparator = false,
  currency = 'USD',
}: ITransformToCurrencyProps): string => {
  const result = `${value.toLocaleString('en-US')}`.replace(/,/g, ' ');
  const parts = result.split('.');
  const res =
    `${currency} ${parts[0]}${parts?.length > 1 ? (parts[1]?.length > 1 ? `.${parts[1]}` : `.${parts[1]}0`) : '.00'}`.replaceAll(
      '-',
      '',
    );

  return res;
};

export const transformToCurrencyDecimalJackpot = ({
  value,
  currency = 'USD',
}: ITransformToCurrencyProps): string => {
  const resultJackpot = `${value.toLocaleString('en-US')}`.replace(/,/g, ' ');
  const parts = resultJackpot.split('.');

  if (parts?.length > 1) {
    return `${currency} ${parts[0]}${parts?.length > 1 ? (parts[1]?.length > 1 ? `.${parts[1]}` : `.${parts[1]}0`) : '.00'}`.replaceAll(
      '-',
      '',
    );
  }

  return `${currency} ${parts[0]}`;
};

export const truncationNumber = (value: number): number => {
  return Math.floor(value * 100) / 100;
};
