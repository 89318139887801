import React from 'react';
import { useSelector } from 'react-redux';
import { Device } from 'types';
import { Slots } from 'widgets/Slots';
import { Chips } from 'widgets/Chips';
import { TableInfo } from 'widgets/TableInfo';
import { Scoreboard } from 'widgets/Scoreboard';
import { ScoreboardButton } from 'widgets/ScoreboardToggleButton';
import { isScoreboardOpenSelector } from 'core/widgets/Scoreboard';
import { IDeviceGamePanelProps } from './types';
import {
  PortraitBetPanelStyled,
  ContentWrapper,
  PortraitInnerWrapper,
  PortraitWrapper,
} from './styles';
import { DiceSlots } from '../DiceSlots';
import { GameType } from 'constants/common';
import { gameTypeSelector } from 'core/gameTypeReducer';
import { DiceStatistics } from 'widgets/DiceStatistics';

export const GamePanelPortrait = ({ deviceType = Device.Mobile }: IDeviceGamePanelProps) => {
  const isScoreboardOpen = useSelector(isScoreboardOpenSelector);
  const gameType = useSelector(gameTypeSelector);

  return (
    <PortraitWrapper $deviceType={deviceType}>
      <ContentWrapper>
        <PortraitInnerWrapper>
          {gameType === GameType.HHH ? <DiceSlots /> : <Slots />}
          <PortraitBetPanelStyled>
            <Chips />
          </PortraitBetPanelStyled>
          <ScoreboardButton />
          {isScoreboardOpen ? (
            gameType === GameType.HHH ? (
              <DiceStatistics />
            ) : (
              <Scoreboard />
            )
          ) : null}
        </PortraitInnerWrapper>
        <TableInfo />
      </ContentWrapper>
    </PortraitWrapper>
  );
};
