import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import {
  applySoundVolumeAction,
  changeIsStartLoadingSounds,
} from 'core/widgets/SoundSettings/action';
import { soundSettingsSelector } from 'core/widgets/SoundSettings';
import {
  streamNamesSelector,
  videoAppliedSettingsSelector,
  videoRequestedSettingsSelector,
} from 'core/widgets/VideoSettings';
import {
  applyVideoAutoAdjustAction,
  applyVideoPlaybackAction,
  applyVideoQualityAction,
  changeVideoQualityFailAction,
} from 'core/widgets/VideoSettings/action';
import { StreamNamesType, VideoQualities } from 'core/widgets/VideoSettings/types';
import { ADAPTION_RULE, PLAYER_DIV_ID, getConfig } from './data';
import { VideoStreamResponsive } from './Responsive';
import {
  VideoPlayerPauseReasons,
  VideoPlayerState,
  VideoPlayerStateChangeEvent,
  VideoPlayerSwitchStreamEvent,
} from './types';

interface ICustomWindow extends Window {
  NanoPlayer?: any;
}

declare const window: ICustomWindow;

export const VideoStream = () => {
  const dispatch = useDispatch();
  const streamNames = useSelector(streamNamesSelector);

  const {
    playback: requestedPlayback,
    autoAdjust: requestedAutoAdjust,
    quality: requestedQuality,
  } = useSelector(videoRequestedSettingsSelector);
  const { gameSoundMute, studioSound, isStartLoadingSounds } = useSelector(soundSettingsSelector);

  const { quality: appliedQuality } = useSelector(videoAppliedSettingsSelector);
  const [player, setPlayer] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [reloadPlayer, setReloadPlayer] = useState<boolean>(false);
  const [disableAutoSwitch, setDisableAutoSwitch] = useState<boolean>(false);
  const [isTryingToReload, setIsTryingToReload] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const onPlayerStateChange = useCallback((e: VideoPlayerStateChangeEvent) => {
    if (e.state === VideoPlayerState.PLAYING) {
      setIsPlaying(true);
    }

    if (
      e.state === VideoPlayerState.PAUSED &&
      (e.data.reason === VideoPlayerPauseReasons.notenoughdata ||
        e.data.reason === VideoPlayerPauseReasons.streamnotfound ||
        e.data.reason === VideoPlayerPauseReasons.sourcestreamstopped ||
        e.data.reason === VideoPlayerPauseReasons.buffer)
    ) {
      setReloadPlayer(true);
      setIsTryingToReload(true);

      setLoading(false);
      return;
    }

    if (e.state !== VideoPlayerState.PLAYING && e.data.reason !== VideoPlayerPauseReasons.destroy) {
      setLoading(true);
      return;
    }

    setLoading(false);
    setReloadPlayer(false);
    setDisableAutoSwitch(false);
    setIsTryingToReload(false);
  }, []);

  const onVolumeChange = useCallback(
    (e: any) => {
      const value: number = Math.floor(e.data.volume * 100);
      dispatch(applySoundVolumeAction(value));
    },
    [dispatch],
  );

  // @ts-ignore
  const onMuteChange = useCallback(() => {
    // const mute = e.name === 'Mute';
    // const value: number = Math.floor(e.data.volume * 100);
    // dispatch(applySoundMuteAction({ mute, type: Sounds.STUDIO_SOUND }));
    // dispatch(applySoundVolumeAction(value));
  }, []);

  const onSwitchStreamSuccess = useCallback(
    (e: VideoPlayerSwitchStreamEvent) => {
      const {
        data: { entry },
      } = e;
      const quality = entry?.label;

      if (quality) {
        dispatch(applyVideoQualityAction(quality));
      }
    },
    [dispatch],
  );

  const onSwitchStreamFail = useCallback(() => {
    dispatch(changeVideoQualityFailAction());
  }, [dispatch]);

  const onDestroy = useCallback(() => {
    setPlayer(null);
    dispatch(applyVideoPlaybackAction(false));
  }, [dispatch]);

  const setupNanoPlayer = useCallback(
    (configStreamNames: StreamNamesType, startIndex?: number) => {
      const nanoPlayer = new window.NanoPlayer('playerDiv');
      nanoPlayer
        .setup({
          ...getConfig(configStreamNames, startIndex),
          events: {
            onPlay: onPlayerStateChange,
            onLoading: onPlayerStateChange,
            onPause: onPlayerStateChange,
            onError: onPlayerStateChange,
            onStartBuffering: onPlayerStateChange,
            onStopBuffering: onPlayerStateChange,
            onWarning: onPlayerStateChange,
            onVolumeChange,
            onMute: onMuteChange,
            onUnmute: onMuteChange,
            onSwitchStreamSuccess,
            onSwitchStreamFail,
            onSwitchStreamAbort: onSwitchStreamFail,
            onDestroy,
          },
        })
        .then(() => {
          setPlayer(nanoPlayer);
          dispatch(applyVideoPlaybackAction(true));
          setReloadPlayer(false);
        });
    },
    [
      onPlayerStateChange,
      onVolumeChange,
      onMuteChange,
      onSwitchStreamSuccess,
      onSwitchStreamFail,
      onDestroy,
      dispatch,
    ],
  );

  useEffect(() => {
    if (!streamNames) {
      setLoading(false);
    }
  }, [streamNames]);

  useEffect(() => {
    if (reloadPlayer && streamNames && player) {
      player.destroy();
      setDisableAutoSwitch(true);
      setupNanoPlayer(streamNames, 2);
    }
  }, [streamNames, setupNanoPlayer, reloadPlayer, player]);

  useEffect(() => {
    if (player !== null) {
      const resultVolume = (studioSound.volume / 100).toFixed(2);
      if ((!gameSoundMute && !isIOS) || (!gameSoundMute && !studioSound.mute)) {
        if (resultVolume === '0.00') {
          player.setVolume((studioSound.volume / 100).toFixed(2));
          player.mute();
          return;
        }
        player.unmute();
        player.setVolume((studioSound.volume / 100).toFixed(2));
      }
    }
  }, [player, studioSound, gameSoundMute]);

  useEffect(() => {
    if (player !== null) {
      if (studioSound?.mute && isIOS) {
        player.mute();
        return;
      }

      player.unmute();
    }
  }, [player, studioSound]);

  useEffect(() => {
    if (!streamNames) {
      return;
    }

    if (player !== null && !requestedPlayback) {
      player.destroy();
      return;
    }

    if (player === null && requestedPlayback) {
      setupNanoPlayer(streamNames);
    }
  }, [streamNames, player, requestedPlayback, setupNanoPlayer]);

  useEffect(() => {
    if (player !== null && !disableAutoSwitch) {
      const streamIndex = Object.values(VideoQualities).indexOf(requestedQuality);
      player.switchStream(streamIndex);
    }
  }, [player, requestedQuality, disableAutoSwitch]);

  useEffect(() => {
    if (player !== null) {
      if (requestedAutoAdjust) {
        player.setAdaption({ rule: ADAPTION_RULE });
        dispatch(applyVideoAutoAdjustAction(true));
        return;
      }

      dispatch(applyVideoAutoAdjustAction(false));
    }
  }, [player, requestedAutoAdjust, appliedQuality, dispatch]);

  useEffect(() => {
    if (!streamNames) {
      return;
    }

    const onVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isMobile) {
        setDisableAutoSwitch(true);
        setupNanoPlayer(streamNames, 2);
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [streamNames, setupNanoPlayer]);

  useEffect(() => {
    if (player !== null) {
      if ((!gameSoundMute && !isIOS) || (!gameSoundMute && !studioSound.mute)) {
        player.unmute();
      }

      if (gameSoundMute || (studioSound.mute && isIOS)) {
        player.mute();
      }
    }
  }, [gameSoundMute, player, studioSound]);

  useEffect(() => {
    if (isPlaying && !isStartLoadingSounds) {
      dispatch(changeIsStartLoadingSounds(true));
    }
  }, [isPlaying, isStartLoadingSounds, dispatch]);

  return (
    <VideoStreamResponsive
      id={PLAYER_DIV_ID}
      isLoading={isLoading}
      isTryingToReload={isTryingToReload}
    />
  );
};
