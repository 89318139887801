import React from 'react';

import { IconNames } from 'components';
import { GameType } from 'constants/common';

import { IDeviceTableInfoProps } from './types';
import {
  IconStyled,
  ItemInfoBottomPortrait,
  ItemInfoLabel,
  ItemInfoSeparator,
  ItemInfoTopPortrait,
  ItemInfoValue,
  LimitsWrapper,
  PortraitContainer,
  PortraitWrapper,
} from './styles';

export const TableInfoPortrait = ({
  betsInfo,
  gameInfo,
  deviceType,
  gameType,
  onCloseLimits,
}: IDeviceTableInfoProps) => (
  <PortraitWrapper $deviceType={deviceType}>
    <PortraitContainer>
      {betsInfo.map(({ label, value }, i) => (
        <React.Fragment key={i}>
          <LimitsWrapper>
            <ItemInfoTopPortrait>
              <ItemInfoLabel>{label}: </ItemInfoLabel>
              <ItemInfoValue>{value}</ItemInfoValue>
            </ItemInfoTopPortrait>
            {gameType === GameType.HHH && i === 1 && (
              <IconStyled name={IconNames.Info} onClick={onCloseLimits} />
            )}
          </LimitsWrapper>
          {i !== betsInfo.length - 1 ? <ItemInfoSeparator /> : null}
        </React.Fragment>
      ))}
    </PortraitContainer>
    <PortraitContainer>
      {gameInfo.map(({ label, value }, i) => (
        <React.Fragment key={i}>
          <ItemInfoBottomPortrait>
            <ItemInfoLabel>{label}: </ItemInfoLabel>
            <ItemInfoValue>{value}</ItemInfoValue>
          </ItemInfoBottomPortrait>
          {i !== gameInfo.length - 1 ? <ItemInfoSeparator /> : null}
        </React.Fragment>
      ))}
    </PortraitContainer>
  </PortraitWrapper>
);
