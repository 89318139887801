import { createAction } from 'typesafe-actions';
import { DICE_SLOTS } from 'types';
import { ACTIONS, BetTypeDice, DiceBetSlotType, IDiceBet } from './types';

export const placeDiceBetAction = createAction(ACTIONS.PLACE_DICE_BET)<DiceBetSlotType>();

export const placeDiceBetSuccessAction = createAction(
  ACTIONS.PLACE_DICE_BET_SUCCESS,
)<BetTypeDice>();

export const clearPlacedDiceBetAction = createAction(ACTIONS.CLEAR_PLACED_DICE_BET)<DICE_SLOTS>();

export const clearPlacedDiceBetSuccessAction = createAction(
  ACTIONS.CLEAR_PLACED_DICE_BET_SUCCESS,
)<DICE_SLOTS>();

export const clearAllDiceBetsAction = createAction(ACTIONS.CLEAR_ALL_DICE_BETS)();

export const confirmDiceBetSuccessAction = createAction(ACTIONS.CONFIRM_DICE_BET_SUCCESS)<number>();

export const confirmDiceBetErrorAction = createAction(ACTIONS.CONFIRM_DICE_BET_ERROR)<number>();

export const addConfirmedDiceBetAction = createAction(ACTIONS.ADD_CONFIRMED_DICE_BET)<IDiceBet>();

export const setConfirmedDiceBetAction = createAction(ACTIONS.SET_CONFIRMED_DICE_BET)<IDiceBet>();
