import styled, { css } from 'styled-components';
import { Device } from 'types';
import { isIOS } from 'react-device-detect';

export const Wrapper = styled.div<{ $deviceType: Device }>`
  width: 258rem;
  padding: 16rem;
  border-radius: 8rem;
  background: rgba(18, 16, 17, 0.8);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 12rem;
  position: relative;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        width: 288rem;
        padding: 12rem 14rem;
        background: rgba(18, 16, 17);
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        width: 132rem;
        padding: 6rem 8rem;
        justify-content: start;
        row-gap: 2rem;
        column-gap: 2rem;
        background: rgba(18, 16, 17);
      `;
    }
  }}
`;

export const DiceWrap = styled.div`
  width: 20rem;
  height: 20rem;
  margin-right: 6rem;
`;

export const TripleWrap = styled.div`
  width: 84rem;
  height: 20rem;
  margin-right: 6rem;
`;

export const Text = styled.p`
  font-size: 10rem;
  line-height: 12rem;
  min-width: 36rem;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(67, 67, 67, 1);
`;

export const Triple = styled.div<{ $deviceType: Device }>`
  height: 100%;
  width: 100%;
  border-radius: 3rem;
  box-sizing: border-box;
  background: linear-gradient(180deg, #bb9370 0%, #554333 165.91%);
  border: 1px solid rgba(187, 147, 112, 1);
  font-size: 10rem;
  letter-spacing: 0.02em;
  text-align: center;
  padding: 2rem;
  color: rgba(255, 247, 235, 1);

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        padding: 3rem;
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        padding: 0 6rem;
        font-size: 5rem;
        letter-spacing: 0;
        writing-mode: vertical-rl;
        text-orientation: upright;
      `;
    }
  }}
`;

export const DicePercentage = styled.div<{ $deviceType: Device }>`
  display: flex;
  align-items: center;
  min-width: 58rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        min-width: 60rem;

        ${Text} {
          font-size: 12rem;
          min-width: 42rem;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        min-width: 50rem;

        ${DiceWrap} {
          width: 14rem;
          height: 14rem;
          margin-right: 4rem;
        }

        ${Text} {
          font-size: 9rem;
          min-width: unset;
        }
      `;
    }
  }}
`;

export const TriplePercentage = styled(DicePercentage)<{ $deviceType: Device }>`
  ${({ $deviceType }) => {
    if ($deviceType === Device.Tablet_Portrait) {
      return css`
        ${Text} {
          min-width: 50rem;
        }
      `;
    }
    if ($deviceType === Device.Mobile) {
      return css`
        position: absolute;
        top: 7rem;
        right: 4rem;
        display: flex;
        flex-direction: column;
        min-width: unset;
        height: 79%;

        ${TripleWrap} {
          width: 20rem;
          height: ${isIOS ? 'unset' : '72%'};
          margin-right: 0;
          margin-bottom: 4rem;
        }
      `;
    }
  }}
`;
