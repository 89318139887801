import styled, { css } from 'styled-components';
import { Device } from 'types';

export const Wrapper = styled.div<{ $deviceType: Device }>`
  padding: 16rem;
  border-radius: 8rem;
  background: rgba(18, 16, 17);
  display: flex;
  column-gap: 8rem;

  ${({ $deviceType }) => {
    if ($deviceType === Device.Desktop || $deviceType === Device.Tablet_Landscape) {
      return css`
        background: rgba(18, 16, 17, 0.8);
        margin-right: 14rem;
      `;
    }

    if ($deviceType === Device.Mobile) {
      return css`
        padding: 8rem;

        ${LastWinContainer} {
          padding: 6rem 4rem;
        }
        ${RestWinsContainer} {
          padding: 6rem 4rem;
          min-width: 152rem;
        }
      `;
    }
  }}
`;

export const LastWinContainer = styled.div<{ $hasDices: boolean }>`
  border-radius: 6rem;
  padding: 10rem 8rem;
  display: flex;
  flex-direction: column;
  border: 1rem solid #434343;
  background: #121011;

  ${({ $hasDices }) => {
    if ($hasDices) {
      return css`
        background: linear-gradient(180deg, rgba(18, 16, 17, 0) 0%, #2b2a2b 100%),
          linear-gradient(0deg, #121011, #121011);
        border: 1rem solid #d4bb93;
      `;
    }
  }}
`;

export const RestWinsContainer = styled.div`
  background: #121011;
  border: 1rem solid #434343;
  border-radius: 6rem;
  padding: 10rem 8rem;
  display: flex;
  gap: 5.5rem;
  min-width: 204rem;
`;

export const DiceWrap = styled.div`
  width: 12rem;
  height: 12rem;
`;

export const DicesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  min-width: 12rem;
  min-height: 44rem;
`;

export const Line = styled.div`
  height: 100%;
  width: 1px;
  background: rgba(67, 67, 67, 1);
`;
