import { createSelector } from '@reduxjs/toolkit';
import { IState } from 'core';
import { BetTypeDice, IDiceBetState } from './types';
import { getSummaryDiceBet } from './utils/getSummaryBet';
import { DiceBetType } from '../Bet/types';

export const diceBetRootSelector = (state: IState): IDiceBetState =>
  state.diceReducer.diceBetReducer;

export const placedDiceBetSelector = createSelector(
  diceBetRootSelector,
  (state: IDiceBetState): BetTypeDice => state.bets.placed,
);

export const summaryDiceBetSelector = createSelector(
  diceBetRootSelector,
  (state: IDiceBetState): BetTypeDice =>
    getSummaryDiceBet([
      state.bets.placed,
      ...state.bets.confirmed.map((bet) => ({
        ...bet.body,
      })),
    ]),
);

export const confirmedDiceBetSelector = createSelector(
  diceBetRootSelector,
  (state: IDiceBetState): BetTypeDice[] =>
    state.bets.confirmed.map((bet) => ({
      ...bet.body,
    })),
);

export const summaryDiceBetAllSelector = createSelector(
  diceBetRootSelector,
  (state: IDiceBetState): any => state,
);

export const isEmptyDiceSlotsSelector = createSelector(
  summaryDiceBetSelector,
  (summaryBets: DiceBetType): boolean =>
    Object.values(summaryBets).every((slotValue) => slotValue === 0),
);
